<template>
	<div>
		<div class="help-tab-warp" v-if="way==1">
			<div class="help-tab-item help-back">
				<div class="help-tab-box">
					<div class="help-tab-box-title">
						<h2>三步轻松完成压缩</h2>
						<h3>懂您的疑虑，简单的操作，解决你的后顾之忧</h3>
					</div>
					<div class="help-tab-compress-step">
						<div class="compress-step-item">
							<img src="/images/help-matter/add-video.png" />
							<p class="compress-step-item-t">选择视频</p>
							<p class="compress-step-item-d">支持mp4、avi、mov等多种视频格式</p>
						</div>
						<div class="compress-step-item set">
							<img src="/images/help-matter/set.png" />
							<p class="compress-step-item-t">设置参数</p>
							<p class="compress-step-item-d">根据您的需求设置压缩等级、尺寸、时间等参数</p>
						</div>
						<div class="compress-step-item dwon">
							<img src="/images/help-matter/download.png" />
							<p class="compress-step-item-t">处理并下载</p>
							<p class="compress-step-item-d">云端处理自动删除，保证您的隐私安全</p>
						</div>
					</div>
				</div>
			</div>
			<div class="help-tab-item">
				<div class="help-tab-box">
					<div class="help-tab-box-title">
						<h2>自定义无损压缩</h2>
						<p>快速压缩视频文件，支持多种格式，自定义参数，简单易用。</p>
					</div>
					<div class="help-tab-box-media">
						<video width="1200" autoplay loop src="/images/help-matter/video-banner.mp4?v=1.0.0"></video>
						<div class="help-tab-box-media-desc">
							<div>
								<span><i class="iconfont ys-shipin"></i>MOV</span>
								<span><i class="iconfont ys-cut"></i>1920×1080</span>
								<span><i class="iconfont ys-daxiao"></i>1.1GB</span>
								<span><i class="iconfont ys-time"></i>00:00:05</span>
							</div>
							<div>
								<span><i class="iconfont ys-shipin"></i>MOV</span>
								<span><i class="iconfont ys-cut"></i>1920×1080</span>
								<span><i class="iconfont ys-daxiao"></i>100.5MB</span>
								<span><i class="iconfont ys-time"></i>00:00:05</span>
							</div>
						</div>
					</div>
					<div class="help-tab-make">
						<a-button class="help-tab-defalut-btn" @click="backToTap" type="primary" size="large" shape="round">立即压缩</a-button>
					</div>
					
				</div>
			</div>
			<div class="help-tab-item">
				<div class="help-tab-box">
					<div class="help-tab-style-box">
						<div class="help-tab-style-l">
							<h3>多种压缩模式</h3>
							<ul class="help-tab-style-lists">
								<li>标准压缩：智能适配压缩比例一键压缩</li>
								<li>指定大小：输入期望大小MB，自动处理</li>
							</ul>
							<a-button class="help-tab-defalut-btn" @click="backToTap" type="primary" size="large" shape="round">立即压缩</a-button>
						</div>
						<div class="help-tab-style-r">
							<img src="/images/help-matter/media-video1.png"/>
						</div>
					</div>
				</div>
			</div>
			<div class="help-tab-item">
				<div class="help-tab-box">
					<div class="help-tab-style-box">
						<div class="help-tab-style-r">
							<img src="/images/help-matter/media-video2.png"/>
						</div>
						<div class="help-tab-style-l">
							<h3>视频尺寸裁剪</h3>
							<ul class="help-tab-style-lists">
								<li>等比例缩放：只输入宽或高，视频将等比例缩小或放大</li>
								<li>居中裁剪：同时输入宽高，视频将按照输入宽高等比例缩放后居中裁剪</li>
								<li>自动修剪您的视频，让编辑变得更加容易且节省时间</li>
							</ul>
							<a-button class="help-tab-defalut-btn" @click="backToTap" type="primary" size="large" shape="round">立即裁剪</a-button>
						</div>
					</div>
				</div>
			</div>
			<div class="help-tab-item">
				<div class="help-tab-box">
					<div class="help-tab-style-box">
						<div class="help-tab-style-l">
							<h3>去除片头片尾</h3>
							<ul class="help-tab-style-lists">
								<li>通过设置裁剪开始时间与结束时间来实现去除视频片头片尾</li>
								<li>自由调节视频时长范围</li>
							</ul>
							<a-button class="help-tab-defalut-btn" @click="backToTap" type="primary" size="large" shape="round">立即使用</a-button>
						</div>
						<div class="help-tab-style-r">
							<img src="/images/help-matter/media-video3.png"/>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="help-tab-warp" v-if="way==2">
			<div class="help-tab-item">
				<div class="help-tab-box">
					<div class="help-tab-box-title">
						<h2>音频压缩操作指南</h2>
						<p>为了您能够快速了解并且使用该功能，请您详细阅读操作指南</p>
					</div>
					<div class="help-tab-audio-use-step">
						<div class="help-tab-audio-use-step-l">
							<div class="help-tab-audio-use-step-l-item" :class="audioWay==1?'on':''">
								<i></i>
								<div class="msg">
									<h2>1.选择音频文件</h2>
									<p>点击【选择音频压缩】或【将音频拖至页面】，支持多种音频格式<br>每个音频文件最大可以到5Gb。<br>支持的格式：ogg、aif、aifc、au、m3u、mid、mp3、ra、ram、rmi、snd、wav、wma、flac、asf、voc、aiff、rm、m4a、svcd</p>
			
									
								</div>
							</div>
							<div class="help-tab-audio-use-step-l-item" :class="audioWay==2?'on':''">
								<i></i>
								<div class="msg">
									<h2>2.设置参数</h2>
									<p>压缩设置可“选择标准” “指定大小” “指定码率”等多种压缩模式；设置音频采样率；选择音频输出格式；<br>根据需求设置音频裁剪时长；</p>
								</div>
							</div>
							<div class="help-tab-audio-use-step-l-item" :class="audioWay==3?'on':''">
								<i></i>
								<div class="msg">
									<h2>3.处理及下载</h2>
									<p>点击【开始压缩】功能 ，等待系统处理<br>处理完成可预览，点击【下载音频】</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="help-tab-item">
				<div class="help-tab-box">
					<div class="help-tab-box-title">
						<h2>我们的优势</h2>
					</div>
					<div class="help-tab-audio-advantage">
						<div class="audio-advantag-item">
							<div class="audio-advantag-item-num">1</div>
							<div class="audio-advantag-item-icon">
								<i class="iconfont ys--pinzhibaozheng"></i>
							</div>
							<div class="audio-advantag-item-title">
								高品质软件
							</div>
							<div class="audio-advantag-item-text">
								占用内存少，速度快，性能稳定，集成了多种功能。
							</div>
						</div>
						<div class="audio-advantag-item">
							<div class="audio-advantag-item-num">2</div>
							<div class="audio-advantag-item-icon">
								<i class="iconfont ys-ys"></i>
							</div>
							<div class="audio-advantag-item-title">
								多种压缩模式搭配
							</div>
							<div class="audio-advantag-item-text">
								标准压缩、指定大小、指定码率三种模式压缩，一键智能压缩
							</div>
						</div>
					</div>
					<div class="help-tab-audio-advantage">
						<div class="audio-advantag-item">
							<div class="audio-advantag-item-num">3</div>
							<div class="audio-advantag-item-icon">
								<i class="iconfont ys-cut"></i>
							</div>
							<div class="audio-advantag-item-title">
								自由裁剪
							</div>
							<div class="audio-advantag-item-text">
								自定义音频时长，可自由选择裁剪。
							</div>
						</div>
						<div class="audio-advantag-item">
							<div class="audio-advantag-item-num">4</div>
							<div class="audio-advantag-item-icon">
								<i class="iconfont ys-webcaozuojiandan"></i>
							</div>
							<div class="audio-advantag-item-title">
								操作简单
							</div>
							<div class="audio-advantag-item-text">
								界面简洁明了，没有过多繁杂的操作细节，一键压缩，无需学习成本。
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>

		<div class="help-tab-warp" v-if="way==3">
			<div class="help-tab-item">
				<div class="help-tab-box">
					<div class="help-tab-box-title">
						<h2>为什么选择「91压缩」压缩图片 ？</h2>
					</div>
					<div class="help-tab-compress-step">
						<div class="compress-step-item">
							<span><i class="iconfont ys-ys"></i></span>
							<p class="compress-step-item-t">高效无损压缩</p>
							<p class="compress-step-item-d">图片压缩清晰无锯齿且可根据需求选择图片压缩等级、自定义图片分辨率。</p>
						</div>
						<div class="compress-step-item set">
							<span><i class="iconfont ys-ic_image_upload_mult"></i></span>
							<p class="compress-step-item-t">批量压缩</p>
							<p class="compress-step-item-d">拖拽或选择多个图片文件一次性压缩，最大支持同时处理60张，杜绝重复，工作学习效率倍增</p>
						</div>
						<div class="compress-step-item dwon">
							<span><i class="iconfont  ys--pinzhibaozheng"></i></span>
							<p class="compress-step-item-t">保障文件安全</p>
							<p class="compress-step-item-d">91压缩对图片压缩过程进行了多重加密处理，并且在压缩完成1小时后，便会将所有文件从服务器中永久删除，期间无人能查阅或下载这些文件，让你的文件与隐私得到保障。</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="help-tab-warp" v-if="way==4">
			<div class="help-tab-item">
				<div class="help-tab-box">
					<div class="help-tab-box-title">
						<h2>为什么选择「91压缩」转换格式 ？</h2>
					</div>
					<div class="help-tab-compress-step">
						<div class="compress-step-item">
							<span><i class="iconfont ys-ys"></i></span>
							<p class="compress-step-item-t">多种格式转换</p>
							<p class="compress-step-item-d">jpg、jpeg、png、webp、bmp、tiff、支持多种格式互转换</p>
						</div>
						<div class="compress-step-item set">
							<span><i class="iconfont ys-ic_image_upload_mult"></i></span>
							<p class="compress-step-item-t">批量转换</p>
							<p class="compress-step-item-d">拖拽或选择多个图片文件一次性转换，最大支持同时处理60张，杜绝重复，工作学习效率倍增</p>
						</div>
						<div class="compress-step-item dwon">
							<span><i class="iconfont  ys--pinzhibaozheng"></i></span>
							<p class="compress-step-item-t">保障文件安全</p>
							<p class="compress-step-item-d">91压缩对图片转换过程进行了多重加密处理，并且在转换完成1小时后，便会将所有文件从服务器中永久删除，期间无人能查阅或下载这些文件，让你的文件与隐私得到保障。</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="help-tab-warp" v-if="way==5">
			<div class="help-tab-item">
				<div class="help-tab-box">
					<div class="help-tab-box-title">
						<h2>证件照尺寸大全</h2>
					</div>
					<div class="help-tab-zjz-step">
						<label class="help-tab-zjz-step_title">通用尺寸：</label>
						<div class="help-tab-zjz-step_list">
							<div class="help-tab-zjz-step_list_item">
								<h3>小一寸照片</h3>
								<p>2.2X3.2cm丨259X377px</p>
							</div>
							<div class="help-tab-zjz-step_list_item">
								<h3>一寸照片</h3>
								<p>2.5X3.5cm丨295X413px</p>
							</div>
							<div class="help-tab-zjz-step_list_item">
								<h3>大一寸照片</h3>
								<p>3.3X4.8cm丨389X466px</p>
							</div>
							<div class="help-tab-zjz-step_list_item">
								<h3>小二寸照片</h3>
								<p>3.5X4.5cm丨413X531px</p>
							</div>
							<div class="help-tab-zjz-step_list_item">
								<h3>二寸照片</h3>
								<p>3.5X4.9cm丨413X625px</p>
							</div>
							<div class="help-tab-zjz-step_list_item">
								<h3>三寸照片</h3>
								<p>6cm×8.9cm丨649px×991px</p>
							</div>
							<div class="help-tab-zjz-step_list_item">
								<h3>四寸照片</h3>
								<p>7.62cmX6.09cm丨898px×1205px</p>
							</div>
							<div class="help-tab-zjz-step_list_item">
								<h3>五寸照片</h3>
								<p>8.9X12.7cm丨1500X1050px</p>
							</div>
							<div class="help-tab-zjz-step_list_item">
								<h3>六寸照片</h3>
								<p>10.2X15.2cm丨1200X1800px</p>
							</div>
							<div class="help-tab-zjz-step_list_item">
								<h3>七寸照片</h3>
								<p>12.7X17.6cm丨2100X1500px</p>
							</div>
						</div>
						<label class="help-tab-zjz-step_title">证件类尺寸：</label>
						<div class="help-tab-zjz-step_list">
							<div class="help-tab-zjz-step_list_item">
								<h3>驾驶证、驾照</h3>
								<p>2.2X3.2cm丨259X377px</p>
							</div>
							<div class="help-tab-zjz-step_list_item">
								<h3>居民身份证</h3>
								<p>2.6X3.2cm丨358X441px</p>
							</div>
							<div class="help-tab-zjz-step_list_item">
								<h3>社保照片</h3>
								<p>2.6X3.2cm丨358X411px</p>
							</div>
							<div class="help-tab-zjz-step_list_item">
								<h3>港澳通行证</h3>
								<p>3.3X4.8cm丨390x567px</p>
							</div>
							<div class="help-tab-zjz-step_list_item">
								<h3>小学登记报名</h3>
								<p>125px×175px</p>
							</div>
							<div class="help-tab-zjz-step_list_item">
								<h3>学籍照片</h3>
								<p>307px×378px</p>
							</div>
							<div class="help-tab-zjz-step_list_item">
								<h3>学信网</h3>
								<p>480px×640px</p>
							</div>
							<div class="help-tab-zjz-step_list_item">
								<h3>大学生信息采集</h3>
								<p>540px×720px</p>
							</div>
						</div>
						<label class="help-tab-zjz-step_title">资格证类尺寸：</label>
						<div class="help-tab-zjz-step_list">
							<div class="help-tab-zjz-step_list_item">
								<h3>执业药师证</h3>
								<p>215X300px</p>
							</div>
							<div class="help-tab-zjz-step_list_item">
								<h3>电子导游证</h3>
								<p>3.5X4.9cm丨413X625px</p>
							</div>
							<div class="help-tab-zjz-step_list_item">
								<h3>一级建造师考试照片</h3>
								<p>215X300px</p>
							</div>
							<div class="help-tab-zjz-step_list_item">
								<h3>导游资格证考试报名照片</h3>
								<p>285X385px</p>
							</div>
							<div class="help-tab-zjz-step_list_item">
								<h3>教师资格证照片</h3>
								<p>3.0X4.1cm丨360X480px</p>
							</div>
							<div class="help-tab-zjz-step_list_item">
								<h3>小学教师资格证</h3>
								<p>150px*200px</p>
							</div>
							<div class="help-tab-zjz-step_list_item">
								<h3>护士证件照</h3>
								<p>358px×441px</p>
							</div>
							<div class="help-tab-zjz-step_list_item">
								<h3>医生证件照</h3>
								<p>358px×441px</p>
							</div>
							<div class="help-tab-zjz-step_list_item">
								<h3>文员证件照</h3>
								<p>150×200px</p>
							</div>
							<div class="help-tab-zjz-step_list_item">
								<h3>行政人员证件照</h3>
								<p>150×200px</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="help-tab-warp" style="min-height: 120px;" v-if="way==6">
			
		</div>
		<div class="help-tab-warp" v-if="way==7">
			<div class="help-tab-item">
				<div class="help-tab-box">
					<div class="help-tab-box-title">
						<h2>三步轻松完成图标转换</h2>
					</div>
					<div class="help-tab-compress-step">
						<div class="compress-step-item">
							<span><i class="iconfont ys-tianjiatupian"></i></span>
							<p class="compress-step-item-t">选择图片</p>
							<p class="compress-step-item-d">支持jpg、png、webp等多种图片格式</p>
						</div>
						<div class="compress-step-item set">
							<span><i class="iconfont ys-shezhi"></i></span>
							<p class="compress-step-item-t">设置大小</p>
							<p class="compress-step-item-d">根据您的需求设置图标大小</p>
						</div>
						<div class="compress-step-item dwon">
							<span><i class="iconfont ys-xiazai-wenjianxiazai-26"></i></span>
							<p class="compress-step-item-t">处理并下载</p>
							<p class="compress-step-item-d">云端处理自动删除，保证您的隐私安全</p>
						</div>
					</div>
				</div>
			</div>
			<div class="help-tab-item">
				<div class="help-tab-box">
					<div class="help-tab-style-box">
						<!-- <div class="help-tab-style-r">
							<img src="/images/help-matter/media-use2.png"/>
						</div> -->
						<div class="help-tab-style-l">
							<h3>图片转ICON功能优势</h3>
							<ul class="help-tab-style-lists">
								<li>图片一键转ICO</li>
								<p>选择您的图片上传后，点击“开始转换”即可，支持在线将100M以内的图片转换成ICO格式</p>
								<li>灵活设置图标尺寸</li>
								<p>预置有16*16、20*20、24*24、32*32、64*64等常用ICO尺寸，灵活制作各种图标</p>
								<li>支持批量转换</li>
								<p>支持同时上传PNG、JPG、GIF等图片格式，并统一生成为ICON图标</p>
							</ul>
							<a-button @click="backToTap" type="primary" size="large" shape="round">立即转换</a-button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="help-tab-warp" style="background-color: #ffffff;" v-if="way==9">
			<div class="size-box-lists">
				<a :href="$baseFunc.findUrls('image_size','/10')" class="size-box-list" >压缩图片到10K以下</a>
				<a :href="$baseFunc.findUrls('image_size','/15')" class="size-box-list" >压缩图片到15K以下</a>
				<a :href="$baseFunc.findUrls('image_size','/20')" class="size-box-list" >压缩图片到20K以下</a>
				<a :href="$baseFunc.findUrls('image_size','/40')" class="size-box-list" >压缩图片到40K以下</a>
				<a :href="$baseFunc.findUrls('image_size','/50')" class="size-box-list" >压缩图片到50K以下</a>
				<a :href="$baseFunc.findUrls('image_size','/60')" class="size-box-list" >压缩图片到60K以下</a>
				<a :href="$baseFunc.findUrls('image_size','/80')" class="size-box-list" >压缩图片到80K以下</a>
				<a :href="$baseFunc.findUrls('image_size','/100')" class="size-box-list" >压缩图片到100K以下</a>
				<a :href="$baseFunc.findUrls('image_size','/200')" class="size-box-list" >压缩图片到200K以下</a>
				<a :href="$baseFunc.findUrls('image_size','/300')" class="size-box-list" >压缩图片到300K以下</a>
				<a :href="$baseFunc.findUrls('image_size','/500')" class="size-box-list" >压缩图片到500K以下</a>
				<a :href="$baseFunc.findUrls('image_size','/1024')" class="size-box-list" >压缩图片到1024K以下</a>
				<a :href="$baseFunc.findUrls('image_size','/2048')" class="size-box-list" >压缩图片到2048K以下</a>
				<a :href="$baseFunc.findUrls('image_size','/3072')" class="size-box-list" >压缩图片到3072K以下</a>
				<a :href="$baseFunc.findUrls('image_size','/5120')" class="size-box-list" >压缩图片到5120K以下</a>
			</div>
		</div>
	</div>
	
</template>

<script>
	export default{
		name:'Step',
		components:{
		},
		props:{
			way:{
				type:Number,
				default:1,//1:视频压缩-首页 2：音频工具-音频压缩
			},
		},
		created() {
		},
		data() {
			return {
			}
		},
		methods:{
			backToTap(){
				// 设置滚动行为改为平滑的滚动
				window.scrollTo({top: 0,behavior: "smooth"});
			},
		}
	}
</script>
<style scoped lang="less">
	.help-tab-audio-advantage{
		width: 100%;
		display: flex;
		display: -webkit-flex;
		justify-content: space-between;
		margin-top: 24px;
	}
	.audio-advantag-item{
		width: 585px;
		height: 182px;
		background: #fff3db;
		border-radius: 6px;
		margin-bottom: 30px;
	}
	.audio-advantag-item .audio-advantag-item-num{
		width: 32px;
		height: 40px;
		background-image: url(/images/help-matter/choice.png);
		margin-left: 27px;
		font-size: 24px;
		color: #FFFFFF;
		line-height: 36px;
		letter-spacing: 1px;
		text-align: center;
	}
	.audio-advantag-item .audio-advantag-item-icon{
		margin-left: 65px;
		margin-top: -5px;
		float: left;
		margin-right: 24px;
		width: 113px;
		height: 113px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #fbe1ae;
		border-radius: 50%;
	}
	.audio-advantag-item-icon i{
		color: #feaa01;
		font-size: 50px;
	}
	.audio-advantag-item .audio-advantag-item-title{
		font-size: 18px;
		color: #333333;
		line-height: 24px;
		margin-top: 10px;
	}
	.audio-advantag-item .audio-advantag-item-text{
		width: 90%;
		height: 48px;
		font-size: 12px;
		color: #666666;
		line-height: 24px;
		letter-spacing: 1px;
		margin-top: 13px;
	}
	.help-tab-warp{
		width: 100%;
		position: relative;
		margin-top: 24px;
	}
	.help-tab-warp .help-tab-item{
		width: 100%;
		position: relative;
		&.help-back{
			background: url("/images/help-matter/bj.png");
			height: 530px;
			.help-tab-box{
				padding: 76px 0;
			}
			.help-tab-box-title{
				h2{
					font-size: 32px;
					color: #ffffff;
					line-height: 1;
					margin-bottom: 20px;
				}
				h3{
					line-height: 1;
					font-size: 14px;
					color: #FFFFFF;
				}
			}
			.help-tab-compress-step{
				margin-top: 52px;
				height: 260px;
				background-color: #ffffff;
				align-items: center;
				border-radius: 10px;
			}
			.compress-step-item{
				line-height: 1;
				img{
					height: 37px;
				}
				.compress-step-item-t{
					color: #666666;
					margin: 30px 0 28px;
					font-size: 18px;
					font-weight:500;
				}
				.compress-step-item-d{
					font-size: 14px;
					color: #999999;
				}
			}
		}
	}
	.help-tab-warp .help-tab-item:nth-child(odd){background-color:#FFFFFF;}
	.help-tab-box{
		min-width: 1200px;
		width: 1200px;
		margin: 0 auto;
		padding: 0;
		font-size: 14px;
		position: relative;
		padding: 50px 0;
	}
	.help-tab-box .help-tab-box-title{
		width: 100%;
		text-align: center;
	}
	.help-tab-box-title h2{
		color: rgba(0,0,0,0.85);
		font-size: 28px;
		font-weight: bold;
		width: 100%;
		margin-bottom: 8px;
	}
	.help-tab-box-title p{
		margin-bottom: 24px;
	}
	.help-tab-compress-step{
		width: 100%;
		margin-top: 24px;
		display: flex;
		display: -webkit-flex;
		justify-content: space-between;
	}
	.help-tab-compress-step p{margin: 0;padding: 0;}
	.compress-step-item{
		text-align: center;
		width: auto;
		position: relative;
		flex: 1;
		padding: 0 24px;
	}
	.compress-step-item .compress-step-item-t{
		color: rgba(0,0,0,0.85);
		font-size: 18px;
		font-weight: bold;
		line-height: 30px;
		margin: 16px 0 5px;
	}
	
	.compress-step-item>span{
		display: block;
		width: 76px;
		height: 76px;
		display: flex;
		display: -webkit-flex;
		justify-content: center;
		align-items: center;
		background-color:#feaa01;
		border-radius: 50%;
		color: #FFFFFF;
		margin: 0 auto;
		
	}
	.compress-step-item.set>span{
		background-color:#7ED6FF;
	}
	.compress-step-item.dwon>span{
		background-color:#FF7875;
	}
	.compress-step-item>span>i{
		font-size: 38px;
	}
	.help-tab-box .help-tab-make{
		width: 100%;
		text-align: center;
		margin-top: 24px;
	}
	.help-tab-style-box{
		width: 100%;
		display: flex;
		display: -webkit-flex;
		align-items: center;
		justify-content: space-between;
	}
	.help-tab-box .help-tab-box-media{
		width: 100%;
		position: relative;
		border-radius: 6px;
		overflow: hidden;
		height: 410px;
	}
	.help-tab-box .help-tab-box-media::after{
		content: '';
		width: 2px;
		height: 360px;
		background: #fff;
		position: absolute;
		left: calc(50% - 1px);
		top: 0px;
	}
	.help-tab-box-media .help-tab-box-media-desc{
		width: 100%;
		display: flex;
		display: -webkit-flex;
		align-items: center;
		justify-content: space-between;
		height: 38px;
		margin-bottom: 6px;
	}
	.help-tab-box-media-desc div{flex: 1;text-align: center;}
	.help-tab-box-media-desc span{margin:0 16px;line-height: 1.2;}
	.help-tab-box-media-desc span i{margin-right: 4px;}
	.help-tab-style-l h3{
		font-size: 26px;
		font-weight: bold;
		color: #333333;
		margin-bottom: 20px;
	}
	.help-tab-style-l .help-tab-defalut-btn,.help-tab-make .help-tab-defalut-btn{
		width: 150px;
		height: 48px;
		font-size: 18px;
		background: linear-gradient(90deg, #00B277 0%, #00D885 100%);
		border: 0;
		transition: all 0.2s;
		&:hover{
			background: linear-gradient(90deg, #00D885 0%, #00B277 100%);
		}
	}
	.help-tab-style-l .help-tab-style-lists{
		list-style: none;
		text-align: left;
		font-size: 14px;
		line-height: 36px;
		padding: 0;
		margin-bottom: 24px;
		color: #666666;
	}
	.help-tab-style-lists li{
		position: relative;
		padding-left: 12px;
	}
	.help-tab-style-lists li:before{
		content: '';
		display: inline-block;
		position: absolute;
		top: calc(50% - 2px);
		transform: translateX(calc(-50% + 2px));
		left: 2px;	
		width: 4px;
		height: 4px;
		border-radius: 50%;
		background-color: #666;
	}

	
	.help-tab-audio-use-step{
		width: 100%;
		position: relative;
		display: flex;
		display: -webkit-flex;
		justify-content: space-between;
	}
	.help-tab-audio-use-step-r{
		display: block;
		width: 680px;
		height: 520px;
		flex-shrink: 0;
	}
	.help-tab-audio-use-step-r>img {
		display: none;
	}
	.help-tab-audio-use-step-r img.on{
		display: block;
		width: 100%;
		height: auto;
	}
	.help-tab-audio-use-step-l{
		position: relative;
		top:70px;
	}
	.help-tab-audio-use-step-l-item{
		height: 130px;
		box-sizing: border-box;
		padding-left: 38px;
		border-left: none;
		position: relative;
		box-sizing: border-box;
		border-left: 3px dashed rgba(22, 31, 45, 0);
	}
	.help-tab-audio-use-step-l-item:not(:nth-last-child(1)) {
		border-left: 3px dashed #D0D2DB;
	}
	.help-tab-audio-use-step-l-item .msg{
		width: 100%;
		height: 120px;
		border-radius: 8px;
		overflow: hidden;
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		box-sizing: border-box;
		padding: 0 32px 0 20px;
		top: -50px;
	}
	.help-tab-audio-use-step-l-item .msg h2{
		font-weight: bold;
		font-size: 18px;
	}
	.help-tab-audio-use-step-l-item .msg p{font-size: 15px;}
	.help-tab-audio-use-step-l-item.on .msg {
		background: linear-gradient(138deg, #ffb524 0%, var(--main-color) 100%);
		box-shadow: 0px 2px 14px 0px rgb(255 181 36 / 36%);
		border-radius: 8px;
		opacity: 1;
	}
	.help-tab-audio-use-step-l-item.on .msg h2,.help-tab-audio-use-step-l-item.on .msg p{
		color: #FFFFFF;
	}

	.help-tab-audio-use-step-l-item>i{
		display: block;
		width: 22px;
		height: 22px;
		position: absolute;
		left: -12px;
		right: auto;
		top: -15px;
		background: white;
		border-radius: 50%;
		border: 3px solid #d5d5d5;
		box-shadow: 0 0 12px #ffffff;
		transition: all 0.2s;
	}
	.help-tab-audio-use-step-l-item.on>i{
		border: 3px solid var(--main-color);
		background: var(--main-color);
		box-shadow: 0 0 12px var(--main-color);
	}
	
	.help-tab-zjz-step{
		width: 100%;
		position: relative;
		&_title{
			color: #000;
			font-size: 24px;
			width: 100%;
			padding-top: 24px;
			display: block;
			&:first-child{padding-top: 0;}
		}
		&_list{
			width: 100%;
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			&_item{
				font-size: 12px;
				width: calc((100% - 120px) / 5);
				height: 84px;
				line-height: 20px;
				border: 1px solid #eaeaea;
				border-radius: 6px;
				margin-top: 30px;
				margin-right: 30px;
				background: #fff;
				position: relative;
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				transition: all 0.2s;
				cursor: pointer;
				&:nth-child(5n){
					margin-right: 0;
				}
				h3{
					font-size: 17px;
					text-align: center;
					width: 100%;
					line-height: 25px;
				}
				p{
					color: #666;
					text-align: center;
					width: 100%;
					margin: 0;
				}
				&:hover{
					border-color: #109969;
					color: #109969;
					h3{
						color: #109969;
					}
					p{
						color: #349d78;
					}
				}
			}
		}
	}
	.size-box-lists{
		width: var(--max-width);
		margin: 0 auto;
		padding: 30px 0;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		.size-box-list{
			width: calc(20% - 24px);
			background: var(--main-xiala-color);
			border-radius: 6px;
			margin-bottom: 25px;
			color: #000000;
			font-size: 14px;
			text-align: center;
			height: 46px;
			line-height: 46px;
			display: block;
			// transition: all 0.2s;
			&:hover{
				background: var(--main-color);
				color: #ffffff;
				position: relative;
				top: -5px;
			}
		}
	}
	@media(max-width:760px){
		.help-tab-box{
			width: 100%;
			min-width: 100%;
		}
		.size-box-lists{
			padding: 30px 12px;
			.size-box-list{
				width: calc(50% - 12px);
			}
		}
		.help-tab-style-box{
			flex-direction: column;
			align-items: flex-start;
			padding: 0 12px;
			img{
				max-width: 100%;
			}
		}
		.compress-step-item{
			padding: 0 10px;
		}
	}
</style>