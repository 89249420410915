<template>
	<div class="vip-container">
		<div class="upgrade-v-warp">
			<div class="upgrade-v-left">
				<div class="upgrade-vl-item">
					<div class="upgrade-vl-title">
						<i class="iconfont ys-huiyuan"></i>
						<p>{{vipInfo.lists[vipInfo.selectIndex].title}}</p>
					</div>
					<div class="upgrade-vl-content">
						<div class="upgrade-func-desc">功能权限</div>
						<ul>
							<li v-for="(func,index) in vipInfo.lists[vipInfo.selectIndex].funcs">
								<span class="iconfont ys-gou1"></span>
								<span>{{func.content}}</span>
							</li>
						</ul>
					</div>
				</div>
				<div class="upgrade-vl-footer">
					<span>91压缩</span>
					<span> | 最全媒体处理平台</span>
				</div>
			</div>
			<div class="upgrade-v-right">
				<div class="vip-user-info-warp">
					<div class="vip-user-info-left">
						<img :src="userInfoData?userInfoData.avatarurl:''">
						<div class="vip-user-info-desc">
							<p>{{userInfoData?userInfoData.nickname:''}}</p>
							<p>
								<span>会员等级：<text>{{userInfoData?userInfoData.version:''}}</text></span>
								<span v-if="afterUpgradeInfo.upgrade_desc">升级后{{vipInfo.lists[vipInfo.selectIndex].price_item==1?"余额":"到期时间"}}：<text>{{afterUpgradeInfo.upgrade_desc}}</text></span>
							</p>
						</div>
					</div>
					<!-- <a class="vip-questions" href="/help.html" target="_blank">会员常见问题</a> -->
				</div>
				<div class="upgrade-vr-lists">
					
					<div v-for="(item,index) in vipInfo.lists" class="upgrade-vr-item" @click.stop="vipInfo.cutPriceFunc(index,this)" :class="vipInfo.selectIndex==index?'on':''">
						<p class="upgrade-vr-title">{{item.title}}</p>
						<li>￥<span>{{item.price}}</span>/{{item.unit}}</li>
						<p class="upgrade-vr-original-price">原价{{item.org_price}}</p>
						<div class="upgrade-vr-price-desc">{{item.desc}}</div>
						<span v-if="item.ide" class="upgrade-vr-card">限时优惠</span>
					</div>
				</div>
				<div class="user-payment-box">
					<div class="user-payment-list">
						<div class="user-payment-item">
							<div id="weixinPay" class="user-payment-code">
								<qrcode-vue :value="payInfo.weixinUrl" size="105" level="H" />
							</div>
							<div class="user-payment-desc"><img src="/images/weixinpay.svg" />微信支付</div>
						</div>
						<div class="user-payment-item">
							<div id="aliPay" class="user-payment-code">
								<qrcode-vue :value="payInfo.aliUrl" size="105" level="H" />
							</div>
							<div class="user-payment-desc"><img src="/images/alipay.svg" />支付宝支付</div>
						</div>
					</div>
					<div class="user-payment-total">
						<p><b>应付金额：</b><span class="payment-money">￥<text>{{vipInfo.lists[vipInfo.selectIndex].price}}</text><s>￥{{vipInfo.lists[vipInfo.selectIndex].org_price}}</s></span></p>
						<p class="user-payment-mode"><img src="/images/weixin-pay.svg"/><img src="/images/alipay.svg"/>使用微信/支付宝扫码付款</p>
					</div>
				</div>
				<div v-if="payInfo.status" class="order_pay_status_success"><i class="iconfont ys-chenggong"></i><span>{{payInfo.status_msg}}</span></div>
				<div class="user-agree-protocol">开具发票&ensp;|&ensp;支付即视为您同意<a target="_black" :href="$baseFunc.findUrls('agreement')">《91压缩用户协议》</a></div>
			</div>
		</div>
	</div>
</template>

<script>
	import QrcodeVue from 'qrcode.vue'
	export default{
		name: 'Vip',
		components:{
			QrcodeVue,
		},
		props: {
			userInfo:{
				type:[String,Object],
				default:null,
			}
		},
		created() {
			this.getPayPrice()
		},
		beforeDestroy(){
			this.clearTimer()
		},
		destroyed(){
			
		},
		data() {
			return {
				vipInfo:{
					selectIndex:2,
					lists:[
						{
							price:159,
							org_price:299,
							title:"年卡会员",
							unit:"年",
							desc:"仅0.43元/天",
							ide:true,
							price_item:5,
							funcs:[
								{
									content:"支持单次处理 20个 音视频",
								},
								{
									content:"单个音视频最大支持 10G",
								},
								{
									content:"支持单次处理 60张 图片",
								},
								{
									content:"单张图片最大支持 100M",
								},
								{
									content:"在线无损压缩",
								},
								{
									content:"全站功能任意使用",
								},
								{
									content:"智能调节音视频尺寸",
								},
								{
									content:"任意音视频格式转换",
								},
								{
									content:"压缩音视频到指定大小",
								},
								{
									content:"提供发票",
								},
							]
						},
						{
							price:99,
							org_price:159,
							title:"季卡会员",
							unit:"季",
							desc:"仅1.1元/天",
							ide:false,
							price_item:4,
							funcs:[
								{
									content:"支持单次处理 20个 音视频",
								},
								{
									content:"单个音视频最大支持 10G",
								},
								{
									content:"支持单次处理 60张 图片",
								},
								{
									content:"单张图片最大支持 100M",
								},
								{
									content:"在线无损压缩",
								},
								{
									content:"全站功能任意使用",
								},
								{
									content:"智能调节音视频尺寸",
								},
								{
									content:"任意音视频格式转换",
								},
								{
									content:"压缩音视频到指定大小",
								},
								{
									content:"提供发票",
								},
							]
						},
						{
							price:39,
							org_price:69,
							title:"月卡会员",
							unit:"月",
							desc:"性价比高",
							ide:false,
							price_item:3,
							funcs:[
								{
									content:"支持单次处理 20个 音视频",
								},
								{
									content:"单个音视频最大支持 10G",
								},
								{
									content:"支持单次处理 60张 图片",
								},
								{
									content:"单张图片最大支持 100M",
								},
								{
									content:"在线无损压缩",
								},
								{
									content:"全站功能任意使用",
								},
								{
									content:"智能调节音视频尺寸",
								},
								{
									content:"任意音视频格式转换",
								},
								{
									content:"压缩音视频到指定大小",
								},
								{
									content:"提供发票",
								},
							]
						},
						{
							price:26,
							org_price:39,
							title:"天卡会员",
							unit:"3天",
							desc:"8.6元/天",
							ide:false,
							price_item:2,
							funcs:[
								{
									content:"支持单次处理 20个 音视频",
								},
								{
									content:"单个音视频最大支持 10G",
								},
								{
									content:"支持单次处理 60张 图片",
								},
								{
									content:"单张图片最大支持 100M",
								},
								{
									content:"在线无损压缩",
								},
								{
									content:"全站功能任意使用",
								},
								{
									content:"智能调节音视频尺寸",
								},
								{
									content:"任意音视频格式转换",
								},
								{
									content:"压缩音视频到指定大小",
								},
								{
									content:"提供发票",
								},
							]
						},
						{
							price:6,
							org_price:12,
							title:"单卡会员",
							unit:"次",
							desc:"1个音、视频 4张图片",
							ide:false,
							price_item:1,
							funcs:[
								{
									content:"支持单次处理 1个 音视频",
								},
								{
									content:"单个音视频最大支持 10G",
								},
								{
									content:"支持单次处理 1张 图片",
								},
								{
									content:"单张图片最大支持 100M",
								},
								{
									content:"在线无损压缩",
								},
								{
									content:"全站功能任意使用",
								},
								{
									content:"智能调节音视频尺寸",
								},
								{
									content:"任意音视频格式转换",
								},
								{
									content:"压缩音视频到指定大小",
								},
								{
									content:"不提供发票",
								},
							]
						},
					],
					cutPriceFunc(index,_self){
						if(index!=this.selectIndex){
							this.selectIndex = index
							_self.getPayPrice();
						}
					}
				},
				userInfoData:this.userInfo?this.userInfo:null,
				afterUpgradeInfo:{
					upgrade_desc:"",
				},
				payInfo:{
					weixinUrl:"",/*微信支付*/
					aliUrl:"",/*支付宝支付*/
					trade_no:"",/*订单号*/
					status:0,/*支付成功*/
					status_msg:"支付成功",
					order_timer:null,
				}
			}
		},
		methods: {
			getPayPrice(){
				const _self = this
				this.$apiRequest.post('/order/createPayOrder', {
					price_item: this.vipInfo.lists[this.vipInfo.selectIndex].price_item
				}).then(res => {
					this.payInfo.weixinUrl=res.data.wechat_url;
					this.payInfo.aliUrl=res.data.alipay_url;
					this.payInfo.trade_no=res.data.trade_no;
					this.afterUpgradeInfo.upgrade_desc = res.data.upgrade_desc;
					if(!this.payInfo.order_timer){
						this.payInfo.order_timer  = setInterval(function(){
							_self.findOrderPayStatus()
						},2000)
					}
				}).catch(err => {
					this.clearTimer();
					this.$emit("paymentError",err)
				})
			},
			findOrderPayStatus(){
				if(!this.payInfo.trade_no)return false;
				const _self = this
				/*获取支付情况*/
				this.$apiRequest.post('/order/findOrderPayStatus', {
					trade_no: this.payInfo.trade_no
				}).then(res => {
					this.payInfo.status = 1
					this.userInfoData = res.user
					this.clearTimer();
					setTimeout(function(){
						_self.clearTimer();
						_self.$emit("paymentComplete",{userInfo:_self.userInfoData})
					},2000)
				}).catch(err => {
					if(err.code!=10701){
						this.clearTimer();
						this.$emit("paymentError",err)
					}
				})
			},
			clearTimer(){
				/*清除定时器*/
				if(this.payInfo.order_timer){
					clearInterval(this.payInfo.order_timer);
					window.clearInterval(this.payInfo.order_timer);
					this.payInfo.order_timer = null;
				}
			},
		},
		
	}
</script>

<style scoped>
	p{margin: 0;padding: 0;}
	dl, ol, ul {list-style-type: none;margin: 0;padding: 0;list-style: none;}
	.vip-container {
		position: relative;
		height: 600px;
		width: 980px;
		/* border-radius: 4px; */
		/* transition: all .25s; */
		/* border-radius: 8px; */
	}
	
	.upgrade-v-warp {
		width: 100%;
		position: relative;
		display: flex;
		display: -webkit-flex;
		height: 100%;
	}
	
	.upgrade-v-left {
		width: 190px;
		background-color: #333333;
		padding: 30px 20px 20px;
		color: #ffab56;
		position: relative;
		border-radius: 2px 0 0 2px;
	}
	
	.upgrade-vl-title {
		width: 100%;
		position: relative;
		text-align: center;
	}
	
	.upgrade-vl-title .iconfont {
		font-size: 40px;
	}
	
	.upgrade-vl-title p {
		font-size: 18px;
		font-weight: bold;
		margin-bottom: 20px;
		margin-top: -8px;
	}
	
	.upgrade-func-desc {
		font-size: 15px;
		font-weight: bold;
		margin-bottom: 5px;
	}
	
	.upgrade-vl-content>ul>li {
		padding: 5px 0;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-pack: start;
		justify-content: flex-start;
		color: #ffbb76;
		font-size: 12px;
	}
	.upgrade-vl-content>ul>li text{
		color: #FFFFFF;
		font-weight: 700;
		font-size: 14px;
	}
	
	.upgrade-vl-content>ul>li .iconfont {
		margin-right: 6px;
		line-height: 1.5;
		font-size: 14px;
	}
	
	.upgrade-vl-footer {
		position: absolute;
		left: 0;
		right: 0;
		margin: 0 auto;
		bottom: 20px;
		text-align: center;
	}
	
	.upgrade-v-right {
		width: calc(100% - 190px);
		margin: 0;
		padding: 0;
		position: relative;
	}
	
	.upgrade-vr-lists {
		width: 100%;
		display: flex;
		padding: 20px 20px 0;
		justify-content: space-between;
		align-items: center;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}
	
	.upgrade-vr-item {
		width: 136px;
		height: 180px;
		display: block;
		border-radius: 8px;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		text-align: center;
		cursor: pointer;
		position: relative;
		font-size: 13px;
		color: #333333;
		transition: all 0.2s;
	}
	
	.upgrade-vr-item:after {
		position: absolute;
		content: " ";
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		border: 1px solid #e8eaec;
		border-radius: 8px;
		box-sizing: border-box;
		transition: all 0.2s;
	}
	.upgrade-vr-item:hover:after {
		border-color: #f57c00;
	}
	.upgrade-vr-item.on:after {
		border: 2px solid #f57c00;
	}
	
	.upgrade-vr-item .upgrade-vr-title {
		font-size: 18px;
		font-weight: bold;
		text-align: center;
		margin: 40px auto 5px;
		line-height: 1;
	}
	
	.upgrade-vr-item li {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: flex-end;
		font-size: 14px;
		line-height: 1;
		margin: 8px 0 4px;
	}
	
	.upgrade-vr-item li span {
		font-size: 30px;
		color: #f57c00;
		font-weight: bold;
		position: relative;
		top: 3px;
		margin: 0 2px;
	}
	
	.upgrade-vr-item .upgrade-vr-original-price {
		margin-top: 8px;
		line-height: 1;
		color: #999999;
		text-decoration: line-through;
	}
	
	.upgrade-vr-item .upgrade-vr-price-desc {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-align: center;
		align-items: center;
		-ms-flex-pack: center;
		justify-content: center;
		width: 100%;
		height: 28px;
		font-size: 12px;
		font-weight: 500;
		line-height: 28px;
		border-radius: 0 0 8px 8px;
		position: absolute;
	    bottom: 0;
		background-color: #fff7f0;
		color: #f57c00;
		transition: all 0.2s;
	}
	.upgrade-vr-item.on .upgrade-vr-price-desc{
		background-color: #f57c00;
		color: #FFFFFF;
	}
	.upgrade-vr-item .upgrade-vr-card{
		position: absolute;
		top: 0;
		right: 0;
		height: 28px;
		line-height: 28px;
		padding: 0 14px;
		color: #fff;
		background: #f57c00;
		text-align: center;
		border-radius: 0 8px;
		font-size: 12px;
	}
	.vip-user-info-warp{
		width: 100%;
		height: 85px;
		padding: 20px 56px 20px 20px;
		box-sizing: border-box;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		display: flex;
		display: -webkit-flex;
		position: relative;
		background-color: #f9f8f8;
		border-top-right-radius: 8px;
		justify-content: space-between;
	}
	.vip-user-info-left{
		display: flex;
		display: -webkit-flex;
		align-items: center;
		font-size: 13px;
	}
	.vip-user-info-left img{
		width: 40px;
		height: 40px;
		border-radius: 100%;
		margin-right: 10px;
		object-fit: cover;
	}
	.vip-user-info-desc p:first-child{
		color: #000000;
		margin-bottom: 5px;
		font-size: 14px;
		font-weight: bold;
	}
	.vip-user-info-desc p span{margin-right: 15px;}
	.vip-user-info-warp .vip-questions{
		display: flex;
		align-items: center;
	}
	.user-payment-box{
		background: #f9f8f8;
		margin: 20px;
		display: flex;
		padding: 30px;
		width: calc(100% - 40px);
		height: 204px;
		border-radius: 5px;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}
	.user-payment-list{
		width: 300px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		text-align: center;
	}
	.user-payment-list .user-payment-code{
		width: 126px;
		height: 126px;
		padding: 8px;
		background: #fff;
		margin-bottom: 10px;
	}
	.user-payment-list .user-payment-desc{
		font-size: 18px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.user-payment-desc img{height: 24px;width: auto;margin-right: 6px;}
	.user-payment-total{
		font-size: 18px;
		margin: 16px 0 0 40px;
		font-size: 14px;
		position: relative;
	}
	.user-payment-total .payment-money{
		color: #ff4343;
		font-size: 14px;
	}
	.payment-money text{
		font-size: 30px;
		font-weight: 700;
	}
	.payment-money s{
		color: rgba(27, 35, 55, 0.4);
		font-weight: 400;
		margin-left:10px;
		line-height: 18px;
	}
	.user-payment-mode{
		display: flex;
		align-items: center;
		color: #333;
		margin: 12px 0;
		font-size: 14px
	}
	.user-payment-mode img{
		width:auto;
		height: 24px;
		margin-right: 5px;
	}
	.user-agree-protocol{
		font-size: 14px;
		color: #333;
		position: absolute;
		right: 20px;
		margin-top: 8px;
	}
	.user-agree-protocol a{
		text-decoration: underline;
		color: #ff4343;
	}
	.order_pay_status_success{font-size:18px;color:#09bb07;position:absolute;left:20px;margin-top:8px;line-height: 1;}
	.order_pay_status_success i{font-size: 20px;margin-right: 3px;}
</style>