export default{
  AccessKey:'kk680x7rokv986mr',
  SecretKey:'byfs4zyu8xps62luniysb5iruxxv4458',
  DevelopmentUrls:{//本地
	video:{
		ys:"/",//视频压缩
		batch:"/video/batch.html",//视频批量压缩
		mp4:"/",//视频mp4压缩
		mov:"/",//视频mov压缩
		avi:"/",//视频avi压缩
		fenduan:"/video/fenduan.html",//视频分段
		extract:"/video/extract.html",//视频提取
		format:"/video/format.html",//视频转格式
		image:"/video/to-image.html",//视频提取图片
	},
	audio:{
		ys:"/audioYs.html",//音频压缩
		wav:"/audioYs.html",//音频wav压缩
		flac:"/audioYs.html",//音频flac压缩
		fenduan:"/audio/fenduan.html",//音频分段
		format:"/audio/format.html",//音频转格式
	},
	image:{
		index:"/img/ys.html",
		png:"/img/ys.html",
		jpg:"/img/ys.html",
		webp:"/img/ys.html",
		gif:"/img/ys.html",
		zjz:"/img/zjz.html",
		dpi:"/img/dpi.html",
		size:"/img/size.html",
		gif_split:"/img/gifSplit.html",
		geshi_all:"/img/format.html",
		ico:"/img/ico.html",
	},
	pdf:{
		index:"/pdf.html",//pdf压缩
		split:"/pdf/split.html",//PDF拆分
		merge:"/pdf/merge.html",//PDF合并
		decode:"/pdf/decode.html",//pdf解密
		encrypt:"/pdf/encrypt.html",//pdf加密
	},
	logout:'/oauth/logout',
	user:{
		user:'/user',//个人中心
		order:'/user/order.html',//充值记录
		record:'/user/record.html',//订单详情
		help:'/user/help.html',//帮助中心
	},
    help:{
		list:'/help',
      about:'/about',//关于我们
      agreement:'/about/agreement',//用户协议
      privacy:'/about/privacy',//隐私政策
      copyright:'/about/copyright',//版权声明
    },
	map:'/map.html',
  },
  ProductionUrls:{//线上
	video:{
		ys:"/",//视频压缩
		batch:"/pl",//视频批量压缩
		mp4:"/mp4",//视频mp4压缩
		mov:"/mov",//视频mov压缩
		avi:"/avi",//视频avi压缩
		fenduan:"/fenduan",//视频分段
		extract:"/tq",//视频提取
		format:"/gs",//视频转格式
		image:"/video-to-image",//视频提取图片
	},
	audio:{
		ys:"/mp3",//音频压缩
		wav:"/wav",//音频wav压缩
		flac:"/flac",//音频flac压缩
		fenduan:"/audio/fenduan",//音频分段
		format:"/audio/gs",//音频转格式
	},
	image:{
		index:"/image",
		png:"/png",
		jpg:"/jpg",
		webp:"/webp",
		gif:"/gif",
		zjz:"/zjz",
		ico:"/ico",
		dpi:"/dpi",
		size:"/size",
		gif_split:"/gif/fenjie",
		geshi_all:"/imagegeshi",
	},
	pdf:{
		index:"/pdf",//pdf压缩
		split:"/pdf/cf",//PDF拆分
		merge:"/pdf/hb",//PDF合并
		decode:"/pdf/yc",//pdf移除密码
		encrypt:"/pdf/jm",//pdf加密
	},
	logout:'/oauth/logout',
	user:{
		user:'/user',//个人中心
		order:'/user/order',//充值记录
		record:'/user/record',//订单详情
		help:'/user/help',//帮助中心
	},
    help:{
		list:'/help',
      about:'/about',
      agreement:'/about/agreement',
      privacy:'/about/privacy',
      copyright:'/about/copyright',
    },
	map:'/map.html',
  },
}