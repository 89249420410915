<template>
	<!-- 地图 -->
	<div v-if="toolShow" class="ys91-map-warp">
		<div class="map-box">
			<div class="map-box-title">常用工具</div>
			<div v-for="(item,index) in $baseFunc.getMap()">
				<a class="map-one-t" target="_blank" :href="item.url">{{item.title}}</a>
				<div class="map-t-list">
					<a class="map-t-list-item" v-for="list in item.lists"  :href="list.url" target="_blank" :title="list.title">{{list.title}}</a>
				</div>
			</div>
		</div>
	</div>
	<div class="ya91-bot-r-bottom">
		<div class="ya91-bot-r-b-title">立即注册，免费体验</div>
		<div class="ya91-bot-r-b-desc">有更高需求的用户，可升级会员获得更多功能和需求</div>
		<div class="ya91-bot-r-b-btn">立即注册</div>
	</div>
	<!-- 友情链接 -->
	<template v-if="linkShow">
		<div class="links-warp">
			<div class="links-box">
				<h3>LINKS</h3>
				<div>
					<a href="http://www.spjian.com/" title="视频压缩" target="_blank">视频压缩</a>
				</div>
			</div>
		</div>
	</template>
	
	<!-- 底部导航 -->
	<div class="borromContainer">
		<div>
			<div class="contact">来不及找到心仪的内容？按 <a-button type="primary" size="small">Ctrl</a-button> + <a-button type="primary" size="small">D</a-button> 收藏我们</div>
			<div class="info">
				<div class="info1">
					<span>Copyright © 2022 <a @click.stop> 四川有点意思网络技术有限公司 </a>保留所有权利。</span>
					<a target="_black" :href="$baseFunc.findUrls('about')">关于我们</a>
					<a target="_black" :href="$baseFunc.findUrls('agreement')">用户协议</a>
					<a target="_black" :href="$baseFunc.findUrls('privacy')">隐私政策</a>
					<a target="_black" :href="$baseFunc.findUrls('map')">网站地图</a>
				</div>
				<div class="info2">
					<a href="https://beian.miit.gov.cn" target="_blank">蜀ICP备2022016824号 </a>
					<!-- <a href=""> 川公网安备99897889号 </a> -->
					<a href="http://www.12377.cn/" target="_blank" rel="nofollow">网上有害信息举报专区 </a>
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
	import baseFunc from '@/libs/base_func.js'
	export default {
		name: 'Bottom',
		components: {
		},
		props:{
			linkShow:{
				type:Boolean,
				default:false,//true：显示底部友情链接 false:不显示
			},
			toolShow:{
				type:Boolean,
				default:true,//true：显示地图导航 false:不显示
			},
		},
		data(){
			return{
			}
		},
		created(){
		},
		methods:{
			
		}
	}
</script>

<style scoped lang="less">
	.info2 a{
		margin-right: 10px;
	}
	.info{
		border-top: 1px solid #e1e1e1;
		font-size: 13px;
		color: #666;
		padding: 10px 0 0;
		line-height: 25px;
	}
	.contact{
		font-size: 13px;
		color: #666;
		letter-spacing: .5px;
		padding: 5px 0;
	}
	.info1 a{
		margin-left: 10px;
	}
	.borromContainer a{
		color: #333;
	}
	.borromContainer{
		width: 100%;
		height: 120px;
		text-align: center;
		display: flex;
		align-items: center;
	}
	.borromContainer>div{
		min-width: var(--max-width);
		margin: 0 auto;
	}
	.ya91-bot-r-bottom{
		width: 100%;
		position: relative;
		height: 176px;
		background: url("/images/reg.png");
		color: #ffffff;
		text-align: center;
		font-size: 16px;
		line-height: 1;
		font-family: PingFang SC;
		.ya91-bot-r-b-title{
			font-size: 26px;
			padding-top: 19px;
		}
		.ya91-bot-r-b-desc{
			font-size: 14px;
			color: #E6E6E6;
			margin: 19px 0 28px;
		}
		.ya91-bot-r-b-btn{
			width: 150px;
			height: 48px;
			border-radius: 0;
			background: #FFFFFF;
			font-size: 16px;
			font-family: PingFang SC;
			font-weight: 500;
			color: #00B277;
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			left: 0;
			right: 0;
			margin: 0 auto;
			cursor: pointer;
			transition: all 0.2s;
			&:hover{
				background-color: #00B277;
				color: #ffffff;
			}
		}
	}
	.ys91-map-warp{
		background-clip: border-box;
		position: relative;
		display: flex;
		margin: 50px 0 25px;
		.map-box{
			min-width: var(--max-width);
			width: var(--max-width);
			margin: auto;
			padding: 0;
			border-radius: 4px;
			.map-box-title{
				margin-bottom: 28px;
				line-height: 1;
				color: #333333;
				font-size: 26px;
				font-weight: 600;
			}
			.map-one-t{
				line-height: 1;
				margin-bottom: -4px;
				font-size: 16px;
				display: block;
			}
			.map-t-list{
				margin-bottom: 16px;
				display: flex;
				justify-content: flex-start;
				flex-wrap: wrap;
				.map-t-list-item{
					font-size: 14px;
					height: 44px;
					line-height: 44px;
					border: 1px solid rgba(0,0,0,0);
					border-radius: 7px;
					margin-top: 14px;
					margin-right: 14px;
					padding: 0;
					width:118px;
					background: #fff;
					position: relative;
					display: flex;
					flex-wrap: wrap;
					justify-content: center;
					align-items: center;
					flex-direction: column;
					transition: all 0.2s;
					cursor: pointer;
					&:hover{
						border-color:var(--main-color);
						color: var(--main-color);
					}
				}
			}
		}
		a{
			font-size: 15px;
			color: #333333;
			
		}
	}

	
	.links-warp{
		width:100%;
		padding:40px 0;
		.links-box{
			width:1200px;
			margin:0 auto;
			h3{font-size:23px;color:var(--main-color);font-weight:bold;width:100%;padding:25px 0 15px 0;border-top:1px solid #ececec;margin:0}
			a{font-size:13px;color:#666;line-height:30px;margin:0 10px 0 0}
		}
	}
	@media(max-width:760px){
		.ys91-map-warp{
			margin: 24px 0 24px;
			padding: 0 12px;
			.map-box .map-t-list {
				.map-t-list-item{
					width: calc(33.3% - 10px);
					&:nth-child(3n){
						margin-right: 0;
					}
				}
			}
		}
		.links-warp{
			padding:40px 12px;
		}
	}
</style>