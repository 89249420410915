<template>
	<div class="user-meau-container">
		<!-- <div class="user-meau-banner">
			<p>这里放一些介绍或其他或者不放</p>
		</div> -->
		<div class="user-meau-warp">
			<div class="user-meau-info-box">
				<img v-if="userInfo&&userInfo.avatarurl" :src="userInfo?userInfo.avatarurl:''"/>
				<span>{{userInfo?userInfo.nickname:''}}</span>
			</div>
			<a :href="$baseFunc.findUrls('user')" class="user-meau-item" :class="partActive==1?'active':''">
				<div>
					<text>账户信息</text>
					<span class="user-meau-right-icon"><svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="10" height="10"><path d="M946.4 353.50000001l-419.9 419.9c-8.2 8.2-21.5 8.2-29.7 0l-421.1-421.1c-16.4-16.4-16.4-43 0-59.3 16.4-16.4 43-16.4 59.3 0L504.2 662.20000001c4.1 4.1 10.7 4.1 14.8 0l368-368c16.4-16.4 43-16.4 59.3 0 16.5 16.3 16.5 42.9 0.1 59.3z" fill="#8a8a8a" p-id="7992"></path></svg></span>
				</div>
			</a>
			<a :href="$baseFunc.findUrls('order')" class="user-meau-item" :class="partActive==2?'active':''">
				<div>
					<text>订单记录</text>
					<span class="user-meau-right-icon"><svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="10" height="10"><path d="M946.4 353.50000001l-419.9 419.9c-8.2 8.2-21.5 8.2-29.7 0l-421.1-421.1c-16.4-16.4-16.4-43 0-59.3 16.4-16.4 43-16.4 59.3 0L504.2 662.20000001c4.1 4.1 10.7 4.1 14.8 0l368-368c16.4-16.4 43-16.4 59.3 0 16.5 16.3 16.5 42.9 0.1 59.3z" fill="#8a8a8a" p-id="7992"></path></svg></span>
				</div>
			</a>
			<a :href="$baseFunc.findUrls('record')" class="user-meau-item" :class="partActive==3?'active':''">
				<div>
					<text>处理记录</text>
					<span class="user-meau-right-icon"><svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="10" height="10"><path d="M946.4 353.50000001l-419.9 419.9c-8.2 8.2-21.5 8.2-29.7 0l-421.1-421.1c-16.4-16.4-16.4-43 0-59.3 16.4-16.4 43-16.4 59.3 0L504.2 662.20000001c4.1 4.1 10.7 4.1 14.8 0l368-368c16.4-16.4 43-16.4 59.3 0 16.5 16.3 16.5 42.9 0.1 59.3z" fill="#8a8a8a" p-id="7992"></path></svg></span>
				</div>
			</a>
			<a v-if="userInfo&&userInfo.manage==1" :href="$baseFunc.findUrls('user-help')" class="user-meau-item" :class="partActive==4?'active':''">
				<div>
					<text>帮助中心</text>
					<span class="user-meau-right-icon"><svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="10" height="10"><path d="M946.4 353.50000001l-419.9 419.9c-8.2 8.2-21.5 8.2-29.7 0l-421.1-421.1c-16.4-16.4-16.4-43 0-59.3 16.4-16.4 43-16.4 59.3 0L504.2 662.20000001c4.1 4.1 10.7 4.1 14.8 0l368-368c16.4-16.4 43-16.4 59.3 0 16.5 16.3 16.5 42.9 0.1 59.3z" fill="#8a8a8a" p-id="7992"></path></svg></span>
				</div>
			</a>
		</div>
	</div>
</template>

<script>
	// 用户个人中心菜单
	import topnav from "./topnav.vue"
	export default{
		name:'UserMenu',
		components:{
			topnav
		},
		props:{
			partActive:Number,
			userInfo:Object,
		},
		created() {
			/*获取微信登录二维码*/
			// this.getUserInfo()
		},
		data() {
			return {
			}
		},
		methods: {
		},
	}
</script>
<style>
	.user-common-warp{
		min-width: var(--default-width);
		/* width: var(--default-width); */
		margin: 0;
		padding: 0;
		display: flex;
		display: -webkit-flex;
		position: relative;
		margin: 0 auto;
		border-radius: 8px;
		/* background-color: #fbf3e3; */
		background-color: var(--main-ucb-color);
		box-shadow:0 0 1px rgba(224,156,0,0.1);
	}
</style>
<style scoped>
	.user-meau-container{
		flex: 0 0 220px;
		width: 220px;
		min-width:220px;
		background-color: #ffffff;
		border-radius: 8px 0 0 8px;
		font-size: 14px;
		min-height: calc(100vh - 65px);
	}
	.user-meau-container .user-meau-banner{
		width: 100%;
		height: 200px;
		background-image: url('/images/1.webp');
		background-size: 100% 100%;
		background-repeat: no-repeat;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.user-meau-warp{
		width: 100%;
		margin:0 auto;
	}
	.user-meau-info-box{
		width: 100%;
		padding: 24px 12px;
		display: flex;
		display: -webkit-flex;
		align-items: center;
		justify-content: center;
		height: 100px;
		font-size: 15px;
	}
	.user-meau-info-box img{
		width: 28px;
		height: 28px;
		border-radius: 50%;
		object-fit: cover;
		margin-right: 4px;
	}
	.user-meau-warp .user-meau-item{
		text-align: center;
		font-size: 16px;
		height: 38px;
		cursor: pointer;
		color: #000000;
		display: block;
		padding: 0 12px;
		margin: 4px 0;
		box-sizing: border-box;
	}
	.user-meau-item>div{
		width: 100%;
		height: 100%;
		display: flex;
		display: -webkit-flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 20px;
		font-size: 14px;
		border-radius: 4px;
		transition: all 0.2s;
	}
	.user-meau-warp .user-meau-item:hover>div,.user-meau-warp .user-meau-item.active>div{
		background-color: var(--main-dan-color);
	}
	.user-meau-warp .user-meau-item.active>div{font-weight: 600;}
	.user-meau-item .user-meau-right-icon{
		transform: rotate(270deg);
		-webkit-transform: rotate(270deg);
		-ms-transform: rotate(270deg);
		-moz-transform: rotate(270deg);
		-o-transform: rotate(270deg);
	}
</style>