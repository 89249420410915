<template>
	<div class="parameter-control-warp">
		<div class="parameter-control-box">
			<template v-if="format=='image_geshi_all'">
				<label class="parameter-control-label">输出格式</label>
				<a-select placeholder="选择需要转换格式" class="parameter-control-common-hu1s" size="large" v-model:value="parameter.format">
					<a-select-option value="">选择需要转换格式</a-select-option>
					<a-select-option value="jpg">jpg</a-select-option>
					<a-select-option value="jpeg">jpeg</a-select-option>
					<a-select-option value="png">png</a-select-option>
					<a-select-option value="webp">webp</a-select-option>
					<a-select-option value="bmp">bmp</a-select-option>
					<a-select-option value="tiff">tiff</a-select-option>
				</a-select>
			</template>
			<template v-else-if="format=='gif_split'">
				<label class="parameter-control-label">分解后图片格式</label>
				<a-select placeholder="选择需要分解后图片格式" class="parameter-control-common-hu1s" size="large" v-model:value="parameter.format">
					<a-select-option value="jpg">jpg</a-select-option>
					<a-select-option value="jpeg">jpeg</a-select-option>
					<a-select-option value="png">png</a-select-option>
					<a-select-option value="bmp">bmp</a-select-option>
				</a-select>
			</template>
			<template v-else-if="format=='ico'">
				<label class="parameter-control-label">图标尺寸</label>
				<a-radio-group v-model:value="defaultParameter.icon.value" size="large" @change="defaultParameter.icon.change">
				    <a-radio :value="16">16*16</a-radio>
					<a-radio :value="20">20*20</a-radio>
					<a-radio :value="24">24*24</a-radio>
				    <a-radio :value="32">32*32</a-radio>
				    <a-radio :value="48">48*48</a-radio>
				    <a-radio :value="64">64*64</a-radio>
				    <a-radio :value="128">128*128</a-radio>
				    <a-radio :value="256">256*256</a-radio>
				  </a-radio-group>
			</template>
			<template v-else-if="format=='dpi'">
				<label class="parameter-control-label">DPI设置</label>
				<a-radio-group v-model:value="parameter.dpi" size="large">
				    <a-radio :value="72">72dpi</a-radio>
					<a-radio :value="96">96dpi</a-radio>
					<a-radio :value="150">150dpi</a-radio>
					<a-radio :value="200">200dpi</a-radio>
					<a-radio :value="300">300dpi</a-radio>
					<a-radio :value="350">350dpi</a-radio>
					<a-radio :value="500">500dpi</a-radio>
					<a-radio :value="1000">1000dpi</a-radio>
					<a-radio :value="1500">1500dpi</a-radio>
				  </a-radio-group>
			</template>
			<template v-else>
				<label class="parameter-control-label">选择尺寸</label>
				<a-select placeholder="设置视频压缩等级" class="parameter-control-common-hu1s" size="large" v-model:value="defaultParameter.size.value" @change="defaultParameter.size.change">
					<a-select-option v-for="(item,index) in defaultParameter.size.selectOptions" :value="index">{{item.title}}</a-select-option>
				</a-select>
				<a-input addon-before="图&ensp;片&ensp;宽"  class="parameter-control-common-hu1s"  size="large" placeholder="图片宽" v-model:value="parameter.width" @change="defaultParameter.changeWidth" addonAfter="px"></a-input>
				<a-input addon-before="图&ensp;片&ensp;高"  class="parameter-control-common-hu1s"  size="large" placeholder="图片高" v-model:value="parameter.height" @change="defaultParameter.changeHeight" addonAfter="px"></a-input>
				<a-select placeholder="设置图片压缩等级" class="parameter-control-common-hu1s" size="large" v-model:value="parameter.quality">
					<template #suffixIcon>
						<svg t="1655264345662" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7991" width="10" height="10">
							<path d="M946.4 353.50000001l-419.9 419.9c-8.2 8.2-21.5 8.2-29.7 0l-421.1-421.1c-16.4-16.4-16.4-43 0-59.3 16.4-16.4 43-16.4 59.3 0L504.2 662.20000001c4.1 4.1 10.7 4.1 14.8 0l368-368c16.4-16.4 43-16.4 59.3 0 16.5 16.3 16.5 42.9 0.1 59.3z" fill="#8a8a8a" p-id="7992"></path>
						</svg>
					</template>
					<a-select-option v-if="format=='zjz'" :value="100">不压缩</a-select-option>
					<a-select-option v-for="index of 49" :value="100-index*2">{{100-index*2}}{{100-index*2==70?"（推荐压缩等级）":""}}</a-select-option>
				</a-select>
			</template>
			<div class="parameter-control-handle-box"><a-button :loading="handleInfo.state == 1?true:false" @click="startCompress" class="media-handle-btn" size="large" type="primary">{{'开始'+handleName}}</a-button></div>
		</div>
	</div>
	<div class="mainContainer" :class="fileInfo.lists.length>0?'mainContainerShow':''">
		<div class="mainContainerL">
			<div :style="{display:(fileInfo.lists.length<1?'block':'none')}" class="media-files-select-box">
				<file-upload input-id="file" 
				:name="fileInfo.initData.name" :post-action="fileInfo.initData.postAction" 
				:extensions="fileInfo.initData.extensions"
				:accept="fileInfo.initData.accept" 
				:multiple="fileInfo.initData.multiple" 
				:directory="fileInfo.initData.directory" 
				:create-directory="fileInfo.initData.createDirectory"
				:maximum="fileInfo.initData.maximum"
				:size="fileInfo.initData.size || 0" 
				:thread="fileInfo.initData.thread < 1 ? 1 : (fileInfo.initData.thread > 5 ? 5 : fileInfo.initData.thread)" 
				:headers="fileInfo.initData.headers"
				:data="fileInfo.initData.data" 
				:drop="fileInfo.initData.drop" 
				:drop-directory="fileInfo.initData.dropDirectory" 
				:add-index="fileInfo.initData.addIndex"
				:chunk-enabled="fileInfo.initData.chunkEnabled" :chunk="fileInfo.initData.chunk" 
				v-model="fileInfo.lists" 
				@input-filter="mediaFilter" @input-file="mediaFile" ref="upload">
					<div class="media-files-upload-warp">
						<div>
							<p class="media-icon-k"><i class="iconfont ys-tianjiatupian ys__icon_i1"></i></p>
							<a-button shape="round" size="large" type="primary" class="ant-btn-mainc">{{format=='image_geshi_all'||format=='ico'||format=='dpi'?'选择图片':format=='gif_split'?'选择GIF图片分解':'选择图片压缩'}}</a-button>
							<div class="meadi__codoe_size__scf">支持的格式：{{supportFormt}}、单张图片最大支持100M、单次最多批量{{handleName}}60张。</div>
						</div>
					</div>
				</file-upload>
				<label ref="reselectMediaRef" for="file"></label>
			</div>
			<div v-if="fileInfo.lists.length>0" class="media-info-preview-warp" @contextmenu="(e)=>{e.preventDefault()}">
				<ul>
					<li v-for="(list,index) in fileInfo.lists">
						<a-image width="100%" :src="list.defaultInfoData.preview_url" placeholder fallback=""/>
						<div class="file-preview-desc-box">
							<div class="file-preview-desc-tip">
								<p class="file-org-title">{{list.file.name}}</p>
								<p>源文件：<span>{{$baseFunc.converSize(list.defaultInfoData.orgInfo.size)}}</span></p>
								<template v-if="format=='image_geshi_all'">
									<p>源格式：<span>{{list.defaultInfoData.orgInfo.format}}</span></p>
									<p v-if="list.defaultInfoData.being==4" class="after">转换后：<span>{{list.defaultInfoData.lastInfo.format}}</span></p>
								</template>
								<template v-else-if="format=='gif_split'">
									<p v-if="list.defaultInfoData.being==4" class="after">共分解出：<span style="font-weight: 600;">{{list.defaultInfoData.orgInfo.frame}}</span> 张图片</p>
								</template>
								<template v-else-if="format=='ico'">
									<p>源格式：<span>{{list.defaultInfoData.orgInfo.format}}</span></p>
									<p v-if="list.defaultInfoData.being==4" class="after">转换后：<span>{{list.defaultInfoData.lastInfo.format}}</span></p>
								</template>
								<template v-else-if="format=='dpi'">
									<p>源格式：<span>{{list.defaultInfoData.orgInfo.format}}</span></p>
									<p v-if="list.defaultInfoData.being==4" class="after">修改后：<span>{{list.defaultInfoData.lastInfo.dpi}}dpi</span></p>
									<p v-if="list.defaultInfoData.being==4" class="after">修改后：<span>{{list.defaultInfoData.lastInfo.format}}</span></p>
								</template>
								<template v-else>
									<p>源尺寸：<span v-if="list.defaultInfoData.orgInfo.width>0">{{list.defaultInfoData.orgInfo.width}}×{{list.defaultInfoData.orgInfo.height}}</span></p>
									<p v-if="list.defaultInfoData.being==4" class="after">压缩后：<span>{{list.defaultInfoData.lastInfo.width}}×{{list.defaultInfoData.lastInfo.height}}</span></p>
									<p v-if="list.defaultInfoData.being==4" class="after">压缩后：<span>{{$baseFunc.converSize(list.defaultInfoData.lastInfo.size)}}</span></p>
								</template>
							</div>
							<div>
								<a-progress v-if="list.defaultInfoData.being==5" :percent="100" status="exception" :showInfo="false" :strokeWidth="4"/>
								<a-progress v-else-if="list.defaultInfoData.being==4" :percent="100" status="success" :showInfo="false" :strokeWidth="4"/>
								<a-progress v-else-if="list.defaultInfoData.being>1" strokeColor="#feaa01" :percent="100" status="active" :showInfo="false" :strokeWidth="4"/>
								<a-progress v-else strokeColor="#feaa01" :percent="list.progress?$baseFunc.retainTwoDecimals(list.progress):0" status="active" :showInfo="false" :strokeWidth="4"/>
							</div>
							
							<div class="file-preview-success-box">
								<div v-if="list.defaultInfoData.being==4" class="file-preview-success-item" :style="{justifyContent:format=='image_geshi_all'||format=='gif_split'||format=='ico'?'center':'space-between'}">
									<a-button danger size="small" @click="downloadMediaFunc(index)" type="primary" :loading="list.defaultInfoData.load">{{format=='gif_split'?'打包下载':'下载图片'}}</a-button>
									<a-button v-if="format!='image_geshi_all'&&format!='gif_split'&&format!='ico'" size="small" @click="previewMediaInfo.previewMediaFunc(this,index)">查看预览</a-button>
								</div>
								<div v-else-if="list.defaultInfoData.being==5" class="file-preview-error-item"><FrownTwoTone :style="{fontSize:'24px',marginRight:'6px'}" twoToneColor="#FF4D4F" />处理失败</div>
								<span v-else-if="list.defaultInfoData.being==1">正在上传中，请稍后…</span>
								<span v-else-if="list.defaultInfoData.being>1 && list.defaultInfoData.being<4">正在{{handleName}}中，请稍后…</span>
								<span v-else>待{{handleName}}</span>
							</div>
						</div>
					</li>
				</ul>
				<div class="files-handle-btns-container" v-if="fileInfo.lists.length>0">
					<div>
						<span>已选择 {{fileInfo.lists.length}} 张</span>，<span>已{{handleName}} {{handleInfo.successTotal}} 张</span>
					</div>
					<div class="files-handle-btns-container-btnt">
						<a-button v-if="handleInfo.state==2" class="media-handle-btn" size="large"  @click="clearSelectFiles(1)">重新选择</a-button>
						<label v-if="handleInfo.state<1" for="file" class="ant-btn ant-btn-primary ant-btn-lg media-handle-btn">继续添加</label>
						<a-button v-if="handleInfo.state==2" class="media-handle-btn" size="large" danger @click="downloadMediaFunc(-1)" type="primary" :loading="downloadInfo.load">{{fileInfo.lists.length>1?'打包下载':'立即下载'}}</a-button>
					</div>
				</div>
			</div>
		</div>
	</div>
	
</template>
<script>
	import { FrownTwoTone } from '@ant-design/icons-vue';
	import {message,Modal} from 'ant-design-vue';
	import GifParse from "gif-parser-web";
	import baseFunc from '../libs/base_func.js'
	export default ({
		components: {
			FrownTwoTone
		},
		props: {
			types:{
				type:String,
				default:"image_compress",
			},
			format:{
				type:String,
				default:"all",
			},
			idKey:{
				type:String,
				default:null,
			},
			extra:{
				type:[String,Object],
				default:"",
			}
		},
		mounted() {
			switch(this.format){
				case "gif":
					this.fileInfo.initData.accept='image/gif';
					this.fileInfo.initData.extensions='gif';
					this.supportFormt='GIF';
					break;
				case "png":
					this.fileInfo.initData.accept='image/png';
					this.fileInfo.initData.extensions='png';
					this.supportFormt='PNG';
					break;
				case "jpg":
					this.fileInfo.initData.accept='image/jpg,image/jpeg';
					this.fileInfo.initData.extensions='jpg,jpeg';
					this.supportFormt='JPG、JPEG';
					break;
				case "webp":
					this.fileInfo.initData.accept='image/webp';
					this.fileInfo.initData.extensions='webp';
					this.supportFormt='WEBP';
					break;
				case "image_geshi_all":
					this.handleName='转换'
					this.fileInfo.initData.accept='image/jpg,image/jpeg,image/png,image/gif,image/webp,image/bmp,image/tiff';
					this.fileInfo.initData.extensions='jpg,jpeg,png,gif,webp,bmp,tiff';
					this.supportFormt='JPG、JPEG、PNG、GIF、WEBP、BMP、TIFF';
					this.fileInfo.initData.data.types="image_format";
					break;
				case "gif_split"://gif拆分
					this.handleName='分解'
					this.fileInfo.initData.accept='image/gif';
					this.fileInfo.initData.extensions='gif';
					this.supportFormt='GIF';
					this.parameter.format='jpg';
					this.fileInfo.initData.data.types="image_gif_split";
					break;
				case "ico"://gif拆分
					this.handleName='转换'
					this.fileInfo.initData.accept='image/jpg,image/jpeg,image/png,image/gif,image/webp,image/bmp,image/tiff';
					this.fileInfo.initData.extensions='jpg,jpeg,png,gif,webp,bmp,tiff';
					this.supportFormt='JPG、JPEG、PNG、GIF、WEBP、BMP、TIFF';
					this.fileInfo.initData.data.types="image_ico";
					this.parameter.format="ico";
					this.parameter.width = 32;
					this.parameter.height = 32;
					break;
				case "dpi"://修改图片dpi
					this.handleName="修改"
					this.fileInfo.initData.accept='image/jpg,image/jpeg,image/png,image/gif,image/webp,image/bmp,image/tiff';
					this.fileInfo.initData.extensions='jpg,jpeg,png,gif,webp,bmp,tiff';
					this.supportFormt='JPG、JPEG、PNG、GIF、WEBP、BMP、TIFF';
					this.fileInfo.initData.data.types="image_dpi";
					this.parameter.dpi = 300;
					this.parameter.format = "jpg";
					break;
				case "zjz":
					this.fileInfo.initData.accept='image/jpg,image/jpeg,image/png,image/webp,image/bmp,image/tiff';
					this.fileInfo.initData.extensions='jpg,jpeg,png,webp,bmp,tiff';
					this.supportFormt='JPG、JPEG、PNG、WEBP、BMP、TIFF';
					this.fileInfo.initData.data.types="image_zjz";
					let selectOptions = [
							{title:'小一寸照',width:259,height:377},
							{title:'一寸照',width:295,height:413},
							{title:'大一寸照',width:389,height:466},
							{title:'小二寸照',width:413,height:531},
							{title:'二寸照',width:413,height:625},
							{title:'三寸照',width:649,height:991},
							{title:'四寸照',width:898,height:1205},
							{title:'五寸照',width:1500,height:1050},
							{title:'六寸照',width:1200,height:1800,},
						]
					let val = 0;
					if(this.extra){
						for(let i=0;i<selectOptions.length;i++){
							if(selectOptions[i].width==this.extra.width&&selectOptions[i].height==this.extra.height){
								val=i+1;
								break;
							}
						}
					}
					selectOptions.unshift({title:'源尺寸',width:-2,height:-2})
					selectOptions.push({title:'自定义',width:-1,height:-1})
					this.defaultParameter.size.selectOptions=selectOptions
					this.defaultParameter.size.value = 0;
					break;
				default:
					this.fileInfo.initData.accept='image/jpg,image/jpeg,image/png,image/gif,image/webp';
					this.fileInfo.initData.extensions='jpg,jpeg,png,gif,webp';
					this.supportFormt='JPG、JPEG、PNG、GIF、WEBP';
					break;
			}
		},
		created() {
			if(this.idKey)this.getOriginalInfoInit();
			this.ratios=[{0:1}]
		},
		data() {
			return {
				handleName:'压缩',
				supportFormt:'JPG、JPEG、PNG、GIF、WEBP',
				defaultParameter:{
					timer:null,
					clearTimer(){
						/*清除定时器*/
						if(this.timer){
							clearInterval(this.timer);
							window.clearInterval(this.timer);
							this.timer = null;
						}
					},
					size:{
						selectOptions:[
							{
								title:'源尺寸',
								width:-2,
								height:-2,
							},
							{
								title:'适合电脑',
								width:1280,
								height:'',
							},
							{
								title:'适合手机横屏',
								width:960,
								height:'',
							},
							{
								title:'适合手机竖屏',
								width:480,
								height:'',
							},
							{
								title:'微信表情（大）',
								width:200,
								height:'',
							},
							{
								title:'微信表情（小）',
								width:140,
								height:'',
							},
							{
								title:'证件1寸照',
								width:295,
								height:413,
							},
							{
								title:'证件2寸照',
								width:413,
								height:626,
							},
							{
								title:'自定义',
								width:-1,
								height:-1,
							},
						],
						value:0,
						change:(index)=>{
							let item = this.defaultParameter.size.selectOptions[index];
							if(item.width==-1){
							}else if(item.width==-2){
								this.parameter.width = "";
								this.parameter.height = "";
							}else{
								this.parameter.width = item.width;
								this.parameter.height = item.height;
							}
						},
					},
					icon:{
						value:32,
						change:(e)=>{
							this.parameter.width = e.target.value;
							this.parameter.height = e.target.value;
						},
					},
					changeWidth:()=>{
						this.defaultParameter.size.value=this.defaultParameter.size.selectOptions.length-1;
						this.parameter.width = baseFunc.checkNumber(this.parameter.width,4096);
						if(this.parameter.width==0)this.parameter.width=""
					},
					changeHeight:()=>{
						this.defaultParameter.size.value=this.defaultParameter.size.selectOptions.length-1;
						this.parameter.height = baseFunc.checkNumber(this.parameter.height,4096);
						if(this.parameter.height==0)this.parameter.height=""
					},
				},
				parameter:{//调节参数 parameter.compress_type
					quality:70,//压缩品质
					format:"",/*输出格式*/
					width:"",/*输出宽*/
					height:"",/*输出高*/
					dpi:300,/*图片dpi*/
				},
				fileInfo:{
					lists:[],
					initData:{
						accept: 'image/jpg,image/jpeg,image/png,image/gif,image/webp',
						extensions: 'jpg,jpeg,png,gif,webp',
						size: 100 *1024* 1024,/*文件最大支持 100M*/
						maxMediaTotal:60,/*单次最多支持处理图片数量*/
						multiple: true,/*支持批量*/
						directory: false,/*是否是上传文件夹*/
						drop: true,/*拖拽上传*/
						dropDirectory: false,/*支持拖拽文件夹*/
						createDirectory: false,
						addIndex: true,
						thread: 5,/*上传线程*/
						maximum:60,/*列表最大文件数*/
						name: 'file',
						chunkEnabled: true,/*批量上传*/
						chunk: {
							action: process.env.VUE_APP_BASE_URL + '/file/uploadChunk',
							minSize: 10 * 1024 * 1024,/*小于10M则直接上传-不需要分片*/
							maxActive: 1,/*分片批量上传线程*/
							maxRetries: 5,
							headers: {
								"Authorization": this.$baseFunc.getAuthorization(),
								"Media-Type": "image",
							},
						},
						postAction: process.env.VUE_APP_BASE_URL + '/file/upload',
						headers: {
							"Authorization": this.$baseFunc.getAuthorization(),
							"Media-Type": "image",
						},
						data: {
							types: "image",
						},
					},
				},
				handleInfo:{
					state:0,/*0:待处理 1：处理中 2：处理成功*/
					training:false,
					successTotal:0,/*已处理成功数量*/
					errorTotal:0,/*处理失败数量*/
					allSise:0,/*总共需要处理文件大小*/
					afterSuccessSize:0,/*已成功处理文件大小*/
					afterErrorSize:0,/*处理失败文件大小*/
				},
				downloadInfo:{
					load:false,
				},
				previewMediaInfo:{
					visible:false,
					type:"image",
					path:"",
					previewMediaFunc(_this,index){/*预览处理后的图片*/
						if(_this.fileInfo.lists.length<1 || !_this.fileInfo.lists[index].defaultInfoData.lastInfo.preview_url)return false;
						_this.$preview(_this.fileInfo.lists[index].defaultInfoData.lastInfo.preview_url)
						// this.path = _this.fileInfo.lists[0].defaultInfoData.lastInfo.preview_url;
						// this.visible=true;
					},
				}
			}
		},
		methods:{
			
			/*初始化查询源文件*/
			getOriginalInfoInit(){
				this.$apiRequest.post('/file/getOriginalInfo', {
					key:this.idKey,
					way:3,
				}).then(res => {
					this.fileInfo.lists=[];
					let mediaInfo = res.data.information;
					let defaultInfoData={
						being:0,/*0：待上传 1：上传中 2：上传成功 3：处理中 4：处理成功 5：处理失败*/
						progress:0,/*处理进度*/
						load:false,
						preview_url:process.env.VUE_APP_BASE_URL+res.data.preview_url,
						orgInfo:{/*源图片信息*/
							loadedState:1,/*0：待加载 1：加载成功 2：加载失败*/
							width:0,
							height:0,
							bit_rate:parseInt(mediaInfo.audio.bit_rate),//源码率
							sample_rate:parseInt(mediaInfo.audio.sample_rate),//源采样率
							duration:parseInt(mediaInfo.duration),
							size:parseInt(mediaInfo.size),
							format:res.data.format,
							ext:res.data.format,
							key:res.data.key,
							frame:1,
						},
						lastInfo:{/*处理后图片信息*/
							width:0,
							height:0,
							duration:0,
							size:0,
							format:"",
							ext:"",
							key:"",
							preview_url:"",
						}
					}
					if(this.format=="gif_split"){
						try{
							const gifParse = new GifParse(process.env.VUE_APP_BASE_URL+res.data.preview_url);
							const gifInfo = gifParse.getInfo(null);
							gifInfo.then((res)=>{
								this.fileInfo.lists[0].defaultInfoData.orgInfo.frame=res.images.length
							})
						}catch(e){
							//TODO handle the exception
						}
						
					}
					this.fileInfo.lists[0]={
						file:{
							name:res.data.filename,
							size:defaultInfoData.orgInfo.size,
						},
						defaultInfoData:defaultInfoData,
						afetrOriginalInfo:1,
					}
					this.rangeDataInit(defaultInfoData.orgInfo.duration)
				}).catch(err => {
				})
			},
			/*获取图片信息*/
			mediaSkipFunc(duration){
				if(this.fileInfo.lists.length>0 && this.fileInfo.lists[0].defaultInfoData.preview_url && this.fileInfo.lists[0].defaultInfoData.orgInfo.loadedState==1){
					this.$refs.mediaRef.currentTime = duration;
				}
			},
			/*清空已上传文件*/
			clearSelectFiles(state){
				this.$refs.upload.clear();
				this.handleInfo={
					state:0,/*0:待处理 1：上传中 2：已全部上传成功 3：处理中 4：处理成功*/
					training:false,
					successTotal:0,/*已处理成功数量*/
					errorTotal:0,/*处理失败数量*/
					allSise:0,/*总共需要处理文件大小*/
					afterSuccessSize:0,/*已成功处理文件大小*/
					afterErrorSize:0,/*处理失败文件大小*/
				}
				if(state==1)this.reselectMedia();
			},
			reselectMedia(){
				this.$refs.reselectMediaRef.dispatchEvent(new MouseEvent('click'))
			},
			mediaFilter(newFile, oldFile, prevent) {
				if (newFile && !oldFile) {
					if(newFile.file.type.split("/")[0]!="image"){
						message.error('请选择正确的图片！');
						return prevent();
					}
					if(newFile.size>this.fileInfo.initData.size){
						message.warning('最大支持 100M 图片，请重新选择！');
						return prevent();
					}
				}
			},
			mediaFile(newFile, oldFile) {
				if(newFile && typeof newFile.afetrOriginalInfo!='undefined' && newFile.afetrOriginalInfo)return false;
				if (newFile && !oldFile) {
					if(this.fileInfo.lists.length>this.fileInfo.initData.maxMediaTotal){
						this.$refs.upload.remove(newFile)
						message.warning('单次最多支持'+this.handleName+' '+this.fileInfo.initData.maxMediaTotal+' 张图片！');
						return false;
					}
					this.defaultParameter.clearTimer()
					
					// 添加文件
					newFile.defaultInfoData={
						being:0,/*0：待上传 1：上传中 2：上传成功 3：处理中 4：处理成功 5：处理失败*/
						progress:0,/*处理进度*/
						preview_url:"",
						load:false,
						orgInfo:{/*源图片信息*/
							loadedState:0,/*0：待加载 1：加载成功 2：加载失败*/
							width:0,
							height:0,
							duration:0,
							size:newFile.file.size,
							format:"",
							ext:"",
							key:"",
							frame:1,
							dpi:"未知",
						},
						lastInfo:{/*处理后图片信息*/
							width:0,
							height:0,
							duration:0,
							size:0,
							format:"",
							ext:"",
							key:"",
							preview_url:"",
							dpi:300,
						}
					}
					
					if(this.format=="gif_split"){
						const gifParse = new GifParse(null);
						const gifInfo = gifParse.getInfo(newFile.file);
						gifInfo.then((res)=>{
							newFile.defaultInfoData.orgInfo.frame=res.images.length
						})
					}
					
					try {
						newFile.defaultInfoData.orgInfo.format = newFile.file.type.split('/')[1]
					} catch (e) {
					}
					let URL = (window.URL || window.webkitURL)
					if (URL) {
						newFile.defaultInfoData.preview_url = URL.createObjectURL(newFile.file)
					}
					
				}
				// 添加，更新，移除 后
				if (newFile && oldFile) {
					// 更新文件
					if (newFile.success !== oldFile.success) {
						if (newFile.response && newFile.response.code == 200) {
							newFile.defaultInfoData.being=2;
							newFile.defaultInfoData.orgInfo.key=newFile.response.md5
							if(newFile.response.information && newFile.response.information.image){
								newFile.defaultInfoData.orgInfo.width = parseInt(newFile.response.information.image.width)
								newFile.defaultInfoData.orgInfo.height = parseInt(newFile.response.information.image.height)
							}
							this.beingCompress()
						}
					}
				}
				if (!newFile && oldFile) {
					// 删除文件
				}
			},
			startCompress(){
				if(this.fileInfo.lists.length<1){
					this.reselectMedia()
				}else{
					if(this.format=='image_geshi_all' && !this.parameter.format){
						message.warning('请选择需要转换的格式');
						return false;
					}
					if (!this.$refs.upload || !this.$refs.upload.active) {
						this.$refs.upload.active = true;
					}
					this.handleInfo.state = 1;
					/*已经处理完成，再次处理*/
					for (let i = 0; i < this.fileInfo.lists.length; i++) {
						if (this.fileInfo.lists[i].defaultInfoData.being>2) {
							this.fileInfo.lists[i].defaultInfoData.being = 2
						}else if(this.fileInfo.lists[i].afetrOriginalInfo){
							this.fileInfo.lists[i].defaultInfoData.being = 2
						}
						this.fileInfo.lists[i].defaultInfoData.lastInfo.dpi = this.parameter.dpi
					}
					this.beingCompress();
				}
			},
			beingCompress(){
				const _this = this
				let keys = [];
				let fileTotal = 0,compressingTatal = 0;
				for (let i = 0; i < this.fileInfo.lists.length; i++) {
					fileTotal++;
					if (this.fileInfo.lists[i].defaultInfoData.being == 2) {
						keys.push(this.fileInfo.lists[i].defaultInfoData.orgInfo.key)
						compressingTatal++
						this.fileInfo.lists[i].defaultInfoData.being=3;
					}
				}
				if(keys.length>0){
					this.$apiRequest.post('/file/submitMediaProcessingTask', {
						keys:keys.join(','),
						types:this.types,
						width:this.parameter.width?parseInt(this.parameter.width):0,
						height:this.parameter.height?parseInt(this.parameter.height):0,
						quality:this.parameter.quality,
						format:this.parameter.format,
						dpi:this.parameter.dpi,
					}).then(res => {
						if (res.code == 200) {
							for (let i = 0; i < res.data.length; i++) {
								for (let j = 0; j < this.fileInfo.lists.length; j++) {
									if (this.fileInfo.lists[j].defaultInfoData.orgInfo.key == res.data[i].md5) {
										this.fileInfo.lists[j].defaultInfoData.lastInfo.key = res.data[i].handle_md5
									}
								}
							}
							if (!this.handleInfo.training) {
								this.getTaskProgress()
							}
						}
					}).catch(err => {
					})
				}
				
			},
			getTaskProgress(){
				this.handleInfo.training = true;
				const _this = this
				/*查询处理进度*/
				let keys = [];
				for (let i = 0; i < this.fileInfo.lists.length; i++) {
					if (this.fileInfo.lists[i].defaultInfoData.being == 3) {
						keys.push(this.fileInfo.lists[i].defaultInfoData.lastInfo.key)
					}
				}
				if (keys.length > 0) {
					this.$apiRequest.post('/file/getTaskProgress', {
						keys:keys.join(","),
					}).then(res => {
						if (res.code == 200) {
							for (let i = 0; i < res.data.length; i++) {
								for (let j = 0; j < this.fileInfo.lists.length; j++) {
									if (this.fileInfo.lists[j].defaultInfoData.lastInfo.key == res.data[i].md5) {
										switch (res.data[i].code) {
											case 200:
												/*处理成功*/
												this.fileInfo.lists[j].defaultInfoData.being = 4;
												this.fileInfo.lists[j].defaultInfoData.lastInfo.size=parseFloat(res.data[i].data.size)
												this.fileInfo.lists[j].defaultInfoData.lastInfo.format=res.data[i].data.format
												this.fileInfo.lists[j].defaultInfoData.lastInfo.preview_url=res.data[i].data.preview_url
												if(res.data[i].data.image){
													this.fileInfo.lists[j].defaultInfoData.lastInfo.width = parseInt(res.data[i].data.image.width)
													this.fileInfo.lists[j].defaultInfoData.lastInfo.height = parseInt(res.data[i].data.image.height)
												}
												break;
											case 10000:
												/*处理失败*/
												this.fileInfo.lists[j].defaultInfoData.being = 5;
												break;
											case 10201:
											case 10202:
												break;
										}
										break;
									}
								}
							}
						}
						this.tongjiHandleResult()
					}).catch(err => {})
				}else{
					this.tongjiHandleResult()
				}
			},
			tongjiHandleResult(){
				var _this = this
				this.handleInfo.successTotal = 0;
				this.handleInfo.errorTotal = 0;
				this.handleInfo.state = 1;
				this.handleInfo.afterSuccessSize = 0;
				this.handleInfo.afterErrorSize = 0;
				let handleTotal = 0,
					handleAlreadyTotal = 0;
				for (let j = 0; j < this.fileInfo.lists.length; j++) {
					handleTotal++
					/*being说明：0：待上传 1：上传中 2：上传成功 3：处理中 4：处理成功 5：处理失败*/
					if (this.fileInfo.lists[j].defaultInfoData.being > 3) {
						handleAlreadyTotal++
						if (this.fileInfo.lists[j].defaultInfoData.being == 4) {
							this.handleInfo.afterSuccessSize += parseInt(this.fileInfo.lists[j].defaultInfoData.lastInfo.size);
							this.handleInfo.successTotal++
						} else if (this.fileInfo.lists[j].defaultInfoData.being == 5) {
							this.handleInfo.afterErrorSize += parseInt(this.fileInfo.lists[j].defaultInfoData.lastInfo.size);
							this.handleInfo.errorTotal++
						}
					}
				}

				if (handleAlreadyTotal >= handleTotal) {
					/*全部处理完成*/
					/*state -- 0:待处理 1：处理中 2：处理成功*/
					this.handleInfo.state = 2;
					this.handleInfo.training = false;
				} else {
					this.defaultParameter.timer = setTimeout(function() {
						_this.getTaskProgress()
					}, 1000)
				}
			},
			/*下载图片*/
			downloadMediaFunc(index){
				this.previewMediaInfo.visible = false;
				let downloadKeys = [];
				if(index>-1){
					if(!this.fileInfo.lists[index].defaultInfoData.lastInfo.key){
						Modal.warning({
							title:'下载提示',
							content:'请先处理后再下载！',
						});
						return false;
					}
					this.fileInfo.lists[index].defaultInfoData.load = true;
					downloadKeys.push(this.fileInfo.lists[index].defaultInfoData.lastInfo.key)
				}else{
					this.downloadInfo.load=true;
					for (let j = 0; j < this.fileInfo.lists.length; j++) {
						/*being说明：0：待上传 1：上传中 2：上传成功 3：处理中 4：处理成功 5：处理失败*/
						if (this.fileInfo.lists[j].defaultInfoData.being == 4) {
							downloadKeys.push(this.fileInfo.lists[j].defaultInfoData.lastInfo.key)
						}
					}
				}
				if(downloadKeys.length>0){
					this.$apiRequest.post('/file/decideDownload', {
						file_handle_md5s:downloadKeys.join(","),
					}).then(res => {
						if(index>-1){
							this.fileInfo.lists[index].defaultInfoData.load = false;
						}else{
							this.downloadInfo.load=false;
						}
						window.location.href=process.env.VUE_APP_BASE_URL+res.download_url
					}).catch(err => {
						if(index>-1){
							this.fileInfo.lists[index].defaultInfoData.load = false;
						}else{
							this.downloadInfo.load=false;
						}
						this.$emit("userHandleCall",err);
					})
				}
				
			}
		},
		
	});
</script>
<style lang="less">
	.parameter-control-box input{font-size: 14px;height: 40px;}
	.parameter-control-box .ant-select-lg{font-size: 14px;}
	.parameter-control-common-time-box input{border: unset !important;outline: unset;box-shadow: unset !important;padding: 0;height: 38px;}
	.media-files-upload-warp button.ant-btn{height: 44px;width: 160px;}
	.media-files-select-box label{cursor: pointer;}
	.media-info-preview-warp li img{max-width: 220px;max-height: 350px;object-fit: contain;cursor: zoom-in;cursor: -webkit-zoom-in;}
	@media(max-width:760px){
		.media-info-preview-warp li img{
			max-width:100%;
			max-height: calc(50vw - 36px);
		}
		.parameter-control-box{
			.ant-radio-group{
				display: flex;
				flex-wrap: wrap;
				margin-bottom: 12px;
				.ant-radio-wrapper{
					width: 25%;
					margin: 0 0 8px 0;
				}
			}
		}
	}
</style>
<style lang="less" scoped>
	.parameter-control-warp{position:relative;padding:0 0 12px 0;margin:0;}
	.ant-btn-mainc{
		background-color: #ffffff;
		color: #00B277;
		border: 1px solid #00B277;
		margin: 0 20px;
	}
	.parameter-control-warp .parameter-control-box{width:100%;position:relative;margin-bottom:12px;display:flex;display:-webkit-flex;height:40px;align-items:center;justify-content:flex-start}
	.parameter-control-warp .parameter-control-box:last-child{margin-bottom:0}
	.parameter-control-label{display:inline-block;padding:0px 15px;width:auto;font-weight:400;text-align:right;min-width:86px;height:40px;line-height:40px}
	.parameter-control-box .parameter-control-common-hu1s{margin-right:12px;width:208px}
	.parameter-control-box .parameter-control-common-hu1s:last-child{margin-right:0}
	.parameter-control-common-time-hu1s{width:44px;text-align:center}
	.parameter-control-common-time-separator-hu1s{margin:0}
	.parameter-control-common-time-box{border:1px solid #d9d9d9;border-radius:2px}
	.parameter-control-box .parameter-control-common-time-range-hu1s{margin:-12px 44px 0;width:341px}
	.media-crop-recover-range{margin-left:12px;min-width:92px;font-size:14px}
	.mainContainer{position:relative;width:100%;border-radius:0;display:flex;display:-webkit-flex;border-radius: 10px;overflow: hidden;}
	.mainContainerL{flex:1;min-height:480px;background-color:#ffffff;position:relative;display:flex;justify-content:center;align-items:center;transition:all 0.2s;}
	.mainContainerR{width:0;min-height:280px;position:relative;background-color:#ffffff;transition:all 0.2s;overflow: hidden;}
	.fileinfoTable{display: none;}
	.mainContainerShow .mainContainerR{width:280px;}
	.mainContainerShow .fileinfoTable{display:table;}
	.parameter-control-box{
		:deep{
			.ant-input-group-addon:last-child{
				color: rgba(0,0,0,0.65);
			}
		}
	}
	.parameter-control-warp .parameter-control-handle-box{position: absolute;right: 0;bottom: 0;}
	.media-handle-btn{font-size: 14px;width: 208px;}
	.parameter-control-handle-box .media-handle-btn{
		font-size: 14px;
		background: linear-gradient(90deg, #00B277 0%, #00D885 100%);
		border-radius: 7px;
		border: 0;
		&:hover{
			background: linear-gradient(90deg, #00D885 0%, #00B277 100%);
		}
	}
	.btnsContainer{position:absolute;width:100%;bottom:0;left:0;right:0;text-align:center;margin:0 auto}
	.btnsContainer .ant-btn{margin-top:12px}
	.fileinfoTable tr:nth-child(even){background-color:#e4e4e4}
	.fileinfoTable{width:100%;text-align:center}
	.fileinfoTable td{font-size:14px;text-align:center;line-height:50px}
	.fileinfoTable th{width:33.3%;font-size:15px;line-height:50px}
	.fileinfoTable .media-filename{overflow:hidden;text-overflow:ellipsis;white-space:nowrap;max-width:80px;display:block;text-align:center;margin:auto}
	.media-files-select-box,.media-files-select-box .file-uploads,.media-files-upload-warp{width:100%;height:100%;display:flex;margin:0;padding:0;position:relative;align-items:center;justify-content:center;color:#ffffff}
	.media-files-upload-warp .media-icon-k{margin:auto;padding:0}
	.media-files-upload-warp .media-icon-k .ys__icon_i1{font-size:44px;color:#cdcdcd}
	.media-files-upload-warp .meadi__codoe_size__scf{color:#bfbfbf;font-size:16px;margin-top:40px}
	.media-info-warp{padding:24px;box-sizing:border-box;position:relative;width:100%;height:100%;display:flex;display:-webkit-flex;align-items:center;justify-content:center}
	.media-info-warp video{max-width:100%;max-height:100%}
	.media-state-box{width:100%;display:flex;display:-webkit-flex;flex-wrap:nowrap;justify-content:center;line-height:1.5;margin:0;padding:6px 24px;position:relative;color:var(--main-color);font-size:16px;text-align:center}
	.media-state-box text{margin-left:3px}
	.media-state-box>div{width:100%}
	.media-state-box .waiting__process__tip{color:#999999}
	.media-state-box .media-state-progress-desc{width:100%;display:flex;display:-webkit-flex;margin-top:4px;justify-content:space-between;font-weight:normal;color:#252525}
	.media-state-box .media-state-item{text-align:center;width:100%;display:flex;display:-webkit-flex;justify-content:center;align-items:center;font-weight:bold}
	.media-state-box .media-state-success{color:#52C41A}
	.media-state-box .media-state-error{color:#FF4D4F}
	.media-info-preview-warp{
		padding:24px;
		box-sizing:border-box;
		position:relative;
		width:100%;
		height:100%;
	}
	.media-info-preview-warp ul{
		width: 100%;
		position: relative;
		margin: 0;
		padding: 0;
		height: auto;
		display: flex;
		display: -webkit-flex;
		flex-wrap: wrap;
	}
	.media-info-preview-warp li{
		position: relative;
		list-style-type: none;
		/* color: #ffffff; */
		background-color: #F2F5FA;
		width: 220px;
		height: 350px;
		margin-right: 13px;
		margin-bottom: 13px;
		border-radius: 6px;
		overflow: hidden;
		display: flex;
		display: -webkit-flex;
		align-items: flex-start;
		justify-content: center;
		transition: transform 0.25s;
		padding: 0;
	}
	.media-info-preview-warp li:nth-child(5n){margin-right: 0;}
	.media-info-preview-warp li:hover{
		transform: translateY(-6px);
	}
	
	.media-info-preview-warp li .file-preview-desc-box{
		position: absolute;
		bottom:0;
		left: 0;
		width: 100%;
		background-color: rgba(242, 245, 250, 0.95);
		color:#252525;
		padding: 4px 8px;
		height: 176px;
		box-shadow: 1px 0 1px rgba(0, 0, 0, 0.2);
	}
	.file-preview-desc-box .file-preview-desc-tip{
		height: calc(100% - 22px - 36px);
	}
	.file-preview-desc-tip .file-org-title{
		width: 100%;
		text-align: center;
		color: #777777;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
	.file-preview-desc-box p{
		margin: 0;
		padding: 0;
		line-height: 22px;
	}
	.file-preview-desc-box p.after span{
		color:#52c41a;
	}
	.file-preview-success-box{
		width: 100%;
		height: 36px;
		display: flex;
		display: -webkit-flex;
		align-items: center;
		justify-content: center;
	}
	.file-preview-success-box>span{color:rgba(0,0,0,0.65);font-size: 13px;}
	.file-preview-success-box .file-preview-success-item{
		width: 100%;
		height: 100%;
		display: flex;
		display: -webkit-flex;
		align-items: center;
		justify-content: space-between;
	}
	.file-preview-error-item{
		width: 100%;
		display: flex;
		display: -webkit-flex;
		align-items: center;
		justify-content: center;
		color: #FF4D4F;
	}
	.file-preview-success-item .ant-btn{
		height: 28px;
		padding: 0 12px;
	}
	.files-handle-btns-container{
		width: 100%;
		height: auto;
		position: relative;
		display: flex;
		display: -webkit-flex;
		justify-content: space-between;
		align-items: center;
		color: #333;
		margin-top: 11px;
	}
	.files-handle-btns-container .ant-btn{margin-left: 13px;width: 220px;}
	
	@media(max-width:760px){
		.parameter-control-warp .parameter-control-handle-box{
			position: relative;
			width: 100%;
			.media-handle-btn{
				width: 100%;
			}
		}
		.parameter-control-warp .parameter-control-box{
			height: auto;
			flex-wrap: wrap;
		}
		.parameter-control-box .parameter-control-common-hu1s{
			width: 100%;
			margin-bottom: 12px;
			margin-right: 0;
		}
		.parameter-control-label{
			padding: 0;
			text-align: left;
		}
		.media-info-preview-warp{
			padding: 12px;
			li{
				width: calc(50% - 6px);
				margin-right: 12px;
				margin-bottom: 12px;
				height: 300px;
				&:nth-child(5n){
					margin-right: 12px;
				}
				&:nth-child(2n){
					margin-right: 0;
				}
				img{
					max-width:100%;
					max-height: calc(50vw - 36px);
				}
			}
		}
		.files-handle-btns-container{
			flex-direction: column;
			text-align: center;
			.files-handle-btns-container-btnt{
				display: flex;
				width: 100%;
				margin-top: 12px;
			}
			.ant-btn{
				width: calc(50% - 6px);
				flex: 1;
				margin: 0;
				&:first-child{margin-right: 12px;}
				&:only-child{margin: 0;}
			}
		}
	}
	
</style>
