<template>
	<div>
		<!-- 一级菜单导航 -->
		<div class="topnavCon">
			<nav class="topNav" :class="full?'topnav-full':''">
				<a href="/" class="logo" title="91压缩" ><img width="121" src="/logo.png?v=1.1.5"></a>
				<a class="phone-item" :class="topNavActive==1?'topNavActive':''" href="/">视频工具</a>
				<a class="phone-item" :class="topNavActive==2?'topNavActive':''" :href="$baseFunc.findUrls('audio')">音频工具</a>
				<a class="phone-item" :class="topNavActive==3?'topNavActive':''" :href="$baseFunc.findUrls('image')">图片工具</a>
				<a class="phone-item" :class="topNavActive==6?'topNavActive':''" :href="$baseFunc.findUrls('pdf')">PDF工具</a>
				<a class="phone-item" :class="topNavActive==4?'topNavActive':''" :href="$baseFunc.findUrls('help')">帮助中心</a>
				<a-popover placement="bottom" overlayClassName="userInfomationWarp__iHg2s" :destroyTooltipOnHide="false">
					<template #content>
						<div class="nav-map-box">
							<div class="nav-map-lists" v-for="item in $baseFunc.getMap()">
								<div class="nav-map-d-title">{{item.title}}</div>
								<div class="nav-map-list">
									<div class="nav-map-item" v-for="list in item.lists">
										<a :href="list.url" target="_blank">{{list.title}}</a>
									</div>
								</div>
							</div>
							<div class="nav-map-more"><a :href="$baseFunc.findUrls('map')" target="_blank">查看所有功能</a></div>
						</div>
					</template>
					<span class="phone-item" >所有功能</span>
				</a-popover>
				
				<div class="up-vip-box" @click.stop="vipData.openVipModalFunc(this)">
					<i class="iconfont ys-f-vip"></i>
					<div>升级会员</div>
				</div>
				
				
				
				<!-- <span :class="topNavActive==4?'topNavActive':''" @click.stop="comeSoonBtn()">API服务</span> -->
				<a-popover v-if="userInfo" placement="bottomRight" overlayClassName="userInfomationWarp__iHg2s" :destroyTooltipOnHide="false">
			        <template #content>
						<div class="userInfomation__header_box">
							<div class="userInfomation__header_item">
								<div class="userInfomation__header_avatar"><img :src="userInfo.avatarurl"/></div>
								<div class="userInfomation__header_userinfo">
									<p class="userInfomation__header_nickname">{{userInfo.nickname}}</p>
									<p class="userInfomation__header_balance">
										<span v-if="userInfo.vip==2 && userInfo.effectivetime">到期时间：<text class="userInfomation__header_balance__price">{{userInfo.effectivetime}}</text><text v-if="userInfo.expired==2" style="color:red;">（已到期）</text></span>
										<span v-else>余额：<text  class="userInfomation__header_balance__price">￥{{userInfo.balance}}</text></span>
									</p>
								</div>
							</div>
							<div class="userInfomation__upgrade__vip__item">
								<div class="userInfomation__upgrade__vip__content">
									<div class="userInfomation__upgrade__vip__desc">
										<span>{{userInfo.version}}</span>
										<span>视频、音频、图片、PDF一键处理</span>
									</div>
									<div class="userInfomation__upgrade__vip__btn"><a-button  @click.stop="vipData.openVipModalFunc(this)" shape="round" type="primary">{{userInfo.vip>0?"续费":"开通"}}</a-button></div>
								</div>
							</div>
							<div class="userInfomation__header__meaus__item">
								<div class="userInfomation__header__meaus__list"><a :href="$baseFunc.findUrls('user')" target="_blank"><i class="iconfont ys-icon_setting"></i>账号设置</a></div>
								<div class="userInfomation__header__meaus__list"><a :href="$baseFunc.findUrls('order')" target="_blank"><i class="iconfont ys-vip" target="_blank"></i>订单中心</a></div>
								<div class="userInfomation__header__meaus__list"><a :href="$baseFunc.findUrls('record')" target="_blank"><i class="iconfont ys-jilu" target="_blank"></i>处理中心</a></div>
								<div class="userInfomation__header__meaus__divider"></div>
								<div class="userInfomation__header__meaus__list"><a :href="$baseFunc.findUrls('logout')"><i class="iconfont ys-anquantuichu1" target="_blank"></i>安全退出</a></div>
							</div>
						</div>
			        </template>
			        <a :class="topNavActive==5?'topNavActive':''" class="userInfomationbox__iHg2s" v-if="userInfo" :href="$baseFunc.findUrls('user')">
						<img :src="userInfo.avatarurl"/>
						<text>{{userInfo.nickname}}</text>
					</a>
			    </a-popover>
				<div v-if="!userInfo"  class="user__login__iHg2s pc-login">
					<div class="user__item__iHg2s user__register_i__iHg2s" @click="loginModalInfo.openLoginModal(4)">注册</div>
					<div class="user__item__iHg2s user__login_i__iHg2s" @click="loginModalInfo.openLoginModal(1)">登录</div>
				</div>
				<span class="user__login__iHg2s phone-login" v-if="!userInfo" @click="loginModalInfo.openLoginModal">注册 / 登录</span>
			</nav>
		</div>
		<div class="topnavCon-perch"></div>
		
		<div class="phone-warp">
			<a class="phone-item" :class="topNavActive==1?'topNavActive':''" href="/">视频</a>
			<a class="phone-item" :class="topNavActive==2?'topNavActive':''" :href="$baseFunc.findUrls('audio')">音频</a>
			<a class="phone-item" :class="topNavActive==3?'topNavActive':''" :href="$baseFunc.findUrls('image')">图片</a>
			<a class="phone-item" :class="topNavActive==6?'topNavActive':''" :href="$baseFunc.findUrls('pdf')">PDF</a>
			<a class="phone-item" :class="topNavActive==4?'topNavActive':''" :href="$baseFunc.findUrls('help')">帮助中心</a>
		</div>
		<!-- 二级导航菜单 -->
		<div v-if="secNavActive>0" class="second-nav-b">
			<div class="second-nav-box">
				<template v-if="topNavActive==1">
					<a class="second-nav-box_item" :class="format=='all'?'active':''" :href="$baseFunc.findUrls('video')">视频压缩</a>
					<a class="second-nav-box_item" :class="format=='mp4'?'active':''" :href="$baseFunc.findUrls('video_mp4')">mp4压缩</a>
					<a class="second-nav-box_item" :class="format=='mov'?'active':''" :href="$baseFunc.findUrls('video_mov')">mov压缩</a>
					<a class="second-nav-box_item" :class="format=='avi'?'active':''" :href="$baseFunc.findUrls('video_avi')">avi压缩</a>
					<a class="second-nav-box_item" :class="format=='video_format'?'active':''" :href="$baseFunc.findUrls('video_format')">转格式</a>
					<a class="second-nav-box_item" :class="format=='fenduan'?'active':''" :href="$baseFunc.findUrls('video_fenduan')">批量分段</a>
					<a class="second-nav-box_item" :class="format=='video_extract'?'active':''" :href="$baseFunc.findUrls('video_extract')">视频提取</a>
					<a class="second-nav-box_item" :class="format=='video_to_image'?'active':''" :href="$baseFunc.findUrls('video_to_image')">视频提取图片</a>
				</template>
				<template v-if="topNavActive==2">
					<a class="second-nav-box_item" :class="format=='all'?'active':''" :href="$baseFunc.findUrls('audio')">音频压缩</a>
					<a class="second-nav-box_item" :class="format=='wav'?'active':''" :href="$baseFunc.findUrls('audio_wav')">wav压缩</a>
					<a class="second-nav-box_item" :class="format=='flac'?'active':''" :href="$baseFunc.findUrls('audio_flac')">flac压缩</a>
					<a class="second-nav-box_item" :class="format=='audio_format'?'active':''" :href="$baseFunc.findUrls('audio_format')">转格式</a>
					<!-- <a class="second-nav-box_item" :class="format=='fenduan'?'active':''" :href="$baseFunc.findUrls('audio_fenduan')">批量分段</a> -->
				</template>
				<template v-if="topNavActive==3">
					<a class="second-nav-box_item" :class="format=='all'?'active':''" :href="$baseFunc.findUrls('image')">图片压缩</a>
					<a class="second-nav-box_item" :class="format=='size'?'active':''" :href="$baseFunc.findUrls('image_size')">指定大小压缩</a>
					<a class="second-nav-box_item" :class="format=='png'?'active':''" :href="$baseFunc.findUrls('image_png')">png压缩</a>
					<a class="second-nav-box_item" :class="format=='jpg'?'active':''" :href="$baseFunc.findUrls('image_jpg')">jpg压缩</a>
					<a class="second-nav-box_item" :class="format=='webp'?'active':''" :href="$baseFunc.findUrls('image_webp')">webp压缩</a>
					<a class="second-nav-box_item" :class="format=='zjz'?'active':''" :href="$baseFunc.findUrls('image_zjz')">证件照压缩</a>
					<a class="second-nav-box_item" :class="format=='dpi'?'active':''" :href="$baseFunc.findUrls('image_dpi')">修改DPI</a>
					<a class="second-nav-box_item" :class="format=='gif'?'active':''" :href="$baseFunc.findUrls('image_gif')">gif压缩</a>
					<a class="second-nav-box_item" :class="format=='gif_split'?'active':''" :href="$baseFunc.findUrls('gif_split')">gif拆分</a>
					<a class="second-nav-box_item" :class="format=='image_geshi_all'?'active':''" :href="$baseFunc.findUrls('image_geshi_all')">转格式</a>
					<a class="second-nav-box_item" :class="format=='ico'?'active':''" :href="$baseFunc.findUrls('image_ico')">图片转ico</a>
				</template>
				<template v-if="topNavActive==6">
					<a class="second-nav-box_item" :class="format=='all'?'active':''" :href="$baseFunc.findUrls('pdf')">PDF压缩</a>
					<a class="second-nav-box_item" :class="format=='pdf_split'?'active':''" :href="$baseFunc.findUrls('pdf_split')">PDF拆分</a>
					<a class="second-nav-box_item" :class="format=='pdf_merge'?'active':''" :href="$baseFunc.findUrls('pdf_merge')">PDF合并</a>
					<a class="second-nav-box_item" :class="format=='pdf_encrypt'?'active':''" :href="$baseFunc.findUrls('pdf_encrypt')">PDF加密</a>
					<a class="second-nav-box_item" :class="format=='pdf_decode'?'active':''" :href="$baseFunc.findUrls('pdf_decode')">PDF移除密码</a>
				</template>
			</div>
		</div>
		
		
		<!-- 升级会员 -->
		<a-modal :visible="vipData.visible" wrapClassName="vip-modal-warp" @cancel="vipData.closeVipModalFunc(this)" width="980px" :centered="true" :destroyOnClose="true" :footer="null" :keyboard="true" :maskClosable="false">
			<vip-modal ref="vipModalRef" :userInfo="userInfo" @paymentComplete="paymentComplete" @paymentError="paymentError"></vip-modal>
		</a-modal>
		<!-- 登录 -->
		<a-modal class="loginModal" width="730px" v-model:visible="loginModalInfo.visible"  @cancel="loginModalInfo.closeLoginModal(this)" :destroyOnClose="true" :centered="true" title="" :maskClosable="false" :footer="null">
		    <login ref="loginModalRef" @loginSuccess="loginSuccess"></login>
		</a-modal>
		<!-- 客服系统 -->
		<div class="ys-service-warp" v-if="topNavActive!=5 && topNavActive!=100">
			<a-popover placement="leftBottom">
				<template #content>
					<div class="batch-service-pop">
						<img src="/images/91yasuo_chat.jpg" />
						<p>扫描二维码关注</p>
					</div>
				</template>
				<div class="ys-service-item">
					<i class="iconfont ys-erweima"></i>
					<div>公众号</div>
				</div>
			</a-popover>
			<a-popover placement="leftBottom">
				<template #title>
					<span class="ys-service-title-jxuhs">在线咨询</span>
				</template>
				<template #content>
					<div class="service-consult-warp">
						<div class="service-consult-item">
							<div class="service-consult-title">QQ客服</div>
							<div class="service-consult-content">
								<p>客服QQ: 369818417 <a class="ant-btn ant-btn-primary ant-btn-sm" title="联系客服" href="http://wpa.qq.com/msgrd?v=3&amp;uin=369818417&amp;site=qq&amp;menu=yes" target="_blank">咨询</a></p>
								<p>客服QQ: 404656437 <a class="ant-btn ant-btn-primary ant-btn-sm" title="联系客服" href="http://wpa.qq.com/msgrd?v=3&amp;uin=404656437&amp;site=qq&amp;menu=yes" target="_blank">咨询</a></p>
							</div>
						</div>
						<div class="service-consult-item">
							<div class="service-consult-title">微信客服咨询</div>
							<div class="service-consult-content"><img src="/images/chat_kf.jpg" /></div>
						</div>
						<div class="service-consult-item">
							<div class="service-consult-title">电话咨询</div>
							<div class="service-consult-content">
								<p>客服电话: 177-1367-1031</p>
								<p>客服电话: 159-2865-7177</p>
								<p>周一至周六：09:00-18:00</p>
							</div>
						</div>
					</div>
				</template>
				<div class="ys-service-item">
					<i class="iconfont ys-kefu"></i>
					<div>客服</div>
				</div>
			</a-popover>
			<a-back-top style="position: unset;width: auto;height: auto;">
				<div class="ys-service-item">
					<i class="iconfont ys-zhiding" style="font-size: 24px;"></i>
					<div>返回顶部</div>
				</div>
			</a-back-top>
		</div>
	</div>
</template>

<script>
	import vipModal from "./vip.vue";
	import login from './login.vue';
	import { Modal,message } from 'ant-design-vue';
	import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
	import { createVNode } from 'vue';
	
	export default{
		name:'topnav',
		components:{
			vipModal,login
		},
		props:{
			topNavActive:{
				type:Number,
				default:1,//1:视频工具 2：音频工具 3：图片工具 4:帮助中心 5:个人中心 6：pdf
			},
			secNavActive:{
				type:Number,
				default:0,//二级菜单
			},
			format:{
				type:String,
				default:"all",//二级菜单-类型
			},
			full:{
				type:Boolean,
				default:false,//是否左右撑满
			},
			userInfoData:Object||String,
		},
		watch:{
			userInfoData:{
			    handler(newVal,oldVal){
					if(newVal && newVal!=oldVal){
						this.userInfo = JSON.parse(JSON.stringify(newVal))
					}
			    },
			    deep:true// 深度监听父组件传过来对象变化
			}
		},
		created() {
			/*获取微信登录二维码*/
			this.getUserInfo()
		},
		data() {
			return {
				userInfo:null,
				loginModalInfo:{
					visible:false,
					openLoginModal:(way)=>{
						/*打开登录*/
						this.loginModalInfo.visible=true;
						if(!way)way = 1
						this.$nextTick(()=>{
							if(window.screen.width<760 && way==1){
								way = 2
							}
							this.$refs.loginModalRef.cutLoginWay(way)
						})
						
					},
					closeLoginModal(_self){
						/*关闭登录*/
						_self.$refs.loginModalRef.clearTimer();/*清除登录所有定时器*/
						this.visible=false;
					},
				},
				vipData:{
					visible:false,
					closeVipModalFunc:function(_self){
						_self.$refs.vipModalRef.clearTimer();
						this.visible = false;
					},
					openVipModalFunc:function(_self){
						if(_self.userInfo){
							this.visible = true;
						}else{
							_self.loginModalInfo.openLoginModal();
						}
					}
				},
			}
		},
		methods: {
			comeSoonBtn(){
				Modal.warning({
					title:'即将开放，尽情期待！',
					okText:"知道了",
				});
			},
			getUserInfo(){
				this.$apiRequest.post('/user/getUserInfo', {
				}).then(res => {
					this.userInfo = res.user
					this.$emit('userInfoCallback',{user:res.user})
				}).catch(err => {
				})
			},
			callUserHandleFunc(state,message){
				const _this = this
				switch(parseInt(state)){
					case 10100:/*调用登录*/
						this.loginModalInfo.openLoginModal();
						break;
					case 10111:/*调用充值*/
						if(!message){message="该视频已超出 3M 限制，请升级会员后再下载！"}
						Modal.confirm({
							title: () => '提示信息',
							icon: () => createVNode(ExclamationCircleOutlined),
							content: () => createVNode('div', { style: 'color:red;font-size:16px;' }, message),
							okText:"升级会员",
							cancelText:"取消",
							onOk() {
								_this.vipData.openVipModalFunc(_this);
							},
							onCancel() {
							},
						});
						break;
					case 111://直接打开充值
						this.vipData.openVipModalFunc(this);
						break;
				}
			},
			/*支付成功回调*/
			paymentComplete(rent){
				this.userInfo = JSON.parse(JSON.stringify(rent.userInfo))
				this.$emit('userInfoCallback',{user:rent.userInfo})
				this.vipData.closeVipModalFunc(this)
			},
			/*支付错误回调*/
			paymentError(res){
				if(typeof res.code!="undefined"){
					switch(res.code){
						case 10100:/*请先登录*/
							this.loginModalInfo.openLoginModal()
							break;
					}
				}
				this.vipData.closeVipModalFunc(this)
			},
			/*登录成功回调*/
			loginSuccess(rent){
				this.userInfo = rent.user
				this.$emit('userInfoCallback',{user:rent.user})
				this.loginModalInfo.closeLoginModal(this)
			},
		},
	}
</script>
<style>
	/* 全局样式 */
	.vip-modal-warp .ant-modal .ant-modal-body{padding: 0;}
	.loginModal .ant-modal-body{padding:0;}
	/*修改框架样式*/
	.userInfomationWarp__iHg2s .ant-popover-content{margin-top: -20px !important;}
	.userInfomationWarp__iHg2s .ant-popover-arrow{display: none;}
</style>
<style scoped lang="less">
	.nav-map-box{
		width: 600px;
		min-width: 10%;
		background-color: #fff;
		padding: 0 14px;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		.nav-map-lists{
			margin-bottom: 12px;
			.nav-map-d-title{
				height: 18px;
				line-height: 18px;
				color: var(--main-color);
				font-size: 15px;
				font-weight: bold;
				padding-left: 10px;
				border-left: 3px solid var(--main-color);
				margin: 10px 0 5px;
			}
			.nav-map-list{
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				.nav-map-item{
					a{
						display: block;
						line-height: 26px;
						height: 26px;
						text-align: left;
						font-weight: normal;
						color: #333;
						padding: 0;
						margin-right: 15px;
						font-size: 13px;
						display: flex;
						align-items: center;
						justify-content: center;
						&:hover{
							color: var(--main-color-h);
						}
					}
				}
			}
		}
		.nav-map-more{
			width: 100%;
			text-align: center;
			padding: 8px;
			a{
				display: flex;
				align-items: center;
				justify-content: center;
				line-height: 32px;
				height: 32px;
				width: 120px;
				border-radius: 16px;
				background-color: var(--main-color);
				color: #fff;
				font-size: 12px;
				margin: 0 auto;
				&:hover{
					background-color: var(--main-color-h);
				}
			}
		}
	}
	.topNav{
		width: var(--max-width);
		margin: 0 auto;
		height: 100%;
		display: flex;
		position: relative;
		justify-content: flex-start;
		&.topnav-full{
		   width: 100%;
		   padding: 0 30px;
		   box-sizing: border-box;
		}
		.up-vip-box{
			height: 34px;
			width: auto;
			padding: 0 12px;
			font-size: 16px;
			line-height: 1;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 18px;
			position: relative;
			cursor: pointer;
			color: #ffffff;
			top: 0;
			bottom: 0;
			margin: auto 0;
			background: linear-gradient(90deg, #39A0FF 0%, #5966F3 100%);
			transition: all 0.2s;
			i{
				display: flex;
				align-items: center;
				justify-content: center;
				line-height: 1;
				border-radius: 100%;
				background-color: #1E88E9;
				padding: 5px;
				font-size: 14px;
				box-shadow: 0 0 3px #e1e1e1;
				margin-right: 4px;
			}
			&:hover{
				background: linear-gradient(90deg, #5966F3 0%, #39A0FF 100%);
			}
		}
	 }
	.topNav a,.topNav span{
	   height: 65px;
	   line-height: 65px;
	   font-size: 16px;
	   color: #333333;
	   margin: 0 20px;
	   transition: all .3s;
	   text-decoration: none;
	   cursor:pointer;
	}
	.topNav a.logo{
		display:flex;
		display:-webkit-flex;
		align-items: center;
		margin-left: 0px;
	}
	/*独立样式*/
	.topNav .topNavActive{
		 color: #00B277;
		 font-weight: 500;
	}
	.topNav a:hover,.topNav span:hover{
	   color: #00B277;
	}
	.topNav .user__login__iHg2s{
		width: auto;
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
		display: flex;
		align-items: center;
		&.pc-login{
			display:flex;
		}
		&.phone-login{
			display:none;
		}
		.user__item__iHg2s{
			height: 38px;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 80px;
			font-size: 16px;
			border-radius: 5px;
			cursor: pointer;
			transition: all 0.2s;
		}
		.user__register_i__iHg2s{
			border: 1px solid #00B277;
			color:#00B277;
			margin-right: 15px;
			&:hover{
				background: linear-gradient(180deg, #FFFFFF 0%, #FFBF00 0%, #00B277 0%, #00D885 100%);
				color: #ffffff;
			}
		}
		.user__login_i__iHg2s{
			border: none;
			background: linear-gradient(90deg, #FFFFFF 0%, #FFBF00 0%, #00B277 0%, #00D885 100%);
			color: #fff;
			&:hover{
				background: linear-gradient(180deg, #FFFFFF 0%, #FFBF00 0%, #00B277 0%, #00D885 100%);
			}
		}
	}
	.second-nav-b{
		background-color: #ffffff;
	}
	.second-nav-box{
		width: var(--max-width);
		height: 70px;
		background-color: #ffffff;
		color: #ffffff;
		display: flex;
		align-items: center;
		margin:0 auto 16px ;
		&_item{
		    width: auto;
		    padding: 0 24px;
		    height: 100%;
		    line-height: 1;
		    display: flex;
		    align-items: center;
		    justify-content: center;
		    color: #666666;
		    transition: all .4s ease 0s;
			font-size: 15px;
			&:hover{color: var(--main-color);}
			&:first-child{margin-left: -24px;}
			position: relative;
			&.active{
				color: #333333;
				&::after{
					position: absolute;
					content: "";
					background: #00B277;
					height: 4px;
					width: calc(100% - 48px);
					border-radius: 2px;
					bottom: 0;
				}
			}
		}
	}
	.topnavCon{
	   width: 100%;
	   height: 65px;
	   background-color: #ffffff;
	   position: fixed;
	   top: 0;
	   left: 0;
	   z-index: 100;
	}
	.topnavCon-perch{width:100%;height:65px;}
	.userInfomationbox__iHg2s{
		display: flex;
		display: -webkit-flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		position: absolute;
		right: 0px;
		top: 0px;
	}
	.userInfomationbox__iHg2s img{
		width: 28px;
		height: 28px;
		border-radius: 50%;
		margin-right: 4px;
	}
	.userInfomationbox__iHg2s text{
		max-width: 120px;
		width: 120px;
		overflow: hidden;
		white-space: nowrap;
		-o-text-overflow: ellipsis;
		text-overflow: ellipsis;
		display: block;
	}
	.userInfomation__header_box{
		width:240px;
		min-height: 120px;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	    position: relative;
	}
	.userInfomation__header_item{
		width: 100%;
		padding: 0;
		display: flex;
		display: -webkit-flex;
		margin: 0 0 20px 0;
		height: 54px;
		align-items: center;
	}
	.userInfomation__header_avatar{
		width: 54px;
		height: 54px;
		margin-right: 12px;
	}
	.userInfomation__header_avatar img{width: 100%;height: 100%;object-fit: cover;}
	.userInfomation__header_userinfo{
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: start;
		-ms-flex-align: start;
		align-items: flex-start;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
	}
	.userInfomation__header_nickname{
		max-width: 130px;
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		overflow: hidden;
		white-space: nowrap;
		-o-text-overflow: ellipsis;
		text-overflow: ellipsis;
		color: rgba(0,0,0,0.85);
		margin: 0 0 2px 0;
		padding: 0;
	}
	.userInfomation__header_balance{
		font-weight: 400;
		font-size: 12px;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		color: rgba(0,0,0,0.65);
		margin: 0;
	}
	.userInfomation__header_balance__price{
		font-weight: 600;
		font-size: 14px;
		color: var(--main-color);
	}
	.userInfomation__upgrade__vip__item{
		width: 100%;
		padding: 12px;
		border-radius: 6px;
		background-color: var(--main-ucb-color);
		color:rgba(0,0,0,0.85);
		font-size: 16px;
	}
	.userInfomation__upgrade__vip__content{
		height: 100%;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		font-size: 12px;
		line-height: 20px;
		padding: 0;
	}
	.userInfomation__upgrade__vip__desc{
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-flex: 1;
		-ms-flex: 1;
		flex: 1;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: start;
		-ms-flex-align: start;
		align-items: flex-start;
	}
	.userInfomation__upgrade__vip__desc span:first-child{
		font-size: 14px;
		font-weight: 600;
		line-height: 22px;
		margin-bottom: 2px;
		white-space: nowrap;
	}
	.userInfomation__header__meaus__item{
		width: calc(100% + 32px);
		margin: 12px 0 0 -16px;
		position: relative;
		
	}
	.userInfomation__header__meaus__item .userInfomation__header__meaus__list{
		width: 100%;
		height: 44px;
		padding: 0 16px;
		cursor: pointer;
		transition: all 0.3s;
	}
	.userInfomation__header__meaus__item .userInfomation__header__meaus__list a{
		width: 100%;
		height: 100%;
		display: flex;
		display: -webkit-flex;
		align-items: center;
		color: rgba(0,0,0,0.85);
		font-size: 14px;
		transition: all 0.3s;
	}
	.userInfomation__header__meaus__item .userInfomation__header__meaus__divider{
		margin: 8px 16px;
		width: calc(100% - 32px);
		height: 1px;
		background: var(--main-xiala-color);
	}
	.userInfomation__header__meaus__item .userInfomation__header__meaus__list a i{
		margin-right: 6px;
	}
	.userInfomation__header__meaus__item .userInfomation__header__meaus__list:hover{
		background-color: var(--main-xiala-color);
	}
	.userInfomation__header__meaus__item .userInfomation__header__meaus__list:hover a{
		color: #000000;
	}
	.ys-service-warp{
		text-align: center;
		position: fixed;
		bottom: 50px;
		right: 24px;
		width: 68px;
		background-color: #ffffff;
		z-index: 100;
		box-shadow: 0 0 2px rgba(0,0,0,0.15);
		border-radius: 4px;
		overflow: hidden;
	}
	.ys-service-warp .ys-service-item{
		width: 100%;
		text-align: center;
		padding: 6px 0;
		border-bottom: 2px solid #f9f9f9;
		cursor: pointer;
		color: #9C9FA2;
		transition: all 0.2s;
	}
	.ys-service-warp .ys-service-item:hover{
		background-color: var(--main-color);
		color: #ffffff;
	}
	.ys-service-item i{
		font-size: 26px;
	}
	
	.ys-service-title-jxuhs{text-align: center;font-weight: bold;display: block;width: 100%;font-size: 16px;}
	.service-consult-warp{color: #000000;}
	.service-consult-warp .service-consult-item{margin-bottom: 12px;}
	.service-consult-warp .service-consult-title{
		font-weight: 600;
		margin-bottom: 8px;
		font-size: 15px;
	}
	.service-consult-warp .service-consult-content{
		color: rgba(0,0,0,0.65);
	}
	.service-consult-content img{width: 120px;}
	.service-consult-content p{margin-bottom: 8px;}
	.batch-service-pop{width:auto;text-align:center}
	.batch-service-pop img{width:150px;height:auto;margin-bottom:4px}
	.phone-warp{display: none;}
	@media(max-width:760px){
		.topNav .user__login__iHg2s{
			&.pc-login{
				display:none;
			}
			&.phone-login{
				display:flex;
			}
		}
		
		.topnavCon .phone-item{
			display: none;
		}
		.userInfomationbox__iHg2s text{
			width: 60px;
		}
		.second-nav-box{
			flex-wrap: wrap;
			height: auto;
			margin-top: 0;
			.second-nav-box_item{
				width: 33.3%;
				height: 44px;
				padding: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				&:first-child{margin-left: 0;}
			}
		}
		.ys-service-warp{
			display: none;
		}
		.topNav a.logo{
			margin: 0 12px;
		}
		.topNav .user__login__iHg2s{
			margin: 0 12px;
		}
		.phone-warp{
			display: flex;
			align-items: center;
			justify-content: space-between;
			height: 60px;
			width: 100%;
			padding: 0 12px;
			.phone-item{
				color: #000000;
				font-size: 16px;
				&.topNavActive{
					color: var(--main-color);
				}
			}
		}
	}
</style>