import axios from 'axios'
import baseFunc from './base_func.js'
import Qs from 'qs'
import {message} from 'ant-design-vue';

axios.defaults.baseURL=process.env.VUE_APP_BASE_URL;
// 请求拦截器
axios.interceptors.request.use(    
    config => {
		if (config.method !== 'get') {
			config.headers.Authorization = baseFunc.getAuthorization();    
		};
		return config;
    },
    error => {        
        return Promise.error(error);    
    }
)
// 响应拦截器
axios.interceptors.response.use(    
    response => {        
        if (response.status === 200) {
			if (response.data.code === 200) {
				return Promise.resolve(response);
			} else {
				return Promise.reject(response);
			}      
        } else {            
            return Promise.reject(response);        
        }
    },
	error => {   
		// 服务器状态码不是200的情况  
        if (error.response.status) {       
            return Promise.reject(error.response);        
        }       
    }
);
// 判断传入参数的类型，以字符串的形式返回
function dataType(obj) {
	if (obj === null) return "Null";
	if (obj === undefined) return "Undefined";
	return Object.prototype.toString.call(obj).slice(8, -1);
}
// 处理对象参数值，排除对象参数值为”“、null、undefined，并返回一个新对象
function dealObjectValue(obj) {
	let param = {};
	if (obj === null || obj === undefined || obj === "") return param;
	for (let key in obj) {
		if (dataType(obj[key]) === "Object") {
			param[key] = dealObjectValue(obj[key]);
		} else if (obj[key] !== null && obj[key] !== undefined && obj[key] !== "") {
			param[key] = obj[key];
		}
	}
	return param;
}

const apiRequest={
	post(url, params){
		if(!params)params={};
		return axios({
			method: "post",
			url,
			data: Qs.stringify(dealObjectValue(params)),
			headers: {
				"Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
				'Accept': 'application/json'
			}
		}).then(res => {
			return Promise.resolve(res.data);
		}).catch(error => {
			return Promise.reject(error.data);
		});
	},
	get(url, params){
		return axios({
			method: "get",
			url,
			params: dealObjectValue(params),
			headers: {}
		}).then(res => {
			return Promise.resolve(res.data);
		}).catch(error => {
			return Promise.reject(error.data);
		});
	},
	// 上传文件
	uploadFile(url, file,extra,progress){
		return new Promise(function(resolve, reject){
			if(!file){
				reject({
					code:10001,
					message:'请选择音视频文件',
					extra_data_defult:extra
				})
			};
			let media_types = file.type.split("/");
			let upload_type = (extra && typeof extra.upload_type!='undefined' && extra.upload_type)?extra.upload_type:'img';
			let checkExt = baseFunc.checkFileExt(baseFunc.getType(file.name,true),upload_type);
			if(checkExt){
				message.error(checkExt);
				reject({
					code:10001,
					message:checkExt,
					extra_data_defult:extra
				})
				return
			}else{
				if(upload_type=="video" && media_types[0]!="video"){
					message.error("请上传视频文件");
					reject({
						code:10001,
						message:'请上传视频文件',
						extra_data_defult:extra,
					})
					return
				}else if(upload_type=="audio" && media_types[0]!="audio"){
					message.error("请上传音频文件");
					reject({
						code:10001,
						message:'请上传音频文件',
						extra_data_defult:extra,
					})
					return
				}else if(upload_type=="img" && media_types[0]!="image"){
					message.error("请上传正确的图片文件");
					reject({
						code:10001,
						message:'请上传正确的图片文件',
						extra_data_defult:extra,
					})
					return
				}else if(media_types[0]!="audio" && media_types[0]!="video"&& media_types[0]!="image"){
					message.error("请上传正确的文件");
					reject({
						code:10001,
						message:'请上传正确的文件',
						extra_data_defult:extra,
					})
					return
				}
			}
			
			const formData = new FormData()
			extra = JSON.parse(JSON.stringify(extra))
			for(let item in extra){
				formData.append(item, extra[item])
			}
			formData.append('file', file)
			return axios({
				method: "post",
				url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				onUploadProgress:function(progressEvent){
					progressEvent.percent = 100*progressEvent.loaded/progressEvent.total | 0
					progressEvent.extra_data_defult = extra;
					progress&&progress(progressEvent)
				},
			}).then(res => {
				res.data.extra_data_defult = extra;
				resolve(res.data);
			}).catch(err => {
				err.data.extra_data_defult = extra;
				reject(err.data);
			});
		})
		
	},
}

export default apiRequest;