import config from './config.js'
import md5 from 'js-md5'
import {message,Modal} from 'ant-design-vue';
import {createVNode } from 'vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';

const baseFunc = {
	getMap(){
		return [
			{
				title:"视频",
				url:"/",
				lists:[
					{
						title:"视频压缩",
						url:"/",
						desc:"一键压缩视频、压缩视频到指定大小、视频尺寸裁剪、时长裁剪等",
						img:"/ico/video-ys.png",
					},
					{
						title:"mp4压缩",
						url:this.findUrls('video_mp4'),
						desc:"mp4在线无损压缩、mp4指定大小、尺寸缩小、时长裁剪",
						img:"/ico/mp4.png",
					},
					{
						title:"mov压缩",
						url:this.findUrls('video_mov'),
						desc:"mov在线无损压缩、mp4指定大小、尺寸缩小、时长裁剪",
						img:"/ico/mov.png",
					},
					{
						title:"avi压缩",
						url:this.findUrls('video_avi'),
						desc:"avi在线无损压缩、mp4指定大小、尺寸缩小、时长裁剪",
						img:"/ico/avi.png",
					},
					{
						title:"转格式",
						url:this.findUrls('video_format'),
						desc:"视频格式批量转换,mov、mp4、avi、flv、mkv等格式互转",
						img:"/ico/video-gs.png",
					},
					{
						title:"批量分段",
						url:this.findUrls('video_fenduan'),
						desc:"多种格式视频与音频批量导出片段、分割多段保存、视频分割器",
						img:"/ico/video-fd.png",
					},
					{
						title:"视频提取",
						url:this.findUrls('video_extract'),
						desc:"提取视频中音频,去除视频中的音乐,批量提取音频,批量去除背景音乐",
						img:"/ico/video-yq.png",
					},
					{
						title:"视频提取图片",
						url:this.findUrls('video_to_image'),
						desc:"视频在线提帧、视频逐帧提取图片、视频图片提取",
						img:"/ico/video-to-image.png",
					},
				]
			},
			{
				title:"图片",
				url:this.findUrls('image'),
				lists:[
					{
						title:"图片压缩",
						url:this.findUrls('image'),
						desc:"一键无损压缩图片、图片大小批量压缩、批量修改图片尺寸",
						img:"/ico/img-ys.png",
					},
					{
						title:"指定大小压缩",
						url:this.findUrls('image_size'),
						desc:"压缩图片到200kb以下、指定图片大小压缩、图片压缩到指定kb",
						img:"/ico/tp-size.png",
					},
					{
						title:"png压缩",
						url:this.findUrls('image_png'),
						desc:"png无损压缩、批量压缩png大小、压缩修改png尺寸",
						img:"/ico/png.png",
					},
					{
						title:"jpg压缩",
						url:this.findUrls('image_jpg'),
						desc:"jpg无损压缩、批量压缩jpeg大小、批量修改jpg尺寸",
						img:"/ico/jpg.png",
					},
					{
						title:"webp压缩",
						url:this.findUrls('image_webp'),
						desc:"webp无损压缩、批量压缩webp大小、批量修改webp尺寸",
						img:"/ico/webp.png",
					},
					{
						title:"证件照压缩",
						url:this.findUrls('image_zjz'),
						desc:"证件照批量压缩工具、证件照尺寸大全、批量压缩证件照",
						img:"/ico/zjz.png",
					},
					{
						title:"修改DPI",
						url:this.findUrls('image_dpi'),
						desc:"修改照片分辨率,批量调整照片DPI",
						img:"/ico/dpi.png",
					},
					{
						title:"转格式",
						url:this.findUrls('image_geshi_all'),
						desc:"图片转格式,图片格式批量转换,支持jpg、png、tiff、webp、bmp等格式互转",
						img:"/ico/tuzh.png",
					},
					{
						title:"图片转ico",
						url:this.findUrls('image_ico'),
						desc:"jpg转ico图标、png转ico图标、gif转ico图标、webp转ico图标",
						img:"/ico/ico.png",
					},
					{
						title:"gif压缩",
						url:this.findUrls('image_gif'),
						desc:"gif无损压缩、批量压缩gif大小、批量修改gif尺寸",
						img:"/ico/gif.png",
					},
					{
						title:"gif拆分",
						url:this.findUrls('gif_split'),
						desc:"gif分解、gif批量拆解、gif拆帧、动图分解工具",
						img:"/ico/gif-cf.png",
					},
				]
			},
			{
				title:"PDF",
				url:this.findUrls('pdf'),
				lists:[
					{
						title:"PDF压缩",
						url:this.findUrls('pdf'),
						desc:"PDF批量压缩、PDF无损压缩、PDF压缩大小",
						img:"/ico/pdf-ys.png",
					},
					{
						title:"PDF拆分",
						url:this.findUrls('pdf_split'),
						desc:"拆分PDF文件、PDF转jpg、PDF转png、PDF转webp、PDF转长图",
						img:"/ico/pdf-cf.png",
					},
					{
						title:"PDF合成",
						url:this.findUrls('pdf_merge'),
						desc:"pdf合并、pdf拼接、pdf多页合并",
						img:"/ico/pdf-hb.png",
					},
					{
						title:"PDF加密",
						url:this.findUrls('pdf_encrypt'),
						desc:"PDF文件加密、pdf批量加密",
						img:"/ico/pdf-jm.png",
					},
					{
						title:"PDF移除密码",
						url:this.findUrls('pdf_decode'),
						desc:"pdf解密、pdf在线移除密码、提取pdf文件",
						img:"/ico/pdf-yc.png",
					}
				]
			},
			{
				title:"音频",
				url:this.findUrls('audio'),
				lists:[
					{
						title:"音频压缩",
						url:this.findUrls('audio'),
						desc:"修改码率、音频时间裁剪、音频指定大小压缩、修改音频采样率",
						img:"/ico/yp.png",
					},
					{
						title:"wav压缩",
						url:this.findUrls('audio_wav'),
						desc:"wav在线无损压缩、wav指定大小、wav指定码率、时长裁剪",
						img:"/ico/wav.png",
					},
					{
						title:"flac压缩",
						url:this.findUrls('audio_flac'),
						desc:"flac在线无损压缩、flac指定大小、flac指定码率、时长裁剪",
						img:"/ico/flac.png",
					},
					{
						title:"音频转格式",
						url:this.findUrls('audio_format'),
						desc:"音频格式批量转换,mp3、flac、wav、wma、asf、wav等格式互转",
						img:"/ico/ypzh.png",
					},
				]
			}
		  ]
	},
	findUrls(type,p){
		let urlsObj=null;
		if(process.env.NODE_ENV=="production"){
			urlsObj = config.ProductionUrls
		}else{
			urlsObj = config.DevelopmentUrls
		}
		switch(type){
			case "video":/*视频压缩*/
				return urlsObj.video.ys
			case "video_batch":/*视频压缩*/
				return urlsObj.video.batch
			case "video_mp4":/*视频压缩*/
				return urlsObj.video.mp4
			case "video_mov":/*视频压缩*/
				return urlsObj.video.mov
			case "video_avi":/*视频压缩*/
				return urlsObj.video.avi
			case "video_fenduan":/*视频分段*/
				return urlsObj.video.fenduan
			case "video_extract":/*视频提取*/
				return urlsObj.video.extract
			case "video_format":/*视频转格式*/
				return urlsObj.video.format
			case "video_to_image":/*视频提取图片*/
				return urlsObj.video.image
			case "audio":/*音频压缩*/
				return urlsObj.audio.ys
			case "audio_wav":/*音频压缩*/
				return urlsObj.audio.wav
			case "audio_flac":/*音频压缩*/
				return urlsObj.audio.flac
			case "audio_fenduan":/*音频分段*/
				return urlsObj.audio.fenduan
			case "audio_format":/*音频转格式*/
				return urlsObj.audio.format
			case "image":/*图片压缩*/
				return urlsObj.image.index
			case "image_png":/*图片压缩*/
				return urlsObj.image.png
			case "image_jpg":/*图片压缩*/
				return urlsObj.image.jpg
			case "image_webp":/*图片压缩*/
				return urlsObj.image.webp
			case "gif_split":/*gif压缩*/
				return urlsObj.image.gif_split
			case "image_gif":/*gif拆分*/
				return urlsObj.image.gif
			case "image_zjz":/*证件照压缩*/
				return urlsObj.image.zjz
			case "image_dpi":/*修改dpi*/
				return urlsObj.image.dpi
			case "image_size":/*指定大小压缩*/
				if(p){
					return urlsObj.image.size+p
				}else{
					return urlsObj.image.size
				}
			case "image_geshi_all":/*图片转格式*/
				return urlsObj.image.geshi_all
			case "image_ico":/*图片转ico*/
				return urlsObj.image.ico
			case "pdf":/*pdf压缩*/
				return urlsObj.pdf.index
			case "pdf_split":/*pdf拆分*/
				return urlsObj.pdf.split
			case "pdf_merge":/*pdf合并*/
				return urlsObj.pdf.merge
			case "pdf_decode":/*pdf解密*/
				return urlsObj.pdf.decode
			case "pdf_encrypt":/*pdf加密*/
				return urlsObj.pdf.encrypt
			case "user":/*个人中心首页*/
				return urlsObj.user.user
			case "order":/*个人中心-订单中心*/
				return urlsObj.user.order
			case "record":/*个人中心-处理记录*/
				return urlsObj.user.record
			case "user-help":/*帮助中心*/
				return urlsObj.user.help
			case "logout":/*安全退出*/
				return urlsObj.logout
			case "about":/*关于我们*/
				return urlsObj.help.about
			case "agreement":/*用户协议*/
				return urlsObj.help.agreement
			case "privacy":/*隐私政策*/
				return urlsObj.help.privacy
			case "copyright":/*版权声明*/
				return urlsObj.help.copyright
			case "help":/*帮助中心*/
				if(p){
					return urlsObj.help.list+p
				}else{
					return urlsObj.help.list
				}
			case "map":/*网站导航*/
				return urlsObj.map
		}
	},
	checkMessageCode(data,callback){
		return new Promise(function(resolve, reject) {
			switch(data.code){
				case 11000:
					resolve("success")
				break;
				case 11001:
					Modal.confirm({
						title: '提示',
						content: data.message,
						icon: createVNode(ExclamationCircleOutlined),
						okText: '立即升级',
						cancelText: '取消',
						onOk:function(){
							resolve("success")
						},
						onCancel(){reject("error")},
					});
					break
				default:
					Modal.confirm({
						title: '提示',
						content: data.message,
						icon: createVNode(ExclamationCircleOutlined),
						okText: '立即升级',
						cancelText: '取消',
						onOk:function(){
							resolve("success")
						},
						onCancel(){
							reject("error")
						},
					});
					break;
			}
		})
	},
	checkUser(that,data){
		if(!data)data={}
		return new Promise(function(resolve, reject) {
			if(that.handleAuthStatus){
				resolve(true)
			}else{
				that.$apiRequest.post('/user/checkUser',data).then(res => {
					that.handleAuthStatus=true;
					resolve(res)
				}).catch(err => {
					reject(err)
				})
			}
			
		})
	},
	downLoadMediaToLocalhost(download_md5,types){
		/*下载文件到本地*/
		var eleLink = document.createElement('a');
		eleLink.style.display = 'none';
		// eleLink.rel = "noreferrer noopener nofollow"
		// eleLink.target = "_blank"
		eleLink.href = process.env.VUE_APP_BASE_URL+"/media/download?types="+types+"&md5="+download_md5;
		eleLink.click();
		// 然后移除
		eleLink.remove();
	},
	getAuthorization(){
	  var times = (Date.parse(new Date()) / 1000).toString(16)
	  return md5(times+"__start__"+config.AccessKey+"_91yasuo_"+config.SecretKey+"__end__"+times)+"-"+times;
	},
	/*
	 *获取地址栏中参数
	 */
	getLocationBarKey: function (name) {
		try{
			return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [ , ''])[1].replace(/\+/g, '%20')) || null;
		}catch(e){
			return null;
		}
	},
	/*
	 *验证密码
	 password：密码
	 check_password：再次输入密码
	 status:
		1：只验证第一个密码
		2：验证两次密码是否一致
	 */
	checkPassword(password,check_password,status){
		return new Promise(function(resolve, reject) {
			if(!password){
				reject('请输入密码！');
			}else{
				var reg =/^[^\s]*$/;
				if(!reg.test(password)){
					reject('密码不能包含空格！');
				}else if(password.length<6 || password.length>20){
					reject('请输入6~20位密码！');
				}else{
					if(status==2 && password!=check_password){
						reject('两次输入的密码不一致！');
					}else{
						resolve('验证成功');
					}
				}
			}
		})
	},
	//正则验证手机号
	checkPhone(phone,status){
		var reg =/^1[3-9]\d{9}$/;
		if(status==1){
			if(!phone){
				return '请输入手机号';
			}else{
				if(!reg.test(phone)){
					return '请输入正确的手机号';
				}
			}
			return false;
		}else{
			return new Promise(function(resolve, reject) {
				if(!phone){
					reject('请输入手机号');
				}else{
					if(!reg.test(phone)){
						reject('请输入正确的手机号');
					}
				}
				resolve('验证成功');
			})
		}
	},
	//正则验证邮箱
	checkEmail(email,status){
		var regExp =/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
		if(status==1){
			if(!email){
				return '请输入邮箱';
			}else{
				if(!regExp.test(email)){
					return '请输入正确的邮箱';
				}
			}
			return false;
		}else{
			return new Promise(function(resolve, reject) {
				if(!email){
					reject('请输入邮箱');
				}else{
					if(!regExp.test(email)){
						reject('请输入正确的邮箱');
					}
				}
				resolve('验证成功');
			})
		}
	},
	//正则验证邮箱
	checkCode(code,status){
		var regExp =/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
		if(status==1){
			if(!code){
				return '请输入验证码';
			}else{
				if((code+"").length!=6){
					return '请输入正确的验证码';
				}
			}
			return false;
		}else{
			return new Promise(function(resolve, reject) {
				if(!code){
					reject('请输入验证码');
				}else{
					if((code+"").length!=6){
						reject('请输入正确的验证码');
					}
				}
				resolve('验证成功');
			})
		}
	},
	/*验证是否是数字*/
	checkNumber(value,maximum){
		/*限制input只能输入大于0的整数*/
		let result = 0; 
		if (typeof value === 'string') {
			result = !isNaN(Number(value)) ? Number(value.replace(/\./g, '')) : 0
		} else if (typeof value === 'number') {
			result = !isNaN(value) ? Number(String(value).replace(/\./g, '')) : 0
		}
		result = Math.abs(result)
		if(maximum && maximum>0){
			if(result>maximum)result = maximum
		}
		return result;
	},
	/*size-转b*/
	converSize(size){
		size = parseInt(size);
		var result = "";
		if( size <  1024 ){ //如果小于0.1KB转化成B
			result = size.toFixed(2) + "B";
		}else if(size <  1024 * 1024 ){//如果小于0.1MB转化成KB
			result = (size / 1024).toFixed(2) + "KB";
		}else if(size <  1024 * 1024 * 1024){ //如果小于0.1GB转化成MB
			result = (size / (1024 * 1024)).toFixed(2) + "MB";
		}else{ //其他转化成GB
			result = (size / (1024 * 1024 * 1024)).toFixed(2) + "GB";
		}
		var sizestr = result + "";
		var len = sizestr.indexOf("\.");
		var dec = sizestr.substr(len + 1, 2);
		if(dec == "00"){//当小数点后为00时 去掉小数部分
			return sizestr.substring(0,len) + sizestr.substr(len + 3,2);
		}
		return sizestr;
	},
	retainTwoDecimals(num,decimal){
		if(!decimal)decimal = 2;
		if(!num)return num;
		/*保留小数点后两位*/
		return parseFloat(num).toFixed(decimal)
	},
	/* 时间处理 int to 时分秒*/
	floatToTimeHandle(time,state,form) {
		time= parseFloat(time).toFixed(3);
		let hour=0,minute=0,second=0;
		hour = parseInt(time/3600);
		minute = parseInt((time%3600)/60);
		second = parseFloat(parseFloat((time%3600)%60).toFixed(3));
		if(form=="array"){
			return [hour,minute,second]
		}else{
			if(state==1 && time<3600){
				return timeHandle(minute)+":"+timeHandle(second);
			}
			return timeHandle(hour)+":"+timeHandle(minute)+":"+timeHandle(second);
		}
		
	},
	timeToArrayHandle(time) {
	    time = time.toString();
	    if(time){
	        var timearr = time.split(":");
	        timearr[0] = parseInt(timearr[0]);
	        timearr[1] = parseInt(timearr[1]);
	        timearr[2] = parseInt(timearr[2]);
	    }else{
	        timearr = ['','',''];
	    }
	    return timearr;
	},
	timeArrayToNumberHandle(data){
		try{
			let result = 0;
			if(data[0])result+=parseInt(data[0])*3600
			if(data[1])result+=parseInt(data[1])*60
			if(data[2])result+=parseFloat(parseFloat(data[2]).toFixed(3))
			return result;
		}catch(e){
		}
		return 0;
	},
	timeArrayToSubmitDemand(data){
		try{
			if(data[0] || data[1] || data[2]){
				return timeHandle(data[0])+":"+timeHandle(data[1])+":"+timeHandle(data[2]);
			}
		}catch(e){
		}
		return "";
	},
	// 时间处理
	timeToNumberHandle(time) {
	    time = time.toString();
	    if(time){
	        var timearr = time.split(":");
			try{
				return parseInt(timearr[0])*3600+parseInt(timearr[1])*60+parseFloat(parseFloat(timearr[2]).toFixed(3));
			}catch(e){
			}
	    }
	    return 0;
	},
	timeTransformZero(timeData,way,duration){
		duration = parseFloat(duration);
		try{
			if(timeData.join(":")=="::"){
				if(way==1){
					return ["00","00","00"];
				}else{
					return [timeHandle(parseInt(duration/3600)),timeHandle(parseInt((duration%3600)/60)),timeHandle(parseInt((duration%3600)%60))];
				}
			}else{
				timeData[0] = timeHandle(timeData[0])
				timeData[1] = timeHandle(timeData[1])
				timeData[2] = timeHandle(timeData[2])
				return timeData;
			}
		}catch(e){
			return timeData;
		}
	},
	supplementZeroStr(hour,minute,second){
		if(hour || minute || second){
			return timeHandle(hour)+":"+timeHandle(minute)+":"+timeHandle(second);
		}else{
			return "";
		}
	},
	/*获取文件后缀名*/
	getType(filename, dot) {
		if (!filename) return "";
		if (dot) {
			return filename.substring(filename.lastIndexOf(".") + 1).toLowerCase();
		} else {
			return filename.substring(filename.lastIndexOf("."), filename.length).toLowerCase();
		}
	},
	
	/**
	 * Blob转Base64
	 * @param base64 String base64格式字符串
	 * @param water Bool true:需要添加水印 false：无需添加水印
	 */
	translateBlobToBase64(blob,water) {
		return new Promise(function(resolve, reject) {
			var reader = new FileReader()
			reader.onload = function (e) {
				if(water){
					const canvas = document.createElement("canvas");
					const ctx = canvas.getContext("2d");
					const img = new Image();
					let resultBase64 = null;
					img.onload = function() {
						canvas.width = img.width;
						canvas.height = img.height;
			
						//canvas绘制图片，0 0  为左上角坐标原点
						ctx.drawImage(img, 0, 0);
						//写入水印
						drawWaterMark(ctx, img.width, img.height, {
							font: "microsoft yahei", //字体
							textArray: ['https://www.91yasuo.cn','91压缩'],//水印文本内容，允许数组最大长度3 即：3行水印
							density: 3  //密度 建议取值范围1-5   值越大，水印越多，可能会导致水印重叠等问题，慎重！！！
						});
						resultBase64 = canvas.toDataURL(blob.type||"image/png");
						if (!resultBase64) {
							reject();
						} else {
							resolve(resultBase64);
						}
					};
					img.src = e.target.result;
				}else{
					resolve(e.target.result)
				}
			}
			reader.onerror = function () {
				reject()
			}
			reader.readAsDataURL(blob)
		})
	},
	getImageFileInfo(base64,file,extra){
		var extraData = JSON.stringify(extra)
		return new Promise(function(resolve, reject) {
			if(base64){
				readImageinfo(base64)
			}else if(file){
				var reader = new FileReader()
				reader.onload = function (event) {
					readImageinfo(event.target.result)
				}
				reader.readAsDataURL(file)
			}else{
				reject({
					extra:JSON.parse(extraData)
				})
			}
			function readImageinfo(url){
				var img = document.createElement('img')
				img.src = url
				img.onload = function () {
					resolve({
						width:img.width,
						height:img.height,
						base64:url,
						extra:JSON.parse(extraData)
					})
				}
				img.onerror = function () {
					reject({
						extra:JSON.parse(extraData)
					})
				}
			}
		})
	},
	/*检测文件格式*/
	checkFileExt(ext, type) {
		let result = '';
		let arr_qf = ['xls', 'xlsx', 'docx', 'doc', 'ppt', 'pptx', 'pdf', 'gif', 'jpg', 'jpeg', 'png','webp'];
		switch (type) {
			case "img":
				/*验证图片*/
				arr_qf = ['gif', 'jpg', 'jpeg', 'png','webp'];
				break;
			case "file":
				/*验证文件*/
				arr_qf = ['xls', 'xlsx', 'docx', 'doc', 'ppt', 'pptx', 'pdf'];
				break;
			case "shengma":
				/*验证生码*/
				arr_qf = ['xls', 'xlsx', 'csv'];
				break;
			case "video":
				/*验证视频*/
				arr_qf = ['mp4', 'flv', 'avi', 'mov'];
				break;
			case "audio":
				/*验证音频*/
				arr_qf = ['mp3', 'm4a', 'wav', 'ogg', 'aiff', 'svcd', 'vcd'];
				break;
			case "media":
				/*验证媒体(音视频)*/
				arr_qf = ['mp4', 'flv', 'avi', 'mov', 'mp3', 'm4a', 'wav', 'ogg', 'aiff', 'svcd', 'vcd'];
				break;
		}
		if (!containsUYTHSKJ(arr_qf, ext)) {
			switch (type) {
				case "img":
					/*验证图片*/
					result = '不支持“' + ext + '”格式文件，请上传正确的图片'
					break;
				case "file":
					/*验证文件*/
					result = '不支持“' + ext + '”格式文件，请上传正确的文件'
					break;
				case "shengma":
					/*验证生码*/
					result = '不支持“' + ext + '”格式文件，请上传正确的文件'
					break;
				case "video":
					/*验证视频*/
					result = '不支持“' + ext + '”格式文件，请上传正确的视频'
					break;
				case "audio":
					/*验证音频*/
					result = '不支持“' + ext + '”格式文件，请上传正确的音频'
					break;
				case "media":
					/*验证媒体(音视频)*/
					result = '不支持“' + ext + '”格式文件，请上传正确的媒体文件'
					break;
				default:
					result = '不支持“' + ext + '”格式文件，请上传正确的图片或文件'
					break;
			}
		}
		return result;
	}
	
}
export default  baseFunc;

// 时间处理
function timeHandle(time) {
    time = time.toString();
    var timestr="";
    if(time&&time!="0"){
        var t = time.split('.')[0];
        if(t.length==1){
            timestr="0"+time
        }else{
            timestr=time
        }
    }else{
        timestr="00"
    }
    return timestr;
}

/*共用方法*/
function parseUrl(url) {
	let urlObj = {
		protocol: /^(.+)\:\/\//,
		host: /\/\/(.+?)[\?\#\s\/]/,
		path: /\w(\/.*?)[\?\#\s]/,
		query: /\?(.+?)[\#\/\s]/,
		hash: /\#(\w+)\s$/
	};
	url += ' ';

	function formatQuery(str) {
		return str.split('&').reduce((a, b) => {
			let arr = b.split('=');
			a[arr[0]] = arr[1];
			return a
		}, {})
	}
	for (let key in urlObj) {
		let pattern = urlObj[key];
		urlObj[key] = key === 'query' ? (pattern.exec(url) && formatQuery(pattern.exec(url)[1])) : (pattern.exec(url) &&
			pattern.exec(url)[1])
	}
	return urlObj
}

function containsUYTHSKJ(arr_qf, obj) {
	for (let i = 0; i < arr_qf.length; i++) {
		if (arr_qf[i] === obj) {
			return true;
		}
	}
	return false;
}
/*数字补0*/
function plusOne(time) {
	if (time < 10) {
		return '0' + time;
	} else {
		return time;
	}
}

//画布添加水印
function drawWaterMark(ctx, imgWidth, imgHeight, wmConfig){
    let fontSize;
    if (imgWidth >= 3456) {
        fontSize = 80;
    } else if (imgWidth >= 2700) {
        fontSize = 60;
    } else if (imgWidth >= 2000) {
        fontSize = 40;
    } else if (imgWidth >= 1436) {
        fontSize = 32;
    } else if (imgWidth >= 800) {
        fontSize = 18;
    } else if (imgWidth >= 500) {
        fontSize = 14;
    } else {
        fontSize = 12;
    }
    ctx.fillStyle = "white";
    ctx.font = `${fontSize}px ${wmConfig.font}`;
    ctx.lineWidth = 1;
    ctx.fillStyle = "rgba(255,255,255,0.3)";
    ctx.textAlign = "left";
    ctx.textBaseline = "middle";
    //文字坐标
    const maxPx = Math.max(imgWidth, imgHeight);
    const stepPx = Math.floor(maxPx / wmConfig.density);
    let arrayX = [0];//初始水印位置 canvas坐标 0 0 点
    while (arrayX[arrayX.length - 1] < maxPx/2) {
        arrayX.push(arrayX[arrayX.length - 1] + stepPx);
    }
    arrayX.push(...arrayX.slice(1, arrayX.length).map((el) => {
        return -el;
    }));

    for (let i = 0; i < arrayX.length; i++) {
        for (let j = 0; j < arrayX.length; j++) {
            ctx.save();
            ctx.translate(imgWidth / 2, imgHeight / 2); ///画布旋转原点 移到 图片中心
            ctx.rotate(-Math.PI / 5);
            if (wmConfig.textArray.length > 3) {
                wmConfig.textArray = wmConfig.textArray.slice(0, 3);
            }
            wmConfig.textArray.forEach((el, index) => {
                let offsetY = fontSize * index + 2;
                ctx.fillText(el, arrayX[i], arrayX[j] + offsetY);
            });
            ctx.restore();
        }
    }

};
