<template>
	<div class="login-warp">
		<div class="login-warp-left">
			<img class="login-warp-left-img" src="/images/bj-2.jpg"/>
			<div class="login-warp-left_box">
				<p class="login-warp-left_box_t">91压缩</p>
				<p class="login-warp-left_box_desc">让视频、图片、音频处理更简单</p>
				<ul class="login-warp-left_list">
					<li>极速压缩，无损画质</li>
					<li>多种压缩模式任你选择</li>
					<li>一键批量压缩图片</li>
					<li>任意文件格式转换</li>
					<li>定时清理数据，隐私无忧</li>
				</ul>
			</div>
		</div>
		<div class="login-warp-right">
			<div class="login-box" v-if="loginType!=5">
				<div class="login-item" :class="loginType==4?'login-register-item':''">
					<div class="login-wx" v-if="loginType==1">
						<div class="wx-title">微信扫码安全登录</div>
						<div class="wx-qrcode">
							<img :src="wechatForm.loginImg" />
							<div v-if="wechatForm.obtainLoad" class="wx-qrcode-load-warp"><a-spin class="wx-qrcode-load" /></div>
							<div v-if="wechatForm.overdue" class="wx-qrcode-load-warp"><RedoOutlined @click="wechatForm.getChatLoginQrImg(this)" style="color:#333333;font-size:40px;cursor: pointer;" /></div>
						</div>
						<!-- <div class="wx-error">扫码登录遇到问题？ <span class="wx-serve-error">联系客服</span></div> -->
						<div class="wx-tips">微信扫码关注登录，安全快捷又实惠</div>
					</div>
					<div class="login-account" v-if="loginType==2">
						<div class="account-title">账号密码登录</div>
						<a-form ref="formAccountRef" 
						:model="accountForm.model"
						:rules="accountForm.rules">
							<a-form-item label="" name="account">
								<a-input v-model:value="accountForm.model.account" class="login-input-btn" size="large" type="text" placeholder="请输入手机号/邮箱"></a-input>
							</a-form-item>
							<a-form-item label="" name="password">
								<a-input v-model:value="accountForm.model.password" class="login-input-btn" size="large" type="password" placeholder="请输入6~20位密码"></a-input>
							</a-form-item>
							<div class="login-toggle-item">
								<span @click="cutLoginWay(3)" class="login-toggle-account">手机验证码登录</span>
								<span @click="cutLoginWay(5)" class="forget-password">忘记密码？</span>
							</div>
							<a-form-item>
								<a-button :loading="accountForm.loginInfo.load" :block="true" type="primary" size="large" @click="accountForm.onAccountLoginSubmit(this)">登录</a-button>
							</a-form-item>
						</a-form>
					</div>
					<div class="login-account" v-if="loginType==3">
						<div class="account-title">手机验证码登录</div>
						<a-form ref="formPhoneRef" 
						:model="phoneForm.model"
						:rules="phoneForm.rules">
							<a-form-item label="" name="phone">
								<a-input v-model:value="phoneForm.model.phone" class="login-input-btn" size="large" type="text" placeholder="请输入手机号"></a-input>
							</a-form-item>
							<a-form-item label="" name="code">
								<a-input-number style="width:calc(100% - 154px);" v-model:value="phoneForm.model.code" class="login-input-btn login-number-input-btn" size="large" type="number" placeholder="请输入短信验证码" />
								<a-button :loading="phoneForm.codeInfo.load" :disabled="phoneForm.codeInfo.disabled" class="login-code-btn" size="large" @click="phoneForm.getPhoneCode(this)">{{phoneForm.codeInfo.title}}</a-button>
							</a-form-item>
							<div class="login-toggle-item">
								<span @click="cutLoginWay(2)" class="login-toggle-account">账号密码登录</span>
								<span @click="cutLoginWay(5)" class="forget-password">忘记密码？</span>
							</div>
							<a-form-item>
								<a-button :block="true" type="primary" size="large" @click="phoneForm.onPhoneLoginSubmit(this)">登录</a-button>
							</a-form-item>
						</a-form>
					</div>
					
					<div class="login-account login-register" v-if="loginType==4">
						<div class="account-title">注册账号</div>
						<a-form ref="formRegisterRef" 
						:model="registerForm.model"
						:rules="registerForm.rules">
							<a-form-item label="" name="phone">
								<a-input v-model:value="registerForm.model.phone" class="login-input-btn" size="large" type="text" placeholder="请输入手机号"></a-input>
							</a-form-item>
							<a-form-item label="" name="code">
								<a-input-number style="width:calc(100% - 154px);" v-model:value="registerForm.model.code" :min="0" :max="999999" class="login-input-btn login-number-input-btn" size="large" type="number" placeholder="请输入短信验证码" />
								<a-button :loading="registerForm.codeInfo.load" :disabled="registerForm.codeInfo.disabled" class="login-code-btn" size="large" @click="registerForm.getPhoneCode(this)">{{registerForm.codeInfo.title}}</a-button>
							</a-form-item>
							<a-form-item label="" name="password">
								<a-input v-model:value="registerForm.model.password" class="login-input-btn" size="large" type="password" placeholder="请输入6~20位密码"></a-input>
							</a-form-item>
							<a-form-item label="" name="check_password">
								<a-input v-model:value="registerForm.model.check_password" class="login-input-btn" size="large" type="password" placeholder="确认密码"></a-input>
							</a-form-item>
							<a-form-item>
								<a-button :block="true" type="primary" size="large" @click="registerForm.onRegisterLoginSubmit(this)">立即注册</a-button>
							</a-form-item>
						</a-form>
						<div class="think-login-account">
							<span>已有账号？</span>
							<span @click="cutLoginWay(2)" class="cut-login-account">去登录</span>
						</div>
					</div>
				</div>
				<div class="login-other-box-text" v-if="loginType!=4">
					<span class="login-other-line"></span>
					<span class="login-other-desc">其他登录方式</span>
					<span class="login-other-line"></span>
				</div>
				<div class="login-other-box-ways" v-if="loginType!=4">
					<div v-if="loginType==1" @click="cutLoginWay(3)" class="login-other-box-way-item way-phone"><i class="iconfont ys-bangdingshouji"></i></div>
					<div v-if="loginType!=1" @click="cutLoginWay(1)" class="login-other-box-way-item way-wechat"><i class="iconfont ys-weixin"></i></div>
					<!-- <div class="login-other-box-way-item way-qq"><i class="iconfont ys-QQ"></i></div> -->
				</div>
				<div class="login-footer-remark">
					<div class="login-remark-left">
						<span v-if="loginType!=4">登录即同意</span>
						<span v-if="loginType==4">注册即同意</span>
						<a :href="$baseFunc.findUrls('agreement')" target="_blank">用户协议</a>和<a :href="$baseFunc.findUrls('privacy')" target="_blank">隐私政策</a>
					</div>
					<div v-if="loginType!=4" @click="cutLoginWay(4)" class="login-remark-right"><span>手机号注册</span></div>
				</div>
			</div>
			<div class="find-login-pwd-warp" v-if="loginType==5">
				<div class="find-login-box">
					<div class="find-header-title">找回密码</div>
					<div class="find-header-tabs">
						<div @click="findAccountForm.cutFindAccountWay(1,this)" class="find-tab-item" :class="findAccountForm.way==1?'active':''">手机号找回</div>
						<div @click="findAccountForm.cutFindAccountWay(2,this)" class="find-tab-item" :class="findAccountForm.way==2?'active':''">邮箱找回</div>
					</div>
					<a-form ref="formFindAccountRef"
					:model="findAccountForm.model"
					:rules="findAccountForm.rules">
						<a-form-item label="" name="username">
							<a-input v-model:value="findAccountForm.model.username" class="login-input-btn" size="large" type="text" :placeholder="findAccountForm.way==1?'请输入手机号':'请输入邮箱'"></a-input>
						</a-form-item>
						<a-form-item label="" name="code">
							<a-input-number style="width:calc(100% - 154px);" v-model:value="findAccountForm.model.code" :min="0" :max="999999" class="login-input-btn login-number-input-btn" size="large" type="number" :placeholder="findAccountForm.way==1?'请输入短信验证码':'请输入邮箱验证码'" />
							<a-button :loading="findAccountForm.codeInfo.load" :disabled="findAccountForm.codeInfo.disabled" class="login-code-btn" size="large" @click="findAccountForm.getUsernameCode(this)">{{findAccountForm.codeInfo.title}}</a-button>
						</a-form-item>
						<a-form-item label="" name="password">
							<a-input v-model:value="findAccountForm.model.password" class="login-input-btn" size="large" type="password" placeholder="请输入6~20位密码"></a-input>
						</a-form-item>
						<a-form-item label="" name="check_password">
							<a-input v-model:value="findAccountForm.model.check_password" class="login-input-btn" size="large" type="password" placeholder="确认密码"></a-input>
						</a-form-item>
						<a-form-item>
							<a-button :block="true" type="primary" size="large" @click="findAccountForm.onFindAccountSubmit(this)">找回密码</a-button>
						</a-form-item>
					</a-form>
				</div>
				<div class="think-login-account">
					<span>想起密码？</span>
					<span @click="cutLoginWay(3)" class="cut-login-account">去登录</span>
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
	import {message,Modal} from 'ant-design-vue';
	import {createVNode } from 'vue';
	import baseFunc from '../libs/base_func.js';
	import { ExclamationCircleOutlined,InfoCircleOutlined,RedoOutlined } from '@ant-design/icons-vue';
	
	export default{
		name: 'login',
		components:{
			InfoCircleOutlined,RedoOutlined
		},
		created() {
			/*获取微信登录二维码*/
			this.wechatForm.getChatLoginQrImg(this)
		},
		data(){
			return{
				loginType:1,//1微信扫码，2账号密码，3手机验证码，4手机号注册，5密码找回
				wechatForm:{/*微信登录*/
					loginImg:"",//登录二维码/images/showqrcode.png
					obtainLoad:false,/*二维码获取中*/
					overdue:false,/*二维码过期*/
					overdue_time:5*60,/*轮询过期时间-超过时间停止轮询-防止长时间停留-减少服务器压力*/
					code_timer:null,
					code_id:0,
					get_timeOut:0,/*轮询开始时间*/
					getChatLoginQrImg(_self){
						this.overdue = false;
						this.obtainLoad = true;
						if(parseInt(this.code_id)>0){
							_self.clearTimer(1);
							this.get_timeOut = parseInt(Date.now()/1000);
							this.code_timer  = setInterval(function(){
								_self.wechatForm.getScanLoginStatus(_self)
							},2000)
						}else{
							_self.$apiRequest.post('/oauth/getChatLoginQrImg', {
								mold:1,
							}).then(res => {
								_self.clearTimer(1);
								this.loginImg = res.ticket;
								this.code_id = res.code_id;
								this.get_timeOut = parseInt(Date.now()/1000);
								this.code_timer  = setInterval(function(){
									_self.wechatForm.getScanLoginStatus(_self)
								},2000)
							}).catch(err => {
								this.obtainLoad = false;
								this.overdue = true;
							})
						}
					},
					getScanLoginStatus(_self){
						if(!this.code_id)return false;
						if(parseInt(Date.now()/1000) - this.get_timeOut>this.overdue_time){
							_self.clearTimer(1);
							this.overdue = true;
							return false;
						}
						_self.$apiRequest.post('/oauth/getScanLoginStatus', {
							code_id:this.code_id,
						}).then(res => {
							_self.clearTimer(1);
							_self.loginSuccessCallback(res.user);
						}).catch(err => {
							switch(err.code){
								case 10108:
									this.overdue = true;
									this.obtainLoad = false;
									break;
							}
						})
					},
				},
				accountForm:{/*账号登录*/
					model:{
						account:"",
						password:"",
					},
					loginInfo:{
						load:false,
					},
					rules:{
						account: [
						    {required: true, message: '请输入手机号/邮箱'},
						],
						password: [
							{validator:(rule, value) => {
								if(!value){
									return Promise.reject('请输入密码');
								}else{
									var reg =/^[^\s]*$/;
									if(!reg.test(value)){
										return Promise.reject('密码不能包含空格');
									}else if(value.length<6 || value.length>20){
										return Promise.reject('请输入6~20位密码');
									}
								}
								return Promise.resolve();
							}},
						],
					},
					onAccountLoginSubmit(_self){
						_self.$refs.formAccountRef.validate()
						.then(() => {
							_self.$apiRequest.post('/oauth/login', {
								username:this.model.account,
								free_login: 0,
								login_type:2,
								password:this.model.password,
							}).then(res => {
								_self.$refs.formAccountRef.resetFields();
								_self.loginSuccessCallback(res.user);
							}).catch(err => {
								if(typeof err.message!='undefined'){
									message.warning(err.message);
								}
							})
						}).catch((error) => {
						});
					},
				},
				phoneForm:{/*手机验证码登录*/
					codeInfo:{
						title:"获取短信验证码",
						disabled:false,
						load:false,
						timeer:60,
						code_timer:null,
					},
					model:{
						phone:"",
						code:"",
					},
					rules:{
						phone: [
						    {
								validator:(rule, value) => {
									let checkResult = baseFunc.checkPhone(value,1);
									if(checkResult){
										return Promise.reject(checkResult);
									}else{
										return Promise.resolve();
									}
								}
							},
						],
						code: [
						    {
								validator:(rule, value) => {
									if(!value){
										return Promise.reject('请输入短信验证码');
									}else{
										if((value+"").length!=6){
											return Promise.reject('请输入正确的短信验证码');
										}
									}
									return Promise.resolve();
								}
							},
						],
					},
					getPhoneCode(_self){//获取短信验证码
						if(this.codeInfo.disabled)return false;
						this.codeInfo.load = true;
						baseFunc.checkPhone(this.model.phone).
						then(res => {
							_self.sendSms(this.model.phone).
							then(res => {
								this.codeInfo.load = false;
								_self.clearTimer(3);/*清除定时器*/
								this.codeInfo.disabled = true;
								this.codeInfo.title="60s 后重新获取"
								this.codeInfo.code_timer  = setInterval(function(){
									_self.phoneForm.codeInfo.timeer--;
									_self.phoneForm.codeInfo.title=_self.phoneForm.codeInfo.timeer+"s 后重新获取"
									if(_self.phoneForm.codeInfo.timeer<=0){
										_self.clearTimer(3);/*清除定时器*/
									}
								},1000)
							}).catch(err => {
								this.codeInfo.load = false;
								this.codeInfo.title="重新获取"
							})
						}).catch(err => {
							this.codeInfo.load = false;
							message.warning(err);	
						})
					},
					onPhoneLoginSubmit(_self){//提交
						_self.$refs.formPhoneRef.validate()
						.then(() => {
							_self.$apiRequest.post('/oauth/login', {
								username:this.model.phone,
								free_login: 0,
								login_type:1,
								message_code:this.model.code,
							}).then(res => {
								_self.clearTimer(3);/*清除定时器*/
								_self.$refs.formPhoneRef.resetFields();
								_self.loginSuccessCallback(res.user);
							}).catch(err => {
								_self.clearTimer(3);/*清除定时器*/
								if(typeof err.message!='undefined')message.warning(err.message);
							})
						}).catch((error) => {
						});
					},
				},
				registerForm:{/*注册账号*/
					codeInfo:{
						title:"获取短信验证码",
						disabled:false,
						load:false,
						timeer:60,
						code_timer:null,
					},
					model:{
						phone:"",
						code:"",
						password:"",
						check_password:"",
					},
					rules:{
						phone: [
						    {validator:(rule, value) => {
								let checkResult = baseFunc.checkPhone(value,1);
								if(checkResult){
									return Promise.reject(checkResult);
								}else{
									return Promise.resolve();
								}
						    }},
						],
						code: [
						    {
								validator:(rule, value) => {
									if(!value){
										return Promise.reject('请输入短信验证码');
									}else{
										if((value+"").length!=6){
											return Promise.reject('请输入正确的短信验证码');
										}
									}
									return Promise.resolve();
								}
							},
						],
						password: [
						    {validator:(rule, value) => {
						    	if(!value){
						    		return Promise.reject('请输入密码');
						    	}else{
						    		var reg =/^[^\s]*$/;
						    		if(!reg.test(value)){
						    			return Promise.reject('密码不能包含空格');
						    		}else if(value.length<6 || value.length>20){
						    			return Promise.reject('请输入6~20位密码');
						    		}
						    	}
						    	return Promise.resolve();
						    }},
						],
						check_password: [
							{validator:(rule, value) => {
								if(!value){
									return Promise.reject('请确认密码');
								}else{
									if(value!=this.registerForm.model.password){
										return Promise.reject('密码不一致');
									}
								}
								return Promise.resolve();
							}},
						],
					},
					getPhoneCode(_self){//获取短信验证码
						if(this.codeInfo.disabled)return false;
						this.codeInfo.load = true;
						baseFunc.checkPhone(this.model.phone).
						then(res => {
							_self.sendSms(this.model.phone).then(res => {
								_self.clearTimer(4);/*清除定时器*/
								this.codeInfo.load = false;
								this.codeInfo.disabled=true
								this.codeInfo.title="60s 后重新获取"
								this.codeInfo.code_timer  = setInterval(function(){
									_self.registerForm.codeInfo.timeer--;
									_self.registerForm.codeInfo.title=_self.registerForm.codeInfo.timeer+"s 后重新获取"
									if(_self.registerForm.codeInfo.timeer<=0){
										_self.clearTimer(4);/*清除定时器*/
									}
								},1000)
							}).catch(err => {
								this.codeInfo.load = false;
								this.codeInfo.title="重新获取"
							})
						}).catch(err => {
							this.codeInfo.load = false;
							message.warning(err);	
						})
					},
					onRegisterLoginSubmit(_self){//提交
						_self.$refs.formRegisterRef.validate()
						.then(() => {
							_self.$apiRequest.post('/oauth/register', {
								username:this.model.phone,
								password: this.model.password,
								message_code:this.model.code,
							}).then(res => {
								_self.clearTimer(4);/*清除定时器*/
								message.success(res.message);
								_self.$refs.formRegisterRef.resetFields();
								_self.cutLoginWay(2);
							}).catch(err => {
								_self.clearTimer(4);/*清除定时器*/
								if(typeof err.code!='undefined'){
									if(err.code==10104){
										Modal.confirm({
											title: '注册提示',
											content: err.message,
											icon: createVNode(ExclamationCircleOutlined),
											okText: '立即登录',
											cancelText: '更换手机',
											onOk:function(){
												_self.cutLoginWay(2);
											},
											onCancel(){},
										});
									}else{
										message.warning(err.message);
									}
								}
							})
						}).catch((error) => {
						});
					},
				},
				findAccountForm:{/*找回密码*/
					codeInfo:{
						title:"获取验证码",
						disabled:false,
						load:false,
						timeer:60,
						code_timer:null,
					},
					way:1,/*1:手机找回 2：邮箱找回*/
					model:{
						username:"",
						code:"",
						password:"",
						check_password:"",
					},
					rules:{
						username: [
						    {validator:(rule, value) => {
								let checkResult=null;
								if(this.findAccountForm.way==1){
									/*验证手机号*/
									checkResult = baseFunc.checkPhone(value,1);
									if(checkResult){
										return Promise.reject(checkResult);
									}else{
										return Promise.resolve();
									}
								}else{
									/*验证邮箱*/
									checkResult = baseFunc.checkEmail(value,1);
									if(checkResult){
										return Promise.reject(checkResult);
									}else{
										return Promise.resolve();
									}
								}
						    	return Promise.resolve();
						    }},
						],
						code: [
						    {
								validator:(rule, value) => {
									let tip_name = "邮箱"
									if(this.findAccountForm.way==1)tip_name="手机";
									if(!value){
										return Promise.reject('请输入'+tip_name+'验证码');
									}else{
										if((value+"").length!=6){
											return Promise.reject('请输入正确的'+tip_name+'验证码');
										}
									}
									return Promise.resolve();
								}
							},
						],
						password: [
						    {validator:(rule, value) => {
						    	if(!value){
						    		return Promise.reject('请输入密码');
						    	}else{
						    		var reg =/^[^\s]*$/;
						    		if(!reg.test(value)){
						    			return Promise.reject('密码不能包含空格');
						    		}else if(value.length<6 || value.length>20){
						    			return Promise.reject('请输入6~20位密码');
						    		}
						    	}
						    	return Promise.resolve();
						    }},
						],
						check_password: [
							{validator:(rule, value) => {
								if(!value){
									return Promise.reject('请确认密码');
								}else{
									if(value!=this.findAccountForm.model.password){
										return Promise.reject('密码不一致');
									}
								}
								return Promise.resolve();
							}},
						],
					},
					getUsernameCode(_self){//获取验证码
						if(this.codeInfo.disabled)return false;
						this.codeInfo.load = true;
						if(this.way==1){
							baseFunc.checkPhone(this.model.username).
							then(res => {
								_self.sendSms(this.model.username,4).then(res => {
									this.resetTimer(_self);
								}).catch(err => {
									this.codeInfo.load = false;
									this.codeInfo.disabled=false
									this.codeInfo.title="重新获取"
								})
							}).catch(err => {
								this.codeInfo.load = false;
								message.warning(err);
							})
						}else{
							baseFunc.checkEmail(this.model.username).
							then(res => {
								_self.sendEmail(this.model.username,4).then(res => {
									this.resetTimer(_self);
								}).catch(err => {
									this.codeInfo.load = false;
									this.codeInfo.disabled=false
									this.codeInfo.title="重新获取"
								})
							}).catch(err => {
								this.codeInfo.load = false;
								message.warning(err);
							})
						}
					},
					resetTimer(_self){/*重置定时器*/
						_self.clearTimer(5);/*清除定时器*/
						this.codeInfo.load = false;
						this.codeInfo.disabled=true
						this.codeInfo.title="60s 后重新获取"
						this.codeInfo.code_timer  = setInterval(function(){
							_self.findAccountForm.codeInfo.timeer--;
							_self.findAccountForm.codeInfo.title=_self.findAccountForm.codeInfo.timeer+"s 后重新获取"
							if(_self.findAccountForm.codeInfo.timeer<=0){
								_self.clearTimer(5);/*清除定时器*/
							}
						},1000)
					},
					cutFindAccountWay(e,_self){/*切换找回方式*/
						_self.$refs.formFindAccountRef.clearValidate();
						this.model.username=""
						this.model.code=""
						this.way = e
					},
					onFindAccountSubmit(_self){
						/*提交-找回密码*/
						_self.$refs.formFindAccountRef.validate()
						.then(() => {
							_self.$apiRequest.post('/oauth/forgot', {
								username:this.model.username,
								password: this.model.password,
								message_code:this.model.code,
							}).then(res => {
								message.success(res.message);
								_self.clearTimer(5);/*清除定时器*/
								_self.$refs.formFindAccountRef.resetFields();
								_self.cutLoginWay(2);
							}).catch(err => {
								_self.clearTimer(5);/*清除定时器*/
								if(typeof err.code!='undefined'){
									if(err.code==10105){
										Modal.confirm({
											title: '密码找回提示',
											content: err.message,
											icon: createVNode(ExclamationCircleOutlined),
											okText: '立即注册',
											cancelText: '取消',
											onOk:function(){
												_self.cutLoginWay(4);
											},
											onCancel(){},
										});
									}else{
										message.warning(err.message);
									}
								}
							})
						}).catch((error) => {
							
						});
					},
				}
			}
		},
		methods: {
			cutLoginWay(e){
				const _this = this
				this.loginType = e;
				if(e==1){
					this.wechatForm.getChatLoginQrImg(this);
				}else{
					this.clearTimer(1)
				}
			},
			/*清除定时器*/
			clearTimer(status){
				const _this = this
				switch(status){
					case 1:/*微信登录*/
						clearWechatLoginTimer();
						break;
					case 3:/*手机验证码登录*/
						clearPhoneFormTimer();
						break;
					case 4:/*注册账号*/
						clearRegisterFormTimer();
						break;
					case 5:/*找回密码*/
						clearfindAccountFormTimer();
						break;
					default:
						clearWechatLoginTimer();
						clearPhoneFormTimer();
						clearRegisterFormTimer();
						clearfindAccountFormTimer();
						break;
				}
				function clearWechatLoginTimer(){
					_this.wechatForm.obtainLoad=false;
					_this.wechatForm.overdue=false;
					if(_this.wechatForm.code_timer){
						clearInterval(_this.wechatForm.code_timer);
						window.clearInterval(_this.wechatForm.code_timer);
						_this.wechatForm.code_timer = null;
					}
					
				}
				function clearPhoneFormTimer(){
					_this.phoneForm.codeInfo.disabled=false;
					_this.phoneForm.codeInfo.load=false;
					_this.phoneForm.codeInfo.timeer = 60;
					_this.phoneForm.codeInfo.title = "获取短信验证码";
					if(_this.phoneForm.codeInfo.code_timer){
						clearInterval(_this.phoneForm.codeInfo.code_timer);
						window.clearInterval(_this.phoneForm.codeInfo.code_timer);
						_this.phoneForm.codeInfo.code_timer = null;
					}
				}
				function clearRegisterFormTimer(){
					_this.registerForm.codeInfo.disabled=false;
					_this.registerForm.codeInfo.load=false;
					_this.registerForm.codeInfo.timeer = 60;
					_this.registerForm.codeInfo.title = "获取短信验证码";
					if(_this.registerForm.codeInfo.code_timer){
						clearInterval(_this.registerForm.codeInfo.code_timer);
						window.clearInterval(_this.registerForm.codeInfo.code_timer);
						_this.registerForm.codeInfo.code_timer = null;
					}
				}
				function clearfindAccountFormTimer(){
					_this.findAccountForm.codeInfo.disabled=false;
					_this.findAccountForm.codeInfo.load=false;
					_this.findAccountForm.codeInfo.timeer = 60;
					_this.findAccountForm.codeInfo.title = "获取验证码";
					if(_this.findAccountForm.codeInfo.code_timer){
						clearInterval(_this.findAccountForm.codeInfo.code_timer);
						window.clearInterval(_this.findAccountForm.codeInfo.code_timer);
						_this.findAccountForm.codeInfo.code_timer = null;
					}
				}
			},
			/*发送短信验证码*/
			sendSms(phone,way){
				const _this = this
				return new Promise(function(resolve, reject) {
					_this.$apiRequest.post('/oauth/sendSms', {
						username:phone,
						way:way,
					}).then(res => {
						message.success(res.message);
						resolve(res);
					}).catch(err => {
						if(typeof err.message!='undefined')message.warning(err.message);
						reject(err);
					})
				})
			},
			/*获取邮箱验证码*/
			sendEmail(email,way){
				const _this = this
				return new Promise(function(resolve, reject) {
					_this.$apiRequest.post('/oauth/sendEmail', {
						email:email,
						way:way,
					}).then(res => {
						message.success(res.message);
						resolve(res);
					}).catch(err => {
						if(typeof err.message!='undefined')message.warning(err.message);
						reject(err);
					})
				})
			},
			loginSuccessCallback(userInfo){
				this.clearTimer();
				message.success("登录成功");
				this.$emit("loginSuccess",{user:userInfo})
			}
		}
	}
</script>
<style>
	.login-number-input-btn .ant-input-number-handler-wrap{display: none;}
	.login-warp .login-input-btn{border: 1px solid #ebeef5;font-size: 14px;height: 40px;}
	.wx-qrcode-load .ant-spin-dot-item{background-color: #ffb200;}
</style>
<style scoped lang="less">
	.login-code-btn{font-size: 14px;width:144px;display: inline-block;margin-left: 10px;color: rgba(0,0,0,0.65);}
	.login-warp *{
		box-sizing: border-box;
		-webkit-tap-highlight-color: rgba(0,0,0,0);
	}
	.login-warp{
		width: 100%;
		position: relative;
		height: 500px;
		display: flex;
	}
	.login-warp .login-warp-left{
		width: 280px;
		min-width: 280px;
		height: 100%;
		position: relative;
	}
	.login-warp-left-img{
		border-top-left-radius: 2px;
		border-bottom-left-radius: 2px;
		width: 280px;
	}
	.login-warp-left_box{
		position: absolute;
		padding: 58px 22px 0;
		box-sizing: border-box;
		color: #ffffff;
		font-size: 14px;
		top: 0;
		left: 0;
	}
	.login-warp-left_box_t{
		font-size: 28px;
		color: #ffffff;
		font-weight: bold;
		text-align: center;
		margin: 0 0 8px;
		text-shadow: 0 0 2px #4d4d4d;
	}
	.login-warp-left_box_desc{
		font-size: 26px;
		color: #ffffff;
		text-align: center;
		text-shadow: 0 0 2px #4d4d4d;
	}
	.login-warp-left_list{
		margin-left: 22px;
		text-shadow: 0 0 4px #afafaf;
		font-size: 16px;
		line-height: 44px;
		text-shadow: 0 0 2px #4d4d4d;
	}
	.login-warp .login-warp-right{
		flex: 1;
		position: relative;
		margin: 0;
		padding: 0;
	}
	.login-warp .login-box{
		position: relative;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-direction: column;
		background: #fff;
		border-radius: 6px;
		box-shadow: 0 0 2px 0 rgb(0 0 0 / 12%);
		box-sizing: border-box;
		overflow: hidden;
	}
	
	.login-box .login-item{
		width: 100%;
		height: calc(100% - 45px - 66px - 40px);
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		padding: 0 56px;
		box-sizing: border-box;
	}
	.login-box .login-item.login-register-item{
		height: calc(100% - 40px);
	}
	.login-wx{
		padding-top: 54px;
		margin: 0 auto;
		flex: 1;
	}
	.login-account{
		width: 100%;
	}
	.login-wx .wx-title,.login-account .account-title{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		font-size: 20px;
		font-weight: 700;
		width: 100%;
		color: rgba(0, 0, 0, .8);
	}
	.login-wx .wx-title{
		justify-content: center;
	}
	.login-account .account-title{
		margin: 49px 0 24px;
	}
	.login-register-item .login-account .account-title{
		margin: 24px 0;
	}
	.login-toggle-item{
		width: 100%;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 24px;
		font-size: 14px;
	}
	.login-toggle-item .login-toggle-account{
		color: var(--main-color);
		cursor: pointer;
	}
	.login-toggle-item .forget-password{
		color: rgba(0,0,0,0.8);
		cursor: pointer;
	}
	.login-wx .wx-qrcode{
		display: flex;
		position: relative;
		justify-content: center;
		align-items: center;
		flex-direction: row;
		width: 172px;
		height: 172px;
		border: 1px solid #eee;
		border-radius: 6px;
		margin: 20px auto;
		box-sizing: border-box;
	}
	.wx-qrcode .wx-qrcode-load-warp{
		position: absolute;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: rgba(243,243,243,0.85);
	}
	
	.login-wx .wx-qrcode img{
		width: auto;
		height: 100%;
	}
	.login-wx .wx-error,.login-wx .wx-tips{
		color: #999999;
		text-align: center;
		font-size: 12px;
		margin-bottom: 8px;
	}
	.wx-error .wx-serve-error{
		cursor: pointer;
		color: var(--main-color);
	}
	.login-wx .wx-tips{margin-bottom: 0;}
	
	
	.login-footer-remark{
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-direction: row;
		position: absolute;
		bottom: 0;
		width: 100%;
		height: 40px;
		padding: 0 24px;
		font-size: 13px;
		color: #a7a7a7;
		background: #f8f8fb;
		box-sizing: border-box;
	}
	.think-login-account{
		width: 100%;
		text-align: center;
		margin-top: 20px;
		height: 24px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		color: #b4b8bf;
	}
	.think-login-account .cut-login-account{
		color: var(--main-color);
		margin-left: 4px;
		cursor: pointer;
	}
	.login-footer-remark .login-remark-left a{
		color: #333333;
		text-decoration: underline;
		margin: 0 4px;
		display: inline-block;
		cursor: pointer;
	}
	.login-footer-remark .login-remark-right{
		color: var(--main-color);
		cursor: pointer;
		transition: color 0.2s;
	}
	.login-footer-remark .login-remark-right:hover,.think-login-account .cut-login-account:hover{
		color: var(--main-color-hover);
	}
	.login-other-box-text{
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-direction: row;
		font-size: 12px;
		height: 20px;
		margin: 15px 0 10px;
		box-sizing: border-box;
	}
	
	.login-other-box-ways{
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: row;
		margin-bottom: 10px;
		height: 56px;
		padding-inline-start: 0;
	}
	.login-other-box-ways .login-other-box-way-item{
		position: relative;
		cursor: pointer;
		margin: 0 15px;
	}
	.login-other-box-way-item i{
		font-size: 36px;
		transition: color 200ms;
	}
	.login-other-box-ways .way-wechat i{
		color: #00b33b;
	}
	.login-other-box-ways .way-wechat:hover i{
		color: #00db49;
	}
	.login-other-box-ways .way-qq i{
		color: #0088cc;
	}
	.login-other-box-ways .way-qq:hover i{
		color: #0aadff;
	}
	.login-other-box-ways .way-phone i{
		color: #96c71a;
	}
	.login-other-box-ways .way-phone:hover i{
		color:#b1e42f;
	}
	.login-other-box-text .login-other-line{
		width: 30px;
		height: 1px;
		background-color: #eee;
	}
	.login-other-box-text .login-other-desc{
		font-size: 12px;
		width: 102px;
		text-align: center;
		color: rgba(0,0,0,.4);
	}
	
	.find-login-pwd-warp{
		width: 100%;
		height: 100%;
		position: relative;
		padding: 24px 56px 0;
	}
	.find-login-pwd-warp .find-header-title{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		font-size: 20px;
		font-weight: 700;
		width: 100%;
		color: rgba(0, 0, 0, .8);
	}
	.find-header-tabs{
		display: flex;
		justify-content: space-around;
		align-items: flex-start;
		width: 100%;
		height: 32px;
		border-bottom: 1px solid #ebeef5;
		margin: 24px 0;
		font-size: 14px;
	}
	.find-header-tabs .find-tab-item{
		cursor: pointer;
		padding-bottom: 8px;
		position: relative;
	}
	.find-header-tabs .find-tab-item.active{
		color: #1b2337;
		font-weight: 700;
	}
	.find-header-tabs .find-tab-item:after{
		width: 0;
		content: '';
		position: absolute;
		bottom: -2px;
		left: 0;
		height: 3px;
		background: var(--main-color);
		border-radius: 2px;
		box-shadow: 0 4px 6px 0 rgba(254, 170, 1, 0.1);
		transition: all 0.1s;
	}
	.find-header-tabs .find-tab-item.active:after{
		width: 100%;
		transition: all 0.3s;
	}
	@media(max-width:760px){
		.login-warp .login-warp-left,.login-other-box-text,.login-other-box-ways{
			display: none;
		}
		.login-box .login-item{
			padding: 0 24px;
		}
		.find-login-pwd-warp{
			padding: 24px 24px 0;
		}
	}
</style>