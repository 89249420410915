<template>
	<div class="parameter-control-warp">
		<div class="parameter-control-box">
			<label class="parameter-control-label">压缩方式</label>
			<a-radio-group name="radioGroup" v-model:value="parameter.compress_type" class="parameter-control-common-hu1s" size="large">
			    <a-radio :value="1">标准</a-radio>
			    <a-radio :value="2">指定大小</a-radio>
			    <a-radio :value="3">指定码率</a-radio>
			</a-radio-group>
			<a-select v-if="parameter.compress_type==1" placeholder="设置音频压缩等级" class="parameter-control-common-hu1s" size="large" v-model:value="parameter.ratio">
				<template #suffixIcon>
					<svg t="1655264345662" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7991" width="10" height="10">
						<path d="M946.4 353.50000001l-419.9 419.9c-8.2 8.2-21.5 8.2-29.7 0l-421.1-421.1c-16.4-16.4-16.4-43 0-59.3 16.4-16.4 43-16.4 59.3 0L504.2 662.20000001c4.1 4.1 10.7 4.1 14.8 0l368-368c16.4-16.4 43-16.4 59.3 0 16.5 16.3 16.5 42.9 0.1 59.3z" fill="#8a8a8a" p-id="7992"></path>
					</svg>
				</template>
				<a-select-option v-for="index of 49" :value="100-index*2">{{100-index*2}}{{100-index*2==70?"（推荐等级）":""}}</a-select-option>
			</a-select>
			<a-input v-if="parameter.compress_type==2" addonAfter="M" addon-before="期望大小" v-model:value="parameter.expect" @change="defaultParameter.expectChange" class="parameter-control-common-hu1s"  size="large"></a-input>
			<a-input v-if="parameter.compress_type==3" addonAfter="K" addon-before="指定码率" v-model:value="parameter.codeRate" @change="defaultParameter.codeRateChange" class="parameter-control-common-hu1s"  size="large"></a-input>
			
			<a-select placeholder="采样率" class="parameter-control-common-hu1s" size="large" v-model:value="parameter.ar">
				<template #suffixIcon>
					<svg t="1655264345662" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7991" width="10" height="10"><path d="M946.4 353.50000001l-419.9 419.9c-8.2 8.2-21.5 8.2-29.7 0l-421.1-421.1c-16.4-16.4-16.4-43 0-59.3 16.4-16.4 43-16.4 59.3 0L504.2 662.20000001c4.1 4.1 10.7 4.1 14.8 0l368-368c16.4-16.4 43-16.4 59.3 0 16.5 16.3 16.5 42.9 0.1 59.3z" fill="#8a8a8a" p-id="7992"></path></svg>
				</template>
				<a-select-option :value="0">源采样率</a-select-option>
				<a-select-option :value="8000">8000</a-select-option>
				<a-select-option :value="11025">11025</a-select-option>
				<a-select-option :value="12000">12000</a-select-option>
				<a-select-option :value="16000">16000</a-select-option>
				<a-select-option :value="22050">22050</a-select-option>
				<a-select-option :value="24000">24000</a-select-option>
				<a-select-option :value="32000">32000</a-select-option>
				<a-select-option :value="44056">44056</a-select-option>
				<a-select-option :value="44100">44100</a-select-option>
				<a-select-option :value="48000">48000</a-select-option>
				<a-select-option :value="50000">50000</a-select-option>
				<a-select-option :value="64000">64000</a-select-option>
				<a-select-option :value="88200">88200</a-select-option>
				<a-select-option :value="96000">96000</a-select-option>
			</a-select>
			<a-select placeholder="输出格式" class="parameter-control-common-hu1s" size="large" v-model:value="parameter.format">
				<template #suffixIcon>
					<svg t="1655264345662" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7991" width="10" height="10"><path d="M946.4 353.50000001l-419.9 419.9c-8.2 8.2-21.5 8.2-29.7 0l-421.1-421.1c-16.4-16.4-16.4-43 0-59.3 16.4-16.4 43-16.4 59.3 0L504.2 662.20000001c4.1 4.1 10.7 4.1 14.8 0l368-368c16.4-16.4 43-16.4 59.3 0 16.5 16.3 16.5 42.9 0.1 59.3z" fill="#8a8a8a" p-id="7992"></path></svg>
				</template>
				<a-select-option value="">源格式</a-select-option>
				<a-select-option v-for="(item,index) in defaultParameter.codedData" :value="item">{{item}}</a-select-option>
			</a-select>
		</div>
		
		<div class="parameter-control-box parameter-control-box-phone-box">
			<label class="parameter-control-label">音频时长</label>
			<div class="parameter-control-common-time-box">
				<a-input  size="large" placeholder="时" class="parameter-control-common-time-hu1s" :maxLength="1" v-model:value="defaultParameter.cropTime.start_time[0]" @change="defaultParameter.cropTime.change('start_h')"></a-input>
				<span class="parameter-control-common-time-separator-hu1s">:</span>
				<a-input  size="large" placeholder="分" class="parameter-control-common-time-hu1s" :maxLength="2" v-model:value="defaultParameter.cropTime.start_time[1]" @change="defaultParameter.cropTime.change('start_m')"></a-input>
				<span class="parameter-control-common-time-separator-hu1s">:</span>
				<a-input  size="large" placeholder="秒" class="parameter-control-common-time-hu1s" :maxLength="2" v-model:value="defaultParameter.cropTime.start_time[2]" @change="defaultParameter.cropTime.change('start_s')"></a-input>
			</div>
			
			<a-slider class="parameter-control-common-hu1s parameter-control-common-time-range-hu1s" range 
				:step="1" :min="defaultParameter.range.min" :max="defaultParameter.range.max"
				v-model:value="defaultParameter.range.rangeTime"
				:marks="defaultParameter.range.rangeMarksTime"
				:tipFormatter="defaultParameter.range.tipFormatter"
				@afterChange="onRangeAfterChange" />
				
				
			<div class="parameter-control-common-time-box parameter-control-common-time-box-phone">
				<a-input  size="large" placeholder="时" class="parameter-control-common-time-hu1s" :maxLength="1" v-model:value="defaultParameter.cropTime.end_time[0]" @change="defaultParameter.cropTime.change('end_h')"></a-input>
				<span class="parameter-control-common-time-separator-hu1s">:</span>
				<a-input  size="large" placeholder="分" class="parameter-control-common-time-hu1s" :maxLength="2" v-model:value="defaultParameter.cropTime.end_time[1]" @change="defaultParameter.cropTime.change('end_m')"></a-input>
				<span class="parameter-control-common-time-separator-hu1s">:</span>
				<a-input  size="large" placeholder="秒" class="parameter-control-common-time-hu1s" :maxLength="2" v-model:value="defaultParameter.cropTime.end_time[2]" @change="defaultParameter.cropTime.change('end_s')"></a-input>
			</div>
			<a-button class="media-crop-recover-range" size="large" @click="defaultParameter.recoverTime">不裁剪</a-button>
		</div>
		<div class="parameter-control-handle-box"><a-button :loading="fileInfo.lists.length>0&&fileInfo.lists[0].defaultInfoData.being>0 && fileInfo.lists[0].defaultInfoData.being<4?true:false" @click="startCompress" class="media-handle-btn" size="large" type="primary">开始压缩</a-button></div>
	</div>
	<div class="mainContainer" :class="fileInfo.lists.length>0?'mainContainerShow':''">
		<div class="mainContainerL">
			<div :style="{display:(fileInfo.lists.length<1?'block':'none')}" class="media-files-select-box">
				<file-upload input-id="file" 
				:name="fileInfo.initData.name" :post-action="fileInfo.initData.postAction" 
				:extensions="fileInfo.initData.extensions"
				:accept="fileInfo.initData.accept" 
				:multiple="fileInfo.initData.multiple" 
				:directory="fileInfo.initData.directory" 
				:create-directory="fileInfo.initData.createDirectory"
				:maximum="fileInfo.initData.maximum"
				:size="fileInfo.initData.size || 0" 
				:thread="fileInfo.initData.thread < 1 ? 1 : (fileInfo.initData.thread > 5 ? 5 : fileInfo.initData.thread)" 
				:headers="fileInfo.initData.headers"
				:data="fileInfo.initData.data" 
				:drop="fileInfo.initData.drop" 
				:drop-directory="fileInfo.initData.dropDirectory" 
				:add-index="fileInfo.initData.addIndex"
				:chunk-enabled="fileInfo.initData.chunkEnabled" :chunk="fileInfo.initData.chunk" 
				v-model="fileInfo.lists" 
				@input-filter="mediaFilter" @input-file="mediaFile" ref="upload">
					<div class="media-files-upload-warp">
						<div>
							<p class="media-icon-k"><i class="iconfont ys-music ys__icon_i1"></i></p>
							<a-button shape="round" size="large" type="primary" class="ant-btn-mainc">选择音频压缩</a-button>
							<div class="meadi__codoe_size__scf">支持的格式：{{supportFormt}} , 单个音频最大支持10G</div>
						</div>
					</div>
				</file-upload>
				<label ref="reselectMediaRef" for="file"></label>
			</div>
			<div v-if="fileInfo.lists.length>0" class="media-info-warp" @contextmenu="(e)=>{e.preventDefault()}">
				<audio ref="mediaRef" @canplay="onMediaCanplay" @error="onMediaError" controlslist="nodownload"  controls="" :src="fileInfo.lists[0].defaultInfoData.preview_url"></audio>
			</div>
		</div>
		<div class="mainContainerR">
			<table class="fileinfoTable">
				<tr>
					<th><text class="media-filename">{{fileInfo.lists.length>0?fileInfo.lists[0].file.name:''}}</text></th>
					<th>处理前</th>
					<th>处理后</th>
				</tr>
				<tr>
					<td class="textBold">大小</td>
					<td>{{fileInfo.lists.length>0 && fileInfo.lists[0].defaultInfoData.orgInfo.size>0?$baseFunc.converSize(fileInfo.lists[0].defaultInfoData.orgInfo.size):''}}</td>
					<td>{{fileInfo.lists.length>0 && fileInfo.lists[0].defaultInfoData.lastInfo.size>0?$baseFunc.converSize(fileInfo.lists[0].defaultInfoData.lastInfo.size):''}}</td>
				</tr>
				<tr>
					<td class="textBold">时长</td>
					<td>{{fileInfo.lists.length>0 && fileInfo.lists[0].defaultInfoData.orgInfo.duration>0?$baseFunc.floatToTimeHandle(fileInfo.lists[0].defaultInfoData.orgInfo.duration):''}}</td>
					<td>{{fileInfo.lists.length>0 && fileInfo.lists[0].defaultInfoData.lastInfo.duration>0?$baseFunc.floatToTimeHandle(fileInfo.lists[0].defaultInfoData.lastInfo.duration):''}}</td>
				</tr>
				<tr>
					<td class="textBold">格式</td>
					<td>{{fileInfo.lists.length>0 && fileInfo.lists[0].defaultInfoData.orgInfo.format?fileInfo.lists[0].defaultInfoData.orgInfo.format:''}}</td>
					<td>{{fileInfo.lists.length>0 && fileInfo.lists[0].defaultInfoData.lastInfo.format?fileInfo.lists[0].defaultInfoData.lastInfo.format:''}}</td>
				</tr>
			</table>
			<div v-if="fileInfo.lists.length>0" class="media-state-box">
				<div v-if="fileInfo.lists[0].defaultInfoData.being==1||fileInfo.lists[0].defaultInfoData.being==3">
					<a-progress :percent="fileInfo.lists[0].defaultInfoData.being==1?$baseFunc.retainTwoDecimals(fileInfo.lists[0].progress):fileInfo.lists[0].defaultInfoData.progress" :showInfo="false" :stroke-color="{from: '#E2F69F',to: '#87d068',}" status="active" />
					<div class="media-state-progress-desc">
						<span>{{fileInfo.lists[0].defaultInfoData.being==1?"上传中，请稍后":"处理中，请稍后"}}</span>
						<span>{{fileInfo.lists[0].defaultInfoData.being==1?$baseFunc.retainTwoDecimals(fileInfo.lists[0].progress):fileInfo.lists[0].defaultInfoData.progress}}%</span>
					</div>
				</div>
				<div v-else-if="fileInfo.lists[0].defaultInfoData.being==4" class="media-state-item media-state-success"><CheckCircleTwoTone :style="{fontSize:'24px',marginRight:'6px'}" twoToneColor="#52C41A" />压缩成功</div>
				<div v-else-if="fileInfo.lists[0].defaultInfoData.being==5" class="media-state-item media-state-error"><FrownTwoTone :style="{fontSize:'24px',marginRight:'6px'}" twoToneColor="#FF4D4F" />压缩失败</div>
				<div v-else><text class="waiting__process__tip">待处理</text></div>
			</div>
			<div class="btnsContainer" v-if="fileInfo.lists.length>0">
				<a-button @click="reselectMedia" v-if="fileInfo.lists[0].defaultInfoData.being!=1&&fileInfo.lists[0].defaultInfoData.being!=3" class="media-handle-btn" size="large">重新选择</a-button>
				<a-button @click="previewMediaInfo.previewMediaFunc(this)" v-if="fileInfo.lists[0].defaultInfoData.being==4" class="media-handle-btn" size="large" type="primary">预览</a-button>
				<a-button @click="downloadMediaFunc" v-if="fileInfo.lists[0].defaultInfoData.being==4" class="media-handle-btn" size="large" danger  :loading="downloadInfo.load" type="primary">下载音频</a-button>
			</div>
		</div>
	</div>
	
	<a-modal class="" width="800px" v-model:visible="previewMediaInfo.visible"   :destroyOnClose="true" :centered="true" 
	title="预览" :maskClosable="false" okText="下载音频" cancelText="关闭" @ok="downloadMediaFunc">
	    <PreviewMedia :mediaTyle="previewMediaInfo.type" :path="previewMediaInfo.path"></PreviewMedia>
	</a-modal>
</template>
<script>
	import { CheckCircleTwoTone,FrownTwoTone } from '@ant-design/icons-vue';
	import {message,Modal} from 'ant-design-vue';
	
	import baseFunc from '../libs/base_func.js'
	import PreviewMedia from '@/components/previewMedia.vue'
	export default ({
		components: {
			CheckCircleTwoTone,FrownTwoTone,PreviewMedia
		},
		props: {
			format:{
				type:String,
				default:"all",
			},
			types:{
				type:String,
				default:"audio_compress",
			},
			idKey:{
				type:String,
				default:null,
			}
		},
		mounted() {
			switch(this.format){
				case "mp3":
					this.fileInfo.initData.accept='audio/mp3';
					this.supportFormt='mp3';
					break;
				case "wav":
					this.fileInfo.initData.accept='audio/wav';
					this.supportFormt='wav';
					break;
				case "flac":
					this.fileInfo.initData.accept='audio/flac';
					this.supportFormt='flac';
					break;
				default:
					this.fileInfo.initData.accept='.opus,.flac,.webm,.weba,.wav,.ogg,.m4a,.oga,.mid,.mp3,.aiff,.wma,.au,.aif,.aifc,.m3u,.ra,.ram,.rmi,.snd,.asf,.voc,.rm,.svcd';
					this.supportFormt='mp3、flac、ogg、aif、aifc、au、m3u、mid、ra、ram、rmi、snd、wav、wma、asf、voc、aiff、rm、m4a、svcd';
					break;
			}
		},
		created() {
			if(this.idKey)this.getOriginalInfoInit();
			this.ratios=[{0:1,}]
		},
		data() {
			return {
				supportFormt:'',
				defaultParameter:{
					timer:null,
					clearTimer(){
						/*清除定时器*/
						if(this.timer){
							clearInterval(this.timer);
							window.clearInterval(this.timer);
							this.timer = null;
						}
					},
					cropTime:{
						start_time:["","",""],
						end_time:["","",""],
						change:(type)=>{
							switch(type){
								case "start_h":
									if(this.defaultParameter.cropTime.start_time[0])this.defaultParameter.cropTime.start_time[0] = baseFunc.checkNumber(this.defaultParameter.cropTime.start_time[0],4)
									break;
								case "start_m":
									if(this.defaultParameter.cropTime.start_time[1])this.defaultParameter.cropTime.start_time[1] = baseFunc.checkNumber(this.defaultParameter.cropTime.start_time[1],59)
									break;
								case "start_s":
									if(this.defaultParameter.cropTime.start_time[2])this.defaultParameter.cropTime.start_time[2] = baseFunc.checkNumber(this.defaultParameter.cropTime.start_time[2],59)
									break;
								case "end_h":
									if(this.defaultParameter.cropTime.end_time[0])this.defaultParameter.cropTime.end_time[0] = baseFunc.checkNumber(this.defaultParameter.cropTime.end_time[0],4)
									break;
								case "end_m":
									if(this.defaultParameter.cropTime.end_time[1])this.defaultParameter.cropTime.end_time[1] = baseFunc.checkNumber(this.defaultParameter.cropTime.end_time[1],59)
									break;
								case "end_s":
									if(this.defaultParameter.cropTime.end_time[2])this.defaultParameter.cropTime.end_time[2] = baseFunc.checkNumber(this.defaultParameter.cropTime.end_time[2],59)
									break;
							}
							let leftNumber = baseFunc.timeArrayToNumberHandle(this.defaultParameter.cropTime.start_time);
							let rightNumber = baseFunc.timeArrayToNumberHandle(this.defaultParameter.cropTime.end_time);
							if(rightNumber>this.defaultParameter.range.max || rightNumber==0)rightNumber = this.defaultParameter.range.max;
							
							if(leftNumber>rightNumber){
								if(leftNumber>this.defaultParameter.range.max){
									leftNumber = this.defaultParameter.range.max;
								}
								this.onRangeAfterChange([rightNumber,leftNumber])
								this.defaultParameter.range.rangeTime=[rightNumber,leftNumber]
							}else{
								this.defaultParameter.range.rangeTime=[leftNumber,rightNumber]
							}
						}
					},
					range:{
						min:0,
						max:3600,
						rangeTime:[0,3600],
						rangeMarksTime:{
							0:"00:00:00",
							3600:"01:00:00",
						},
						tipFormatter(value){
							return baseFunc.floatToTimeHandle(value,1);
						},
					},
					ratios:[],
					codedData:['mp3','m4a','wav','wma','ogg','flac','asf','voc','aiff','rm'],
					recoverTime:()=>{
						this.defaultParameter.range.rangeTime=[0,this.defaultParameter.range.max];
						this.defaultParameter.cropTime.start_time = ["","",""];
						this.defaultParameter.cropTime.end_time = ["","",""];
					},
					expectChange:()=>{
						this.parameter.expect = baseFunc.checkNumber(this.parameter.expect,4096);
						if(this.parameter.expect==0)this.parameter.expect=""
					},
					codeRateChange:()=>{
						this.parameter.codeRate = baseFunc.checkNumber(this.parameter.codeRate,4096);
						if(this.parameter.codeRate==0)this.parameter.codeRate=""
					},
				},
				parameter:{//调节参数 parameter.compress_type
					compress_type:1,/*1：标准压缩 2：指定大小压缩*/
					coded:"libmp3lame",
					ar:0,
					ratio:70,
					codeRate:128,//指定码率
					quality:26,//压缩品质
					expect:10,//期望大小
					format:"mp3",/*输出格式*/
					width:"",/*输出宽*/
					height:"",/*输出高*/
					start_time:"",/*裁剪开始时间*/
					end_time:"",/*裁剪结束时间*/
				},
				fileInfo:{
					lists:[],
					initData:{
						accept: '.opus,.flac,.webm,.weba,.wav,.ogg,.m4a,.oga,.mid,.mp3,.aiff,.wma,.au,.aif,.aifc,.m3u,.ra,.ram,.rmi,.snd,.asf,.voc,.rm,.svcd',
						extensions: '',
						size: 10*1024 * 1024 * 1024,/*文件最大支持 10G*/
						maxMediaTotal:1,/*单次最多支持处理音频数量*/
						multiple: false,/*支持批量*/
						directory: false,/*是否是上传文件夹*/
						drop: true,/*拖拽上传*/
						dropDirectory: false,/*支持拖拽文件夹*/
						createDirectory: false,
						addIndex: false,
						thread: 5,/*上传线程*/
						maximum:1,/*列表最大文件数*/
						name: 'file',
						chunkEnabled: true,/*批量上传*/
						chunk: {
							action: process.env.VUE_APP_BASE_URL + '/file/uploadChunk',
							minSize: 10 * 1024 * 1024,/*小于10M则直接上传-不需要分片*/
							maxActive: 1,/*分片批量上传线程*/
							maxRetries: 5,
							headers: {
								"Authorization": this.$baseFunc.getAuthorization(),
								"Media-Type": "audio",
							},
						},
						postAction: process.env.VUE_APP_BASE_URL + '/file/upload',
						headers: {
							"Authorization": this.$baseFunc.getAuthorization(),
							"Media-Type": "audio",
						},
						data: {
							"types": "audio",
						},
					},
				},
				downloadInfo:{
					load:false,
				},
				previewMediaInfo:{
					visible:false,
					type:"audio",
					path:"",
					previewMediaFunc(_this){/*预览处理后的音频*/
						if(_this.fileInfo.lists.length<1 || !_this.fileInfo.lists[0].defaultInfoData.lastInfo.preview_url)return false;
						this.path = _this.fileInfo.lists[0].defaultInfoData.lastInfo.preview_url;
						this.visible=true;
					},
				}
			}
		},
		methods:{
			/*初始化查询源文件*/
			getOriginalInfoInit(){
				this.$apiRequest.post('/file/getOriginalInfo', {
					key:this.idKey,
					way:2,
				}).then(res => {
					this.fileInfo.lists=[];
					let mediaInfo = res.data.information;
					let defaultInfoData={
						being:0,/*0：待上传 1：上传中 2：上传成功 3：处理中 4：处理成功 5：处理失败*/
						progress:0,/*处理进度*/
						preview_url:process.env.VUE_APP_BASE_URL+res.data.preview_url,
						orgInfo:{/*源音频信息*/
							loadedState:1,/*0：待加载 1：加载成功 2：加载失败*/
							width:0,
							height:0,
							bit_rate:parseInt(mediaInfo.audio.bit_rate),//源码率
							sample_rate:parseInt(mediaInfo.audio.sample_rate),//源采样率
							duration:parseInt(mediaInfo.duration),
							size:parseInt(mediaInfo.size),
							format:res.data.format,
							ext:res.data.format,
							key:res.data.key,
						},
						lastInfo:{/*处理后音频信息*/
							width:0,
							height:0,
							duration:0,
							size:0,
							format:"",
							ext:"",
							key:"",
							preview_url:"",
						}
					}
					this.fileInfo.lists[0]={
						file:{
							name:res.data.filename,
							size:defaultInfoData.orgInfo.size,
						},
						defaultInfoData:defaultInfoData,
						afetrOriginalInfo:1,
					}
					this.rangeDataInit(defaultInfoData.orgInfo.duration)
				}).catch(err => {
				})
			},
			/*滑块改变后*/
			onRangeAfterChange(value){
				if(value[0]<1){
					this.defaultParameter.cropTime.start_time=["","",""];
				}else{
					this.defaultParameter.cropTime.start_time=baseFunc.floatToTimeHandle(value[0],1,"array");
				}
				if(parseInt(value[1])==this.defaultParameter.range.max){
					this.defaultParameter.cropTime.end_time=["","",""];
				}else{
					this.defaultParameter.cropTime.end_time=baseFunc.floatToTimeHandle(value[1],1,"array");
				}
			},
			/*初始化时长裁剪*/
			rangeDataInit(duration,state){
				if(state){
					/*音频加载失败*/
					this.defaultParameter.range.max = duration
					this.defaultParameter.range.rangeTime=[0,duration]
					this.defaultParameter.range.rangeMarksTime={
						0:"00:00:00",
					}
					this.defaultParameter.range.rangeMarksTime[duration]=this.$baseFunc.floatToTimeHandle(duration)
					this.onRangeAfterChange(this.defaultParameter.range.rangeTime)
				}else if(duration>0){
					this.defaultParameter.range.max = duration
					try{
						let nowRangeTimeMax = this.defaultParameter.range.rangeTime[1];
						if(nowRangeTimeMax>duration || !this.$baseFunc.timeArrayToSubmitDemand(this.defaultParameter.cropTime.start_time)){
							this.defaultParameter.range.rangeTime[1] = duration
							if(this.defaultParameter.range.rangeTime[0]>duration){
								this.defaultParameter.range.rangeTime[0] = 0;
							}
							this.onRangeAfterChange(this.defaultParameter.range.rangeTime)
						}
						this.defaultParameter.range.rangeMarksTime={
							0:"00:00:00",
						}
						this.defaultParameter.range.rangeMarksTime[duration]=this.$baseFunc.floatToTimeHandle(duration)
					}catch(e){
						
					}
				}
			},
			/*获取音频信息*/
			mediaSkipFunc(duration){
				if(this.fileInfo.lists.length>0 && this.fileInfo.lists[0].defaultInfoData.preview_url && this.fileInfo.lists[0].defaultInfoData.orgInfo.loadedState==1){
					this.$refs.mediaRef.currentTime = duration;
				}
			},
			onMediaCanplay(){
				const mediaDom = this.$refs.mediaRef
				this.fileInfo.lists[0].defaultInfoData.orgInfo.duration=parseInt(mediaDom.duration)
				this.rangeDataInit(this.fileInfo.lists[0].defaultInfoData.orgInfo.duration)
			},
			onMediaError(){
				this.rangeDataInit(7200,true)
			},
			reselectMedia(){
				this.$refs.reselectMediaRef.dispatchEvent(new MouseEvent('click'))
			},
			mediaFilter(newFile, oldFile, prevent) {
				if (newFile && !oldFile) {
					if(newFile.file.type.split("/")[0]!="audio"){
						message.error('请选择正确的音频！');
						return prevent();
					}
					if(newFile.size>this.fileInfo.initData.size){
						message.warning('最大支持 10G 音频，请重新选择！');
						return prevent();
					}
				}
			},
			mediaFile(newFile, oldFile) {
				if(newFile && typeof newFile.afetrOriginalInfo!='undefined' && newFile.afetrOriginalInfo)return false;
				if (newFile && !oldFile) {
					if(this.fileInfo.lists.length>this.fileInfo.initData.maxMediaTotal){
						this.$refs.upload.remove(newFile)
						message.warning('单次最多支持压缩 '+this.fileInfo.initData.maxMediaTotal+' 个音频！');
						return false;
					}
					this.defaultParameter.clearTimer()
					// 添加文件
					newFile.defaultInfoData={
						being:0,/*0：待上传 1：上传中 2：上传成功 3：处理中 4：处理成功 5：处理失败*/
						progress:0,/*处理进度*/
						preview_url:"",
						orgInfo:{/*源音频信息*/
							loadedState:0,/*0：待加载 1：加载成功 2：加载失败*/
							width:0,
							height:0,
							duration:0,
							size:newFile.file.size,
							format:"",
							bit_rate:0,//源码率
							sample_rate:0,//源采样率
							ext:"",
							key:"",
						},
						lastInfo:{/*处理后音频信息*/
							width:0,
							height:0,
							duration:0,
							size:0,
							format:"",
							ext:"",
							key:"",
							preview_url:"",
						}
					}
					try {
						newFile.defaultInfoData.orgInfo.format = newFile.file.type.split('/')[1]
					} catch (e) {
					}
					let URL = (window.URL || window.webkitURL)
					if (URL) {
						newFile.defaultInfoData.preview_url = URL.createObjectURL(newFile.file)
					}
					
				}
				// 添加，更新，移除 后
				if (newFile && oldFile) {
					newFile.defaultInfoData.progress = parseFloat(newFile.progress)
					// 更新文件
					if (newFile.success !== oldFile.success) {
						if (newFile.response && newFile.response.code == 200) {
							newFile.defaultInfoData.being=2;
							newFile.defaultInfoData.orgInfo.key=newFile.response.md5
							if(newFile.response.information){
								if(parseInt(newFile.response.information.duration)>0){
									newFile.defaultInfoData.orgInfo.duration = parseInt(newFile.response.information.duration)
									this.rangeDataInit(newFile.defaultInfoData.orgInfo.duration)
								}
								if(parseInt(newFile.response.information.bit_rate)>0){
									newFile.defaultInfoData.orgInfo.bit_rate = parseInt(newFile.response.information.bit_rate);
								}
								if(newFile.response.information.audio && typeof newFile.response.information.audio.sample_rate !='undefined'){
									newFile.defaultInfoData.orgInfo.sample_rate = parseInt(newFile.response.information.audio.sample_rate)
								}
							}
							this.beingCompress()
						}
					}
				}
				if (!newFile && oldFile) {
					// 删除文件
				}
			},
			startCompress(){
				if(this.fileInfo.lists.length<1){
					this.reselectMedia()
				}else{
					if(this.fileInfo.lists[0].afetrOriginalInfo){
						this.beingCompress();
					}else if(this.fileInfo.lists[0].defaultInfoData.being<1 || !this.fileInfo.lists[0].defaultInfoData.orgInfo.key){
						// 开始上传
						if (!this.$refs.upload || !this.$refs.upload.active) {
							this.$refs.upload.active = true;
							this.fileInfo.lists[0].defaultInfoData.being=1;
						}
					}else{
						this.beingCompress();
					}
				}
			},
			beingCompress(){
				this.fileInfo.lists[0].defaultInfoData.being=3;
				this.fileInfo.lists[0].defaultInfoData.progress=0;

				let quality = 128;
				if(this.parameter.compress_type==2){
					if(this.parameter.expect && parseInt(this.parameter.expect)>0){
						quality = 0;
					}
				}else if(this.parameter.compress_type==3){
					if(parseInt(this.parameter.codeRate)<1){
						this.parameter.codeRate = 64
					}
					quality = parseInt(this.parameter.codeRate)
				}else{
					quality = parseInt(this.fileInfo.lists[0].defaultInfoData.orgInfo.bit_rate*this.parameter.ratio/100000)
				}
				if(quality>0&&quality<8)quality=8;
				this.$apiRequest.post('/file/submitMediaProcessingTask', {
					keys:this.fileInfo.lists[0].defaultInfoData.orgInfo.key,
					types:this.types,
					coded:this.parameter.coded,
					quality:quality,
					expect:this.parameter.compress_type==2&&this.parameter.expect?parseInt(this.parameter.expect):0,
					format:this.parameter.format,
					ar:this.parameter.ar?parseInt(this.parameter.ar):0,
					start_time:this.$baseFunc.timeArrayToSubmitDemand(this.defaultParameter.cropTime.start_time),
					end_time:this.$baseFunc.timeArrayToSubmitDemand(this.defaultParameter.cropTime.end_time),
				}).then(res => {
					this.fileInfo.lists[0].defaultInfoData.lastInfo.key=res.data[0].handle_md5
					this.getTaskProgress()
				}).catch(err => {
				})
			},
			getTaskProgress(){
				if(!this.fileInfo.lists[0].defaultInfoData.lastInfo.key)return false;
				const _this = this
				this.$apiRequest.post('/file/getTaskProgress', {
					keys:this.fileInfo.lists[0].defaultInfoData.lastInfo.key,
				}).then(res => {
					let rentFileData = res.data[0];
					if(rentFileData.code==10000){
						this.fileInfo.lists[0].defaultInfoData.being=5;
					}else if(rentFileData.code==10202 || rentFileData.code==10201){
						if(rentFileData.code==10201){
							let totalTime = parseFloat(rentFileData.data.totalTime),
							handleTime = parseFloat(rentFileData.data.handleTime)
							if(totalTime>0){
								if(handleTime>totalTime)handleTime=totalTime;
								this.fileInfo.lists[0].defaultInfoData.progress=parseFloat((handleTime*100/totalTime).toFixed(2));
							}
						}
						this.defaultParameter.timer = setTimeout(()=>{
							_this.getTaskProgress()
						},1000);
					}else if(rentFileData.code==200){
						this.fileInfo.lists[0].defaultInfoData.being=4;
						this.fileInfo.lists[0].defaultInfoData.lastInfo.duration=parseInt(rentFileData.data.duration)
						this.fileInfo.lists[0].defaultInfoData.lastInfo.size=parseFloat(rentFileData.data.size)
						this.fileInfo.lists[0].defaultInfoData.lastInfo.format=rentFileData.data.format
						this.fileInfo.lists[0].defaultInfoData.lastInfo.ext=rentFileData.data.format
						this.fileInfo.lists[0].defaultInfoData.lastInfo.preview_url=rentFileData.data.preview_url
					}
				}).catch(err => {
					if(err.code==10000){
						this.fileInfo.lists[0].defaultInfoData.being=5;
					}
				})
			},
			/*下载音频*/
			downloadMediaFunc(){
				this.previewMediaInfo.visible = false;
				if(this.fileInfo.lists.length<1||!this.fileInfo.lists[0].defaultInfoData.lastInfo.key){
					Modal.warning({
						title: () => '下载提示',
						content: () => '请先处理后再下载！',
					});
					return false;
				}
				this.downloadInfo.load=true;
				this.$apiRequest.post('/file/decideDownload', {
					file_handle_md5s:this.fileInfo.lists[0].defaultInfoData.lastInfo.key,
				}).then(res => {
					this.downloadInfo.load=false;
					window.location.href=process.env.VUE_APP_BASE_URL+res.download_url
				}).catch(err => {
					this.downloadInfo.load=false;
					this.$emit("userHandleCall",err);
				})
			}
		},
		
	});
</script>
<style>
	.parameter-control-box input{font-size: 14px;height: 40px;}
	.parameter-control-box .ant-select-lg{font-size: 14px;}
	.parameter-control-common-time-box input{border: unset !important;outline: unset;box-shadow: unset !important;padding: 0;height: 38px;}
	.media-files-upload-warp button.ant-btn{height: 44px;width: 160px;}
	.media-files-select-box label{cursor: pointer;}
</style>
<style scoped lang="less">
	.parameter-control-warp{position:relative;padding:0;margin:0;background-color:rgba(0,0,0,0)}
	.parameter-control-warp .parameter-control-box{width:100%;position:relative;margin-bottom:12px;display:flex;display:-webkit-flex;height:40px;align-items:center;justify-content:flex-start}
	.parameter-control-warp .parameter-control-box:last-child{margin-bottom:0}
	.parameter-control-label{display:inline-block;padding:0px 15px;width:auto;font-weight:400;text-align:right;min-width:86px;height:40px;line-height:40px}
	.parameter-control-box .parameter-control-common-hu1s{margin-right:12px;width:274px}
	.parameter-control-box .parameter-control-common-hu1s:last-child{margin-right:0}
	.parameter-control-common-time-hu1s{width:44px;text-align:center}
	.parameter-control-common-time-separator-hu1s{margin:0}
	.parameter-control-common-time-box{border:0;border-radius:6px;overflow: hidden;background: #fff;}
	.parameter-control-box .parameter-control-common-time-range-hu1s{margin:0 72px;width:320px}
	.media-crop-recover-range{
		margin-left:12px;
		min-width:92px;
		font-size:14px;
		border: 1px solid #00B277;
		border-radius: 7px;
		color: #00B277;
	}
	.mainContainer{position:relative;width:100%;display:flex;border-radius: 10px;overflow: hidden;}
	.mainContainerL{flex:1;height:480px;background-color:#ffffff;position:relative;display:flex;justify-content:center;align-items:center;transition:all 0.2s;}
	.mainContainerR{width:0;min-height:280px;position:relative;background-color:#ffffff;transition:all 0.2s;overflow: hidden;}
	.fileinfoTable{display: none;}
	.mainContainerShow .mainContainerR{width:280px;border-left: 1px solid #F2F5FA;}
	.mainContainerShow .fileinfoTable{display:table;}

	.parameter-control-warp .parameter-control-handle-box{position: absolute;right: 0;bottom: 0;}
	.media-handle-btn{font-size: 14px;width: 263px;}
	.parameter-control-handle-box .media-handle-btn{
		font-size: 14px;
		width: 274px;
		background: linear-gradient(90deg, #00B277 0%, #00D885 100%);
		border-radius: 7px;
		border: 0;
		&:hover{
			background: linear-gradient(90deg, #00D885 0%, #00B277 100%);
		}
	}
	.btnsContainer{
		position: absolute;
		width: 100%;
		bottom: 10px;
		left: 0;
		right: 0;
		text-align: center;
		margin: 0 auto;
	}
	.btnsContainer .ant-btn{margin-top: 12px;}


	.fileinfoTable tr:nth-child(even) {background-color: #e7eef9;}
	.fileinfoTable {width: 100%;text-align: center;}
	.fileinfoTable td {font-size: 14px;text-align: center;line-height: 50px;}
	.fileinfoTable th {width: 33.3%;font-size: 15px;line-height: 50px;}
	.fileinfoTable .media-filename{
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		max-width: 80px;
		display: block;
		text-align: center;
		margin: auto;
	}
	.media-files-select-box,
	.media-files-select-box .file-uploads,
	.media-files-upload-warp {
		width: 100%;
		height: 100%;
		display: flex;
		margin: 0;
		padding: 0;
		position: relative;
		align-items: center;
		justify-content: center;
		color: #ffffff;
		.ant-btn-mainc{
			background-color: #ffffff;
			color: #00B277;
			border: 1px solid #00B277;
			margin: 0 20px;
		}
	}
	.media-files-upload-warp .media-icon-k{margin: auto;padding: 0;}
	.media-files-upload-warp .media-icon-k .ys__icon_i1{
		font-size: 44px;
		color: #cdcdcd;
	}
	.media-files-upload-warp .meadi__codoe_size__scf{
		color: #bfbfbf;
		font-size: 16px;
		margin-top: 40px;
		padding: 0 32px;
	}
	.media-info-warp{
		padding: 24px;
		box-sizing: border-box;
		position: relative;
		width: 100%;
		height: 100%;
		display: flex;
		display: -webkit-flex;
		align-items: center;
		justify-content: center;
	}
	.media-info-warp video{
		max-width: 100%;
		max-height: 100%;
	}
	.media-state-box{
		width: 100%;
		display: flex;
		display: -webkit-flex;
		flex-wrap: nowrap;
		justify-content: center;
		line-height: 1.5;
		margin: 0;
		padding: 6px 24px;
		position: relative;
		color: var(--main-color);
		font-size: 16px;
		text-align: center;
	}
	.media-state-box text{margin-left: 3px;}
	.media-state-box>div{width: 100%;}
	.media-state-box .waiting__process__tip{color: #999999;}
	.media-state-box .media-state-progress-desc{
		width: 100%;
		display: flex;
		display: -webkit-flex;
		margin-top: 4px;
		justify-content: space-between;
		font-weight: normal;
		color: #252525;
	}
	.media-state-box .media-state-item{
		text-align: center;
		width: 100%;
		display: flex;
		display: -webkit-flex;
		justify-content: center;
		align-items: center;
		font-weight: bold;
	}
	.media-state-box .media-state-success{
		color: #52C41A;
	}
	.media-state-box .media-state-error{
		color: #FF4D4F;
	}
	.parameter-control-box{
		:deep{
			.ant-input-group-addon:last-child{
				color: rgba(0,0,0,0.65);
			}
		}
	}
	@media(max-width:760px){
		.parameter-control-warp .parameter-control-handle-box{
			position: relative;
			width: 100%;
			margin-left: 0;
			.media-handle-btn{
				width: 100%;
			}
		}
		.parameter-control-warp .parameter-control-box{
			height: auto;
			flex-wrap: wrap;
		}
		.parameter-control-box .parameter-control-common-hu1s{
			width: 100%;
			margin-bottom: 12px;
			margin-right: 0;
		}
		.parameter-control-box.parameter-control-box-phone-box{
			.parameter-control-label{
				width: 100%;
			}
			.parameter-control-common-time-box-phone{
				position: absolute;
				top: 40px;
				right: 0;
			}
			.media-crop-recover-range{
				display: none;
			}
			.parameter-control-common-hu1s{
				margin: 12px 0 12px 66px;
				width: calc(100% - 132px);
			}
		}
		.parameter-control-label{
			padding: 0;
			text-align: left;
		}
		.mainContainer.mainContainerShow{
			flex-direction: column;
		}
		.mainContainerShow .mainContainerR{
			width: 100%;
		}
		.btnsContainer{
			position: relative;
		}
	}
</style>
