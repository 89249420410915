import { createApp } from 'vue';
import App from './App.vue';
/*引入ui插件*/
import Antd from 'ant-design-vue';
// import 'ant-design-vue/dist/antd.css';
import 'ant-design-vue/dist/antd.less';

/*引入上传组件*/
import VueUploadComponent from 'vue-upload-component'



import baseFunc from '../../libs/base_func.js'
import apiRequest from '../../libs/request.js'

const app = createApp(App);
app.config.globalProperties.$apiRequest = apiRequest;
app.config.globalProperties.$baseFunc = baseFunc;
app.component('file-upload', VueUploadComponent)
app.use(Antd).mount('#app');

