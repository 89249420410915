<template>
	<div class="preview-media-warp" @contextmenu="(e)=>{e.preventDefault()}">
		<div v-if="mediaTyle=='video'" class="preview-media-item">
			<video v-if="mediaLoad!=2 && playPath" ref="mediaVideoRef" 
			@canplay="onMediaCanplay" 
			@error="onMediaError" 
			controlslist="nodownload"  
			controls :src="playPath"></video>
			<div v-else class="preview-media-error-box">
				<FrownTwoTone :style="{fontSize:'80px'}"  twoToneColor="#DDD7CA"  />
				<p class="preview-media-error-tip">很抱歉，您的浏览器不支持，请下载到电脑查看！</p>
			</div>
		</div>
		<div v-if="mediaTyle=='audio'" class="preview-media-item">
			<audio v-if="mediaLoad!=2 && playPath" ref="mediaVideoRef" 
			@canplay="onMediaCanplay" 
			@error="onMediaError" 
			controlslist="nodownload"  
			controls :src="playPath"></audio>
			<div v-else class="preview-media-error-box">
				<FrownTwoTone :style="{fontSize:'80px'}"  twoToneColor="#DDD7CA"  />
				<p class="preview-media-error-tip">很抱歉，您的浏览器不支持，请下载到电脑查看！</p>
			</div>
		</div>
	</div>
</template>

<script>
	import { FrownTwoTone } from '@ant-design/icons-vue';
	export default ({
		name: 'PreviewMedia',
		components: {
			FrownTwoTone
		},
		props: {
			mediaTyle:{
				type:String,
				default:"video",
			},
			path:{
				type:String,
				default:"",
			}
		},
		created() {
			if(this.path.indexOf("blob:")===0){
				this.playPath = this.path;
			}else{
				this.playPath = process.env.VUE_APP_BASE_URL+this.path;
			}
		},
		data(){
			return{
				playPath:"",
				mediaLoad:0,/*0：加载中 1：加载成功 2：加载失败*/
			}
		},
		methods:{
			onMediaCanplay(){
				this.mediaLoad=0;
			},
			onMediaError(){
				this.mediaLoad=2;
			},
		}
	});
</script>

<style scoped>
	.preview-media-warp{
		width:100%;
		position: relative;
		margin: 0;
		padding: 0;
	}
	.preview-media-item{
		width:100%;
		height:600px;
		display:flex;
		display: -webkit-flex;
		align-items: center;
		justify-content: center;
	}
	.preview-media-item video{
		max-width: 100%;
		max-height: 100%;
	}
	.preview-media-error-box{
		width: 100%;
		text-align: center;
	}
	.preview-media-error-tip{
		font-size: 18px;
		margin-top: 14px;
		color: rgba(0,0,0,0.85);
	}
</style>